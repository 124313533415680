import { Fragment } from 'react'
import { Button } from './Button'
import 'material-icons/iconfont/material-icons.css'
import { CircularProgress, Tooltip } from '@mui/material';

export const Icon = ({ name, outlined = null }) => {
    return (<>
        {outlined &&
            <i translate="no" className='material-symbols-outlined'>{name ? name : ''}</i>
            ? <i translate="no" className='material-symbols-outlined'>{name ? name : ''}</i> :
            <i translate="no" className='material-icons'>{name ? name : ''}</i>}
    </>)
}


const IconButton = ({ isLoadingIcon = false, tooltipTitle = '', reactIcons = false, iconName, background, label, children, outlined, ...others }) => {
    return (
        <Fragment>
            <Tooltip title={tooltipTitle}>

                <Button {...others} background={background}>
                    {isLoadingIcon && (<CircularProgress style={{ width: 40 }} />)}
                    {!isLoadingIcon && (<Icon outlined={outlined} name={iconName} />)}
                    {label}
                    {children}

                </Button>
            </Tooltip>
        </Fragment>
    );
};

export default IconButton