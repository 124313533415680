import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import image from '../assets/zeene-blue.png';

export const gerarRelatorioPDF = (value, onClosed) => {
    const doc = new jsPDF();

    // Adicionar logo no topo do relatório
    const logoSuperiorEsquerdo = new Image();
    const logoWidth = 60; // Definir a largura do logo
    const logoHeight = 13; // Definir a altura do logo
    logoSuperiorEsquerdo.src = image;

    doc.addImage(logoSuperiorEsquerdo, 'PNG', 12, 15, logoWidth, logoHeight);

    doc.line(15, 45, doc.internal.pageSize.getWidth() - 15, 45); // Linha divisória
    doc.setFontSize(10);
    doc.text(`Data da Impressão: ${new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()}`, 120, 30);
    doc.text(`Número de Pedido: ${value.orderid}`, 120, 50);
    doc.text(`Data do Orçamento: ${new Date(value.createdAt).toLocaleDateString()}`, 120, 60);
    doc.text(`Vendedor: ${value.cliente && value.cliente.nomeRepresentante ? value.cliente.nomeRepresentante : value.cliente.nomeVendedor}`, 120, 70);
    doc.text('Detalhe do Pedido', 80, 110);

    doc.line(15, 95, doc.internal.pageSize.getWidth() - 15, 95); // Linha divisória

    let status = {
        pending: 'Orçamento',
        send: 'Enviado para Invento',
        cancelled: 'Cancelado',

    }
    // Dados do Pedido
    const dadosPedido = [
        {
            campo: 'Cliente:',
            valor: value.nome.substring(0, 30),
            posY: 50,
            posyStartKey: 15,
            posyStarValue: 50,
        },
        {
            campo: 'Código:',
            valor: value.cliente.codigo,
            posY: 80,
            posyStartKey: 120,
            posyStarValue: 140,
        },
        {
            campo: 'CNPJ:',
            valor: value.cliente.documento,
            posY: 60,
            posyStartKey: 15,
            posyStarValue: 50,
        },
        {
            campo: 'Endereço:',
            valor: value.cliente.logradouro,
            posY: 70,
            posyStartKey: 15,
            posyStarValue: 50,
        },
        {
            campo: 'Pagamento:',
            valor: value.payments,
            posY: 80,
            posyStartKey: 15,
            posyStarValue: 50,
        },
        {
            campo: 'Transportadora:',
            valor: value.transport && value.transport.name ? value.transport.name.substring(0, 25) : '',
            posY: 100,
            posyStartKey: 15,
            posyStarValue: 43,
        },
        {
            campo: 'Unidade de Negócio:',
            valor: value.company,
            posY: 90,
            posyStartKey: 120,
            posyStarValue: 165,
        },
        {
            campo: 'Prazo:',
            valor: value.paymentTime.description,
            posY: 90,
            posyStartKey: 15,
            posyStarValue: 50,
        },
        {
            campo: 'Status:',
            valor: status[value.status] ? status[value.status] : value.status,
            posY: 100,
            posyStartKey: 120,
            posyStarValue: 140,
        },
    ];

    let posY = 50;

    dadosPedido.forEach((item) => {
        doc.text(item.campo, item.posyStartKey, item.posY);
        doc.text(item.valor, item.posyStarValue, item.posY);
    });

    posY += 70;

    const headerTable = ['Código', 'Título', 'Unid', 'Preço Real', 'Preço Negociado'];
    const tableData = [];

    value.items.forEach((item) => {
        const { codigo, descricao, quantity, price, negotiatedPrice, valorDescritor } = item;
        let descriptionItem = descricao + ' ' + (valorDescritor ? valorDescritor : '')
        tableData.push([
            codigo,
            descriptionItem,
            quantity,
            `R$${price ? price.toFixed(2) : 0}`,
            `R$${negotiatedPrice ? negotiatedPrice.toFixed(2) : 0}`,

        ]);
    });

    const options = {
        startY: posY,
        margin: { top: 5 },
        theme: 'grid',
        styles: {
            fillColor: [255, 255, 255], // Cor de preenchimento das células
            textColor: [0, 0, 0], // Cor do texto nas células
            lineColor: [0, 0, 0], // Cor das linhas da tabela
        },
        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
        bodyStyles: {
            fontSize: 9, // Tamanho da fonte do corpo da tabela
        },
        columnStyles: {
            2: { // Índice da coluna (0 para a primeira coluna, 1 para a segunda, etc.)
                columnWidth: 13, // Definir a largura da primeira coluna (em unidades do PDF)
            },
        },
    };

    doc.autoTable(headerTable, tableData, options);

    posY = doc.autoTable.previous.finalY + 10;

    // Adicionar subtotal
    const fromPrice = value.items.reduce((acc, item) => (item.price * item.quantity) + acc, 0);
    const precoNegociado = value.items.reduce((acc, item) => ((item.negotiatedPrice || item.price) * item.quantity) + acc, 0);

    doc.text(`Total preço Real: R$${fromPrice.toFixed(2)}`, 150, posY);
    doc.text(`Total preço Negociado: R$${precoNegociado.toFixed(2)}`, 150, posY + 5);

    doc.save(`Pedido - ${value.orderid ? value.orderid : ''}.pdf`);

    onClosed()
};
