import React, { Fragment } from 'react'
import { Footer } from './Footer'
import Sidebar from './Sidebar'
import styled from 'styled-components'
import { device } from '../components/device.config.js'

const Container = styled.div`
    display:'flex';
    overflow:hidden;
    margin-left:250px;
    padding:30px;
    justify-content: center;

@media ${device.laptopL} { 
    display:'flex';
    margin:0 auto;
    justify-content: center;
  }
`
const Item = styled.div`
width:100%;
max-width: 2560px;
`

const Template = ({ children }) => {
    return (
        <Fragment>
            <Sidebar />
            <Container>
                <Item>
                    {children}
                </Item>
            </Container>
            <Footer />
        </Fragment >
    )
}

export default Template