export const brand = [
    'Nissan',
    'Toyota',
    'Fiat',
    'Gm',
    'Vw',
    'Ford',
    'Honda',
    'Peugeot',
    'Citroen',
    'Mercedes',
    'Renault',
    'Mitsubishi',
    'Audi',
    'Chery',
    'Jeep',
    'Hyundai',
    'Kia',
    'Jac',
    'Bmw',
    'Chrysler',
    'Iveco',
    'Universal',
    'Volkswagen',
    'Automotive Imports',
    'Dodge',
    'Porsche',
    'Land Rover',
    'Suzuki',
    'Chevrolet',
    'Daewoo',
    'Hummer',
    'Mini',
    'Alfa Romeo',
    'Seat',
    'Anroi',
    'Acrilux',
    'Mopar',
    'Arteb',
    'Mercedes Benz',
    'Lifan',
    'Subaru',
    'Troller',
    'Volvo',
    'Allen',
    'Fiat', 'Chevrolet', 'Renault',
    'Peugeot', 'Citroen',
    'Chevrolet',
    'Ford',
    'Renault',
    'Mercedes Benz',
    'Volkswagen',
    'Audi',
    'Ford',
    'Volkswagen',
    'Automotive-Imports',
    'SMS',
    'APC',
    'Fiat',
    'Ai',
    'Loma Plast',

]