import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'jotai';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SnackbarProvider maxSnack={1} autoHideDuration={5000} >
    <Provider>
      <main className='main'>
        <App />
      </main>
    </Provider>
  </SnackbarProvider>
);

