import React from 'react';
import styled from 'styled-components';

const FeatureComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: #333;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FeatureItem = styled.li`
  margin-bottom: 10px;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #ff0000; /* Altere para a cor desejada */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const Icon = styled.span`
  /* Estilos para o ícone */
`;

const Feature = () => {

  let features = ['Característica 1', 'Característica 2', 'Característica 3'];
  return (
    <FeatureComponent>
      <h2>Características</h2>
      <FeatureList>
        {features.map((feature, index) => (
          <FeatureItem key={index}>
            <Icon>{/* Ícone ou imagem aqui */}</Icon>
            {feature}
          </FeatureItem>
        ))}
      </FeatureList>
    </FeatureComponent>
  );
};

export default Feature;
