
  export const paymentsAutoCase = [
    "A VISTA/A VISTA",
    "A VISTA/BOLETO",
    "A VISTA/CARTÃO",
    "BOLETO/A VISTA",
    "BOLETO/BOLETO",
    "BOLETO/CARTÃO",
    "BOLETO/CHEQUE",
    "CARTÃO/A VISTA",
    "CARTÃO/BOLETO",
    "CARTÃO/CARTÃO",
    "CARTÃO DE CRÉDITO",
    "Sem Onus"
]

export const paymentsAi = [
    "BOLETO",
    "A VISTA ANTECIPADO",
    "CARTÃO DE CRÉDITO",
    "CHEQUE",
    "DEPÓSITO",
    "CHEQUE/CHEQUE"
]

export const paymentsSKAutoParts = [
  "BOLETO",
  "A VISTA ANTECIPADO",
  "CARTÃO DE CRÉDITO",
  "CHEQUE",
  "DEPÓSITO",
  "CHEQUE/CHEQUE"
]


export const paymentsVale = [
    "BOLETO",
    "A VISTA ANTECIPADO",
    "CARTÃO DE CRÉDITO",
    "CHEQUE",
    "DEPÓSITO",
    "CHEQUE/CHEQUE"
]

export const paymentsTimeAi = 
[
    { "id": 0, "descricao": "A Vista Antecipado" },
    { "id": 1, "descricao": "7/14/21" },
    { "id": 2, "descricao": "14 dias" },
    { "id": 3, "descricao": "30 Dias" },
    { "id": 4, "descricao": "30/45/60" },
    { "id": 8, "descricao": "45 dias" },
    { "id": 13, "descricao": "30/40" },
    { "id": 14, "descricao": "40 dias" },
    { "id": 15, "descricao": "30/40/50" },
    { "id": 16, "descricao": "30/40/50/60" },
    { "id": 17, "descricao": "30/60" },
    { "id": 20, "descricao": "Cartão de crédito" },
    { "id": 22, "descricao": "28/35/42" },
    { "id": 24, "descricao": "28/35" },
    { "id": 25, "descricao": "28/35/42/49" },
    { "id": 26, "descricao": "30/45" }
  ]
 
  export const paymentsTimeSKAutoParts = 
[
    { "id": 0, "descricao": "A Vista Antecipado" },
    { "id": 1, "descricao": "7/14/21" },
    { "id": 2, "descricao": "14 dias" },
    { "id": 3, "descricao": "30 Dias" },
    { "id": 4, "descricao": "30/45/60" },
    { "id": 8, "descricao": "45 dias" },
    { "id": 13, "descricao": "30/40" },
    { "id": 14, "descricao": "40 dias" },
    { "id": 15, "descricao": "30/40/50" },
    { "id": 16, "descricao": "30/40/50/60" },
    { "id": 17, "descricao": "30/60" },
    { "id": 20, "descricao": "Cartão de crédito" },
    { "id": 22, "descricao": "28/35/42" },
    { "id": 24, "descricao": "28/35" },
    { "id": 25, "descricao": "28/35/42/49" },
    { "id": 26, "descricao": "30/45" }
  ]

export const paymentsTimeVale = [
    { "id": 0, "descricao": "A Vista Antecipado" },
    { "id": 1, "descricao": "7/14/21" },
    { "id": 2, "descricao": "14 dias" },
    { "id": 3, "descricao": "30 Dias" },
    { "id": 4, "descricao": "30/45/60" },
    { "id": 8, "descricao": "45 dias" },
    { "id": 13, "descricao": "30/40" },
    { "id": 14, "descricao": "40 dias" },
    { "id": 15, "descricao": "30/40/50" },
    { "id": 16, "descricao": "30/40/50/60" },
    { "id": 17, "descricao": "30/60" },
    { "id": 20, "descricao": "Cartão de crédito" },
    { "id": 21, "descricao": "30/45" },
    { "id": 25, "descricao": "35/42/49/56/63" },
    { "id": 26, "descricao": "28/35" },
    { "id": 27, "descricao": "28/35/42/49" }
  ]
  

export const paymentsTimeAutoCase =[
    { "id": 0, "descricao": "A Vista Antecipado" },
    { "id": 1, "descricao": "7/14/21" },
    { "id": 2, "descricao": "14 dias" },
    { "id": 3, "descricao": "30 Dias" },
    { "id": 4, "descricao": "30/45/60" },
    { "id": 8, "descricao": "45 dias" },
    { "id": 13, "descricao": "30/40" },
    { "id": 14, "descricao": "40 dias" },
    { "id": 15, "descricao": "30/40/50" },
    { "id": 16, "descricao": "30/40/50/60" },
    { "id": 17, "descricao": "30/60" },
    { "id": 20, "descricao": "Cartão de crédito" },
    { "id": 21, "descricao": "30/45" },
    { "id": 25, "descricao": "35/42/49/56/63" },
    { "id": 26, "descricao": "28/35" },
    { "id": 27, "descricao": "28/35/42/49" }
  ]
  