export default function capitalizeFirst (string) {
  if (string.length === 0) {
    return string
  }

  if (!(typeof string == 'string')) {
    return string
  }

  const capitalizeFinish =
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  return capitalizeFinish.trim()
}
