
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import image from '../assets/logo-talaovenda.jpg';
import { convertMonetaryValueToNumber } from './../lib/convertMonetaryValueToNumber'
import capitalizeFirst from '../lib/capitalizeFirst';



export const ReportPDFTalao = async (value, onClosed) => {
    const doc = new jsPDF('landscape');
    let fontLabel = 10;
    let fontValue = 8;

    const headerFooter = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(150);
        const text = 'Página ' + data.pageCount;
        doc.text(text, data.settings.margin.left, doc.internal.pageSize.getHeight() - 10);
    };
    const pageCount = doc.internal.getNumberOfPages();

    //Headers
    //------------------------------------------------------------------

    const labelDatePrint = 'Data da Impressão:';
    doc.setFontSize(fontLabel);
    doc.text(labelDatePrint, 200, 5);
    doc.setFontSize(fontLabel);

    const datePrint = `${new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()}`
    doc.text(datePrint, 235, 5);


    // Desenhar linha fina no topo
    doc.setLineWidth(0.3); // Defina a espessura da linha
    doc.setTextColor(80);
    doc.line(20, 10, doc.internal.pageSize.getWidth() - 20, 10);

    // Adicionar logo no topo do relatório
    const logoSuperiorEsquerdo = new Image();
    const logoWidth = 40; // Definir a largura do logo
    const logoHeight = 10; // Definir a altura do logo
    logoSuperiorEsquerdo.src = image;
    doc.addImage(logoSuperiorEsquerdo, 'PNG', 20, 15, logoWidth, logoHeight);

    //Adicionar nomo Talao ao centro
    doc.setFont('helvetica', 'bold');
    const headerPagina = 'Talão de Vendas';
    doc.setFontSize(fontLabel);
    doc.text(headerPagina, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

    //Adicionar nomo Talao ao centro
    const labelSale = 'Pedido de venda';
    doc.setFontSize(fontLabel);
    doc.text(labelSale, 180, 20);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const orderId = value[0].numero;
    doc.text(orderId, 180, 25);

    const labelDate = 'Data do Talão';
    doc.setFontSize(fontLabel);
    doc.text(labelDate, 210, 20);
    doc.setFontSize(fontValue);
    doc.setFont('helvetica', 'bold');
    const dateTalao = `${new Date(value[0].dataTalao).toLocaleDateString() + ' ' + new Date(value[0].dataTalao).toLocaleTimeString()}`
    doc.text(dateTalao, 210, 25);

    const labelCode = 'Codigo do cliente';
    doc.setFontSize(fontLabel);
    doc.text(labelCode, 240, 20);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const code = value[0].codigo;
    doc.text(code, 240, 25);


    // Desenhar linha fina no topo
    doc.setTextColor(80);
    doc.setLineWidth(0.3); // Defina a espessura da linha
    doc.line(20, 30, doc.internal.pageSize.getWidth() - 20, 30);

    //------------------------------------------------------------------

    const validateNull = (value, sub = true) => {
        if (sub) {
            return value ? new String(value).substring(0, 30) : ''
        }
        return ''
    }


    // column 1

    const labelName = 'Nome:';
    doc.setFontSize(fontLabel);
    doc.text(labelName, 20, 35);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const name = validateNull(value[0].nomeComprador);
    doc.text(name, 50, 35);


    const labelCompany = 'Unidade de Neg.:';
    doc.setFontSize(fontLabel);
    doc.text(labelCompany, 20, 40);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const company = validateNull(value[0].unidadeNegocio);
    doc.text(company, 50, 40);


    const labelTransport = 'Transportadora:';
    doc.setFontSize(fontLabel);
    doc.text(labelTransport, 20, 45);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const transport = validateNull(value[0].transportadora);
    doc.text(transport, 50, 45);


    const labelObs = 'Observação:';
    doc.setFontSize(fontLabel);
    doc.text(labelObs, 20, 50);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const obs = validateNull(value[0].mensagem, false);
    doc.text(obs, 50, 50);

    // column 2

    const labelFantasia = 'Fantasia:';
    doc.setFontSize(fontLabel);
    doc.text(labelFantasia, 120, 35);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const fantasia = validateNull(value[0].fantasia);
    doc.text(fantasia, 150, 35);


    const labelVendedor = 'Vendedor:';
    doc.setFontSize(fontLabel);
    doc.text(labelVendedor, 120, 40);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const vendedor = validateNull(value[0].vendedor)
    doc.text(vendedor, 150, 40);


    const labelRepresentante = 'Representante:';
    doc.setFontSize(fontLabel);
    doc.text(labelRepresentante, 120, 45);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const representante = validateNull(value[0].representante);
    doc.text(representante, 150, 45);

    // column 3

    const labelCNPJ = 'CNPJ:';
    doc.setFontSize(fontLabel);
    doc.text(labelCNPJ, 240, 35);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const cnpj = validateNull(value[0].documento);
    doc.text(cnpj, 251, 35);


    const labelPrazo = 'Prazo:';
    doc.setFontSize(fontLabel);
    doc.text(labelPrazo, 240, 40);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const prazo = validateNull(value[0].prazo);
    doc.text(prazo, 251, 40);

    const labelUf = 'UF:';
    doc.setFontSize(fontLabel);
    doc.text(labelUf, 240, 45);
    doc.setFontSize(fontLabel);
    doc.setFont('helvetica', 'bold');
    const UF = validateNull(value[0].uf);
    doc.text(UF, 251, 45);

    //----------------------



    // Adicionar a paginação no final
    let totalPagesExp = ''
    const pageContent = function (data, teste) {

        totalPagesExp = Math.ceil((((data.table.body.length - 12) / 17) + 1))
        // Definir o conteúdo da página

        let str = 'Page ' + data.pageCount;



        // Página atual e total de páginas
        if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp;

        }

        // Adicionar o texto no rodapé
        doc.setFontSize(10);
        doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);

    };






    // Adicione conteúdo a cada página
    for (let i = 0; i < pageCount; i++) {
        doc.setPage(i);

        if (i > 0) {
            doc.addPage();
        }

        // Seu código existente para adicionar conteúdo vai aqui

        // Configuração da tabela
        // 'Rentab.'
        const headerTable = ['Código', 'Descrição', 'Qtd', 'Vl. Unit', 'Vl. Promo.', 'Vl. Negoc.', 'Dif. em %', 'X18', 'CC 1', 'CC 2', 'Total'];
        value.sort((a, b) => a.sku.localeCompare(b.sku))
        console.log(value)
        const tableData = value.map((item) =>

            [
                item.sku,
                item.skuDescricao = capitalizeFirst(item.skuDescricao),
                item.quantidade,
                `${item.precoDe ? convertMonetaryValueToNumber(item.precoDe) : convertMonetaryValueToNumber(item.valorUnitario)}`,
                `${item.precoDe && item.valorUnitario && item.valorUnitario != item.precoDe ? convertMonetaryValueToNumber(item.valorUnitario) : ''}`,
                `${item.valorUnitarioNegociado && item.valorUnitario != item.valorUnitarioNegociado ? convertMonetaryValueToNumber(item.valorUnitarioNegociado) : ''}`,
                item.valorUnitarioNegociado ? `${((item.valorUnitarioNegociado / item.valorUnitario - 1) * 100).toFixed(2)}%` : '0.00%',
                `${item.X18 || item.custo ? convertMonetaryValueToNumber(item.custo ? item.custo : item.X18) : 'R$ 0.00'}`, // Replace with actual X18 value
                `${item.precoC ? convertMonetaryValueToNumber(item.precoC) : 'R$ 0.00'}`, // Replace with actual CC 1 value
                `${item.precoR ? convertMonetaryValueToNumber(item.precoR) : 'R$ 0.00'}`, // Replace with actual CC 2 value
                // `${item.X18 ? ((item.valorUnitario / item.X18- 1) *10).toFixed(2): '0.00'}%`,
                `${item.valorBruto ? convertMonetaryValueToNumber(item.valorBruto) : 'R$ 0.00'}` // Replace with actual Total value
            ]);
        let cellPadding = 1;
        const options = {
            startY: 55, // Ajuste a posição x inicial
            startX: 50, // Centralizar a tabela verticalmente
            margin: { top: 10 }, // Ajuste a margem superior
            theme: 'grid',
            styles: {
                lineColor: [0, 0, 0], // Cor das linhas (preto)
                lineWidth: 0.1,         // Espessura das linhas
            },

            headStyles: { fillColor: [41, 128, 185], align: 'center', fontStyle: 'bold', fontSize: 9 },
            willDrawCell: function (data) {
                if (Object.values(data.row.cells).some(cell => parseFloat(cell.raw) < 0)) {
                    doc.setFillColor(235, 235, 235)
                }
            },
            bodyStyles: { fontSize: 11, minCellHeight: 11 },
            afterPageContent: pageContent,            // ... Opções existentes
            columnStyles: {
                0: { // Índice da coluna (0 para a primeira coluna, 1 para a segunda, etc.)
                    columnWidth: 28, // Definir a largura da primeira coluna (em unidades do PDF)
                    halign: 'left', // Definir o alinhamento horizontal da primeira coluna.
                    cellPadding, // Definir o preenchimento da célula (padrão 5)
                },
                1: {
                    columnWidth: 65,
                    cellPadding
                },
                2: {
                    columnWidth: 9,
                    cellPadding
                },
                3: {
                    columnWidth: 22,
                    cellPadding
                },
                4: {
                    columnWidth: 22,
                    cellPadding
                },
                5: {
                    columnWidth: 22,
                    cellPadding
                },
                6: {
                    columnWidth: 18,
                    cellPadding
                },
                7: {
                    columnWidth: 22,
                    cellPadding
                },
                8: {
                    columnWidth: 22,
                    cellPadding
                },
                9: {
                    columnWidth: 22,
                    cellPadding
                },
                10: {
                    columnWidth: 25,
                    cellPadding
                }
            },

        };
        doc.setFontSize(10);
        doc.autoTable(headerTable, tableData, options);
    }
    doc.setFontSize(12);
    doc.setTextColor(0);

    let posY = doc.autoTable.previous.finalY;

    // Adicionar subtotal
    const gross = value.reduce((acc, item) => (item.valorUnitario * item.quantidade) + acc, 0)
    const negotiatedPrice = value.reduce((acc, item) => (item.valorUnitarioNegociado * item.quantidade) + acc, 0)
    const percentual = (gross - negotiatedPrice) * 100 / gross ? ((gross - negotiatedPrice) * 100 / gross).toFixed(2) : '0,00%'

    // SUBTOTAL 
    doc.setFontSize(fontLabel);
    doc.setTextColor(80);
    doc.setFont('helvetica', 'bold');
    doc.text(`Total Pedido: ${convertMonetaryValueToNumber(gross)}`, 240, posY + 5);

    doc.setFontSize(fontLabel);
    doc.setTextColor(80);
    doc.setFont('helvetica', 'bold');
    doc.text(`Total Negociado: ${convertMonetaryValueToNumber(negotiatedPrice)}`, 240, posY + 10);

    doc.setFontSize(fontLabel);
    doc.setTextColor(80);
    doc.setFont('helvetica', 'bold');
    doc.text(`Dif. em %: ${percentual}%`, 240, posY + 15);


    // Desenhar linha fina no topo
    doc.setTextColor(80);
    doc.setLineWidth(0.3); // Defina a espessura da linha
    doc.line(15, posY + 20, doc.internal.pageSize.getWidth() - 15, posY + 20);



    doc.save(`Pedido - ${value[0].numero ? value[0].numero : ''}.pdf`);

    onClosed();
};
