import React from "react";
import * as Io5Icons from "react-icons/io5";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { authToken } from "../context/user";
import { useAtom } from "jotai";

export const SidebarData = () => {

    const [valueToken] = useAtom(authToken);

    return [
        {
            title: "Produtos",
            path: "/produtos",
            view: true,
            icon: <WebAssetOutlinedIcon />,
        },
        {
            title: "Vendas",
            view: true,
            icon: <ShoppingCartOutlinedIcon />,
            // iconClosed: <RiIcons.RiArrowDownSFill />,
            // iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: "Carrinho",
                    path: "/carrinho",
                    view: true,
                    icon: <LocalMallOutlinedIcon />,
                },
                {
                    title: "Orçamento",
                    path: "/orcamento",
                    view: true,
                    icon: <ArticleOutlinedIcon />,
                },
                {
                    title: "Pedidos",
                    path: "/pedidos",
                    view: true,
                    icon: <SellOutlinedIcon />,
                },

            ],
        },
        {
            title: "Clientes",
            path: "/clientes",
            view: true,
            icon: <PersonIcon />,
        },
        {
            title: "Talao",
            path: "/talao",
            view:
                valueToken.talaoVenda
            ,
            icon: <ReceiptLongOutlinedIcon />,
        },
        {
            title: "Sair",
            path: "/login",
            view: true,
            icon: <Io5Icons.IoExitOutline />,
        },
    ]
};
