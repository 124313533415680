// eslint-disable-next-line

import axios from "axios";
import jsPDF from "jspdf";
import { useState } from "react";



const PDF = () => {
  const [imageURI, setImageURI] = useState(null);

  const handleImageDownload = async () => {
    try {
      const imageUrl = 'https://cdn62.picsart.com/182788826000202.jpg'; 

      // 'https://www.zeene.com.br/mkt/imagens/5C6919275GRF.jpg'

      const response = await axios.get(imageUrl, { responseType: 'blob' });
      const blob = response.data;
      const objectURL = URL.createObjectURL(blob);

      setImageURI(objectURL);

      await generatePDF()
    } catch (error) {
      console.error('Erro ao baixar a imagem:', error);
    }
  };

  const generatePDF = async () => {
    const doc = new jsPDF();

    // Configurações do card
    const cardWidth = 80;
    const cardHeight = 100;
    const borderColor = '#CCCCCC'; // Cor da borda

    // Configurações de texto e imagem
    const title = 'Nome do Produto';
    const productCode = 'Código: 12345';
    const price = 'Preço: R$ 99,99';
    const availability = 'Disponibilidade: Em estoque';
    let imageURL = 'https://cdn62.picsart.com/182788826000202.jpg';


    // Carregue a imagem e converta-a para Data URI



    // Defina a posição inicial do card
    let xPos = 10;
    let yPos = 10;

    // Crie o card
    doc.setDrawColor(borderColor); // Cor da borda
    doc.setLineWidth(0.5); // Espessura da borda
    doc.rect(xPos, yPos, cardWidth, cardHeight); // Desenhe o retângulo do card

    // Adicione a imagem
    const imgWidth = 40;
    const imgHeight = 40;
    doc.addImage(imageURI, 'JPEG', xPos + 5, yPos + 5, imgWidth, imgHeight);

    // Adicione o título
    doc.text(xPos + 5, yPos + 50, title);

    // Adicione o código do produto
    doc.text(xPos + 5, yPos + 60, productCode);

    // Adicione o preço
    doc.text(xPos + 5, yPos + 70, price);

    // Adicione a disponibilidade
    doc.text(xPos + 5, yPos + 80, availability);

    // Finalize o PDF
    doc.save('produto_card.pdf');
  };


  // No render do seu componente React
  return (
    <div>
      {/* <button onClick={handleImageDownload}>Gerar PDF</button> */}
    </div>
  );
}


export default PDF