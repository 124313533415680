import React, { useEffect } from 'react';
import styled from 'styled-components';
import IconButton from './IconButton';
import { device } from './device.config';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:1200;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  padding:25px;
  background-color: #fff;
  display:flex;
  flex-wrap:wrap;
  z-index:1200;
  border-radius:15px;
 flex-direction:row; 
  max-width: 800px;
  min-width: 800px;
  min-height: 500px;
  max-height: 800px;
  @media ${device.laptopL} {
    height:400px;
}
`;

const ModalButton = styled.div`
  flex-direction: row;
  display:flex;
  margin:0 auto; 
  margin-top:20px;

  `;
const ModalItem = styled.p`
  text-align:start;
  width:50%;
  margin-bottom:15px;
  @media ${device.laptopL} {
 height:40px;
}
`;

const ModalItemHeaders = styled.p`
  width:100%;
  margin-bottom:0px;
  @media ${device.laptopL} {
 height:40px;
}
  
`;
const ModalItemStatus = styled.div`
display:flex;
flex-direction:row;
`;



export const Label = styled.p`
width:450px;
font-size:20px;
font-weight:800;
`

export const LabeInput = styled.p`

font-weight:800;
margin-bottom:5px;
`
export const InputModalCustormers = styled.input`
border-radius:4px;
font-style: normal;
font-weight: 400;
font-size: 14px;
border:none;
background: #D9D9D9;
width:350px;
border-radius: 4px;
height:30px;
padding:10px;
color: #2E2E2E;

`
export const Divider = styled.hr`
  margin-top:20px;
  margin-bottom:20px;`

const ModalCustomers = ({ isOpen, onClose, value }) => {

    if (!isOpen) {
        return null;
    }


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.keyCode === 27) {
                onClose()
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleClick = () => {
        onClose()
    };
    const replaceName =
    {
        1: 'Inativo',
        0: 'Ativo',
        9: 'Desativado',
        4: 'Financeiro em aberto'
    }

    return (
        <ModalOverlay>
            <ModalContainer>
                <ModalItemHeaders>
                    <Label>PERFIL DA EMPRESA</Label>
                    <Divider />
                </ModalItemHeaders>
                <ModalItem>
                    <LabeInput>Razão Social:</LabeInput>
                    <InputModalCustormers disabled value={value.nome} />
                </ModalItem>
                <ModalItem>
                    <LabeInput>CNPJ:</LabeInput>  <InputModalCustormers disabled value={value.documento} />
                </ModalItem>
                <ModalItem>
                    <ModalItemStatus>
                        <ModalItem>
                            <p><LabeInput>Código:</LabeInput></p><InputModalCustormers style={{ width: 150 }} disabled value={value.codigo} />
                        </ModalItem>
                        <ModalItem>
                            <p><LabeInput>Status:</LabeInput></p><InputModalCustormers style={{ width: 160 }} disabled value={replaceName[value.status]} />
                        </ModalItem>
                    </ModalItemStatus>
                </ModalItem>
                <ModalItem>
                    <LabeInput>Email:</LabeInput><InputModalCustormers disabled value={value.mail} />
                </ModalItem>
                <ModalItem>
                    <LabeInput>Endereço:</LabeInput>  <InputModalCustormers disabled value={value.logradouro} />
                </ModalItem>
                <ModalItem>
                    <LabeInput>Bairro:</LabeInput> <InputModalCustormers disabled value={value.bairro} />
                </ModalItem>
                <ModalItem>
                    <LabeInput>Cidade:</LabeInput><InputModalCustormers disabled value={value.cidade} />
                </ModalItem>
                <ModalItem>
                    <p><LabeInput>Estado:</LabeInput></p><InputModalCustormers disabled value={value.UF} />
                </ModalItem>
                <ModalItem>
                    <LabeInput>Últ. Transportadora:</LabeInput><InputModalCustormers disabled value={value.UltimaCompra ? new Date(value.UltimaCompra).toLocaleDateString() : ''} />
                </ModalItem>
                <ModalItem>
                    <LabeInput>Últ. Compra:</LabeInput><InputModalCustormers disabled value={value.UltimaCompra ? new Date(value.UltimaCompra).toLocaleDateString() : ''} />
                </ModalItem>
                <ModalButton>
                    <ModalItem>
                        <IconButton
                            onClick={handleClick}
                            style={{ width: '130px' }}
                            iconName={'closed'}
                            label={'Fechar'} />
                    </ModalItem>
                </ModalButton>
            </ModalContainer>
        </ModalOverlay>
    );
};

export default ModalCustomers;
