import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "./device.config";

const SidebarLink = styled(Link)`
display: flex;
color: #e1e9fc;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
z-index:0;

background-color:"#0771AC";
height: 60px;
text-decoration: none;
font-size: 18px;
&:hover {
	background:#07689D;
	cursor: pointer;
    z-index:0;
}
`;
const SiderLoginLink = styled(Link)`
display: flex;
color: #e1e9fc;
z-index:1200;
justify-content: space-between;
align-items: center;
background-color:"#0771AC";
padding: 20px;
list-style: none;
height: 60px;
text-decoration: none;
font-size: 18px;
width: 270px;
position: fixed;
bottom:33px;
&:hover {
	background: #07689D;
	cursor: pointer;
    z-index:1200;
}
@media ${device.laptopL} { 
    bottom: 0;
    background:  #07689D;
}
`;
const SidebarLabel = styled.div`
display:flex;
gap:8px;
margin-left: 16px;
`;

const SidebarLabelLogin = styled.div`
background-color:"#0771AC";
display:flex;
gap:8px;
font-size:14px;
margin-left: 16px;
`;

const DropdownLink = styled(Link)`
background-color:#0771AC;
height: 60px;
padding-left: 5rem;
display: flex;
align-items: center;
text-decoration: none;
color: #f5f5f5;
font-size: 18px;
&:hover {
	&:hover {
        background-color:#07689D;

	cursor: pointer;
}
}
`;

const ContainerSideBarLogin = styled.div`
display: flex;
flex-direction:column;
z-index:1200;
background-color:"#0771AC";
position: fixed;
min-height:100px;
@media ${device.tablet} { 
  max-height:768px;
  position:fixed;
}

`

const ContainerSideBarTipographyLogin = styled.div`
display: flex;
flex-direction:column;
height:110px;
position: absolute;
bottom: 30px;
@media ${device.laptopL} { 
  display:none;
}
`
const ContainerSideBarCopy = styled.div`
display: flex;
color:white;
flex-direction:column;
position: fixed;
font-weight:0;
font-size:10px;
margin:5px;
bottom: 0px;
@media ${device.laptopL} { 
  display:none;
}

`
const Typography = styled.p`
text-decoration:none;
margin-left:40px;
color:white;
font-weight:500;
font-size:16px;
inline-size:177px;
@media ${device.laptop} { 
  display:none
}
`
const TypographyFixed = styled.p`
margin-left:40px;
font-size:16px;
z-index:1200;
font-family:sans-serif;
font-weight:20;
color:white;
inline-size:177px;
@media ${device.laptop} { 
    display:none
}
`
const ContainerMenu = styled.div`
display:flex;
flex-direction:column;
text-align:center;

`

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    const { name } = JSON.parse(localStorage.getItem('authToken'))
   
    return (<>
        <ContainerMenu>
            {item.path !== '/login' && item.view &&
                <SidebarLink to={item.path}
                    onClick={item.subNav && showSubnav}>
                    <div>
                        <SidebarLabel>  {item.icon} {item.title}</SidebarLabel>
                    </div>
                    <div>
                    </div>
                    <div >
                        {item.subNav && true
                            ? item.iconOpened
                            : item.subNav
                                ? item.iconClosed
                                : null}
                    </div>
                </SidebarLink >
            }
        </ContainerMenu>
        {
            item.path == '/login' &&
            <div style={{ backgroundColor: 'black' }}>
                <ContainerSideBarTipographyLogin>
                    <TypographyFixed>{'Bem vindo (a),'}</TypographyFixed>
                    <Typography>{name ? name : ''}</Typography>
                </ContainerSideBarTipographyLogin>
                <ContainerSideBarLogin>
                    <SiderLoginLink to={item.path}
                        onClick={item.subNav && showSubnav}>
                        <SidebarLabelLogin> {item.icon}  {item.title}</SidebarLabelLogin>
                        <div>
                            {item.subNav && subnav
                                ? item.iconOpened
                                : item.subNav
                                    ? item.iconClosed
                                    : null}

                        </div>
                    </SiderLoginLink>
                </ContainerSideBarLogin>
                <ContainerSideBarCopy>
                    © 2023. Peças Automotivas Zeene
                </ContainerSideBarCopy>
            </div>
        }

        {

            item.subNav && item.subNav.map((item, index) => {
                return (
                    <DropdownLink to={item.path} key={index}>
                        {item.icon}
                        <SidebarLabel>{item.title}</SidebarLabel>
                    </DropdownLink>
                );
            })
        }
    </>
    );
};

export default SubMenu;
