import axios from 'axios'
import { getToken } from '../PrivateRoute.jsx'

let baseUrl = 'https://api.zeene.com.br'
export const getPartner = async (limit = 100, page = 1) => {
  try {
    let Authorization = await getToken()
    const partners = await axios({
      method: 'get',
      timeout: '30000',
      url: `${baseUrl}/v1/customer?limit=${limit}&page=${page}`,
      headers: {
        Authorization
      }
    })
    return partners.data
  } catch (error) {
    console.log(error)
  }
}

export const insertOrder = async data => {
  try {
    let Authorization = await getToken()
    const orders = await axios({
      method: 'post',
      timeout: '30000',
      url: `${baseUrl}/v1/order`,
      headers: {
        Authorization
      },
      data
    })
    return orders.data
  } catch (error) {
    console.log(error)
  }
}

export const insertOrderInvento = async data => {
  try {
    let Authorization = await getToken()
    const orders = await axios({
      method: 'post',
      timeout: '30000',
      url: `${baseUrl}/v1/order/invento`,
      headers: {
        Authorization
      },
      data
    })
    return orders.data
  } catch (error) {
    throw error
  }
}

export const getPartnerId = async search => {
  try {
    let Authorization = await getToken()
    const partners = await axios({
      method: 'get',
      timeout: '30000',
      url: `${baseUrl}/v1/customerid`,
      headers: {
        Authorization
      },
      params: { search }
    })
    return partners.data.result
  } catch (error) {
    console.log(error)
  }
}

export const getPartnerIdInativo = async search => {
  try {
    let Authorization = await getToken()
    const partners = await axios({
      method: 'get',
      timeout: '30000',
      url: `${baseUrl}/v1/customerid/filter`,
      headers: {
        Authorization
      },
      params: { search }
    })
    return partners.data.result
  } catch (error) {
    console.log(error)
  }
}

export const getOrder = async search => {
  try {
    let Authorization = await getToken()
    const partners = await axios({
      method: 'get',
      timeout: '30000',
      url: `${baseUrl}/v1/order`,
      headers: {
        Authorization
      },
      params: { ...search, limit: 10 }
    })
    return partners.data
  } catch (error) {
    console.log(error)
  }
}

export const getOrderIdTalaoVenda = async search => {
  try {
    let Authorization = await getToken()
    const partners = await axios({
      method: 'get',
      timeout: '30000',
      url: `${baseUrl}/v1/order/talaovenda`,
      headers: {
        Authorization
      },
      params: { orderId: search }
    })
    return partners.data
  } catch (error) {
    console.log(error)
  }
}

export const getProduct = async (limit = 100, page = 1, filter) => {
  try {
    let Authorization = await getToken()
    const products = await axios({
      method: 'get',
      timeout: '30000',
      url: `${baseUrl}/v1/product?limit=${limit}&page=${page}`,
      headers: {
        Authorization
      },
      params: filter
    })
    return products.data
  } catch (error) {
    throw error
  }
}

export const getProductReport = async (limit = 100, page = 1, filter) => {
  try {
    let Authorization = await getToken()
    const products = await axios({
      method: 'get',
      timeout: '30000',
      url: `${baseUrl}/v1/product/report?limit=${limit}&page=${page}`,
      headers: {
        Authorization
      },
      params: filter
    })
    return products.data
  } catch (error) {
    throw error
  }
}

export const deleteOrder = async search => {
  try {
    let Authorization = await getToken()
    const partners = await axios({
      method: 'delete',
      timeout: '30000',
      url: `${baseUrl}/v1/order?orderid=${search.orderid}`,
      headers: {
        Authorization
      }
    })
    return partners
  } catch (error) {
    console.log(error)
  }
}

export const orderSync = async search => {
  try {
    let Authorization = await getToken()
    const partners = await axios({
      method: 'delete',
      timeout: '30000',
      url: `${baseUrl}/v1/order/sync?orderid=${search.orderid}`,
      headers: {
        Authorization
      }
    })
    return partners
  } catch (error) {
    console.log(error)
  }
}

export const validateOrder = async data => {
  try {
    let Authorization = await getToken()
    const newOrder = await axios({
      method: 'post',
      timeout: '30000',
      url: `${baseUrl}/v1/order/verify`,
      headers: {
        Authorization
      },
      data
    })
    return newOrder.data
  } catch (error) {
    console.log(error)
  }
}
