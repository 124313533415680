import styled from "styled-components";
import { device } from "./device.config";



export const Button = styled.button`
  background:${(props) => (props.background ? props.background : '#07689D')} ;
  color:${(props) => (props.color ? props.color : 'white')} ;
  border: 1px solid rgba(243, 239, 239, 0);
  border-radius: ${(props) => (props.radius ? props.radius : '8px')} ;
  width:${(props) => (props.width ? props.width : '100%')} ;
  height:${(props) => (props.height ? props.height : '50px')} ;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor:pointer;
  }
  :disabled {
    background-color: #d2d2d2;
     color: black;
    opacity: 1;
  }
 
`

