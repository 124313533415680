import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import bannerLogo from "../assets/banner-zeene.jpg"
import { Card } from "../components/Card";
import { getProduct, getProductReport } from "../http/portalb2b.api";
import styled from "styled-components";
import { Input } from "../components/Input.jsx";
import IconButton from "../components/IconButton";
import { Select } from "../components/Select";
import { enqueueSnackbar } from "notistack";
import { device } from "../components/device.config";
import { brand } from "../temp/brand"
import { category } from "../temp/category.js"
import { status } from "../temp/status.js"
import ClearButton from "../components/ClearButton";
import PaginationOrders from "../components/PaginationOrders";
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import Table from "../components/Table";
import { Downloading } from "@mui/icons-material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { createWorkSheet, writeXLSX } from "../components/xlsx";
import { useAtom } from "jotai";
import { isUserAuthorized } from "../context/user";
import CatalogoPDF from "../components/ReportPDFTCatalago";
import { Fragment } from "react";
import { useMediaQuery } from "@mui/material";

const ProductsContainer = styled.div`
 	display:flex;
	justify-content: center;
	flex-direction:column;
	width:100%;
	
	&.test{
		align-items: center;
		
	}
    border-radius:10px;
	gap:20px;
	@media ${device.laptopL} { 
	 }

`
const ProductsFilterContainer = styled.div`
	display: flex;
	gap:5px;
	transition: all 0.3s ease;
	&.fixo {
    position: fixed;
	padding:30px 30px 10px 30px;
	border-radius:10px;
    top: 0;
	background-color: rgb(255, 255, 255);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
	@media ${device.laptopL} { 
		margin-top:30px;
	 }
	 @media ${device.tablet} { 
		flex-direction:column;
	 }



	
	
	
`
const ProductsFilterItem = styled.div`
width:100%;
display:flex;
gap:5px;
flex-direction:column;
@media ${device.desktop} { 

	 }
`

const FilterButtonContainer = styled.div`
display: flex;
justify-content: flex-end;
flex-wrap: wrap;
gap:5px;

 @media ${device.laptopL} { 
	justify-content: flex-end;

	 }
`
const FilterButtonItem = styled.div`

width:100%;

`
const FilterButtonItemClear = styled.div`
width: 120px;
display:flex;
`
const ProductsImage = styled.img`
  display:flex;
  border-radius: 10px;
  justify-content:center;
  width:100%;

   @media ${device.laptopL} { 
		display: none;
 }`
const CardContainer = styled.div`
    display:flex;
    width:230px;
	flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    text-align:'center'; 
    @media ${device.laptopL} { 
      padding:5px;
    }
 `
const ButtonContainer = styled.div`
display:flex;
width:100%;
gap:20px;
flex-direction:row;
justify-content: flex-end;
`
const IconButtonContainer = styled.div`
display:flex;
gap:10px;
width:100%;

`

const IconStyle = {
	color: '#07689D',
	backgroundColor: 'white',
	fontSize: 20,
}

export const Products = () => {
	const [productsData, setProductsData] = useState({ items: [] })
	const [pagination, setPagination] = useState({
		currentPage: 1,
		totalPages: 1,
	})
	const [filterValue, setFilterValue] = useState({})
	const [isActiveCard, setIsActiveCard] = useState(true)
	const [isActiveList, setIsActiveList] = useState(false)
	const [countItem, setCountItem] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingIcon, setIsLoadingIcon] = useState(false)
	const [_, setIsAuthorized] = useAtom(isUserAuthorized);
	const [isActiveFilter, setIsActiveFilter] = useState(true)
	const [disabledButton, setDisabledButton] = useState(false)


	useEffect(() => {

		getProduct(100, pagination.currentPage, { ...filterValue }).then((products) => {
			setProductsData({ ...pagination, ...products })

		})
	}, [pagination])


	useEffect(() => {

		let cartData = JSON.parse(localStorage.getItem('cart'))
		if (cartData) {
			setCountItem([...cartData])
		}

	}, [])

	const handleAddCart = (data) => {

		let cartData = JSON.parse(localStorage.getItem('cart'))
		if (!cartData) cartData = []
		let newCardData = [...cartData]
		let quantity = 0
		quantity = newCardData.find(card => card.codigo == data.codigo) ? newCardData.find(card => card.codigo == data.codigo).quantity + 1 : 1
		let distinctItemCardData = newCardData.filter(card => card.codigo != data.codigo)


		localStorage.setItem('cart', JSON.stringify([...distinctItemCardData, {
			...data,
			quantity,
			negotiatedPrice: 0
		}]))



		setCountItem([...distinctItemCardData, { ...data, quantity }])
	}

	const handleRemoveCart = (data) => {

		let cartItems = JSON.parse(localStorage.getItem('cart'))
		if (!cartItems) cartItems = []
		let quantity = cartItems.find(cartItem => cartItem.codigo == data.codigo) ? cartItems.find(cartItem => cartItem.codigo == data.codigo).quantity > 0 ? cartItems.find(cartItem => cartItem.codigo == data.codigo).quantity - 1 : 0 : 0
		let distinctItemCardData = cartItems.filter(cartItem => cartItem.codigo != data.codigo)

		if (quantity == 0) {
			//Apagar do Carrinho
			localStorage.setItem('cart', JSON.stringify([...distinctItemCardData]))
			setCountItem([...distinctItemCardData])
		} else {
			// Decrementar estoque e manter no carrinho
			localStorage.setItem('cart', JSON.stringify([...distinctItemCardData, { ...data, quantity }]))
			setCountItem([...distinctItemCardData, { ...data, quantity }])
		}

	}

	const handleChange = (e) => {

		e.preventDefault()

		const { name, value } = e.target;


		setFilterValue((current) => {
			return {
				...current,
				[name]: value,
			}
		})
	}

	const handleResetFilter = () => {
		setFilterValue({ marca: '', descricao: '', codigo: '', categoria: '', avaible: '' })
		setPagination({
			currentPage: 1,
			totalPages: 1
		}
		)
	}


	const quantityCardItem = (data, quantity) => {

		let cartData = JSON.parse(localStorage.getItem('cart'))
		if (!cartData) cartData = []
		let newCardData = [...cartData]
		quantity = newCardData.find(card => card.codigo == data.codigo) ? Number(quantity) : Number(quantity)
		let distinctItemCardData = newCardData.filter(card => card.codigo != data.codigo)

		if (quantity > 0) {
			localStorage.setItem('cart', JSON.stringify([...distinctItemCardData, {
				...data,
				quantity,
				negotiatedPrice: 0
			}]))



			setCountItem([...distinctItemCardData, { ...data, quantity }])
		} else {
			localStorage.setItem('cart', JSON.stringify([...distinctItemCardData]))
			setCountItem([...distinctItemCardData, { ...data, quantity }])
		}
	}

	const handleChangeItem = (e, data) => {
		e.preventDefault()
		const { value } = e.target;

		quantityCardItem(data, value)

	}

	const columnsDevice = [
		{
			path: '',
			name: 'Grade de Itens',
			customFilter: true,
			isActiveFilter: true,
			attribute: (_, dataRaw) => {


				return (
					<Fragment>

						<CardContainer>
							<p style={{ padding: 10 }}>Codigo:{dataRaw.codigo}</p>
							<p style={{ padding: 10 }}>Marca:{dataRaw.marca}</p>
							<p style={{ padding: 10 }}>Categoria:{dataRaw.categoria}</p>
							<p style={{ padding: 10 }}>Preço De:{dataRaw.price}</p>
							<p style={{ padding: 10 }}>Preço Por:{dataRaw.fromPrice}</p>

							<IconButton
								disabled={dataRaw.avaible == false ? true : false}
								onClick={() => { handleRemoveCart(dataRaw) }}
								style={{ width: '20px', height: '5px', borderRadius: 0, background: 'none', }} >
								<RemoveIcon style={IconStyle} />
							</IconButton>
							<Input
								onChange={(e) => { handleChangeItem(e, dataRaw) }}
								value={countItem.find(f => f.codigo == dataRaw.codigo) ?
									countItem.find(f => f.codigo == dataRaw.codigo).quantity : 0
								}
								style={{ margin: 10, height: 10, width: 40, fontSize: 11, textAlign: 'center', color: 'black', backgroundColor: '#e2e2e2' }}

							/>

							<IconButton
								disabled={dataRaw.avaible == false ? true : false}
								onClick={() => { handleAddCart(dataRaw) }}
								style={{ width: '20px', height: '20px', borderRadius: 0, background: 'none' }}
							>
								<AddIcon style={IconStyle} />
							</IconButton>
						</CardContainer>


					</Fragment>
				)
			}

		}
	]

	const columnsTable = [
		{
			path: 'descricao',
			name: 'Nome do Produto',
			isActiveFilter: true,
			value: filterValue.descricao,
			onChange: handleChange,
			attribute: (value) => {
				return <p style={{ padding: 10 }}>{value}</p>
			}

		},
		{
			path: 'codigo',
			name: 'Código/SKU',
			value: filterValue.codigo,
			isActiveFilter: true,
			onChange: handleChange,


		},
		{
			path: 'categoria',
			name: 'Categoria',
			isActiveFilter: true,
			value: filterValue.categoria,
			options: category,
			onChange: handleChange
		},

		{
			path: 'avaible',
			name: 'Status',
			isActiveFilter: true,
			value: filterValue.avaible,
			onChange: handleChange,
			attribute: (value) => {
				return value == true ? 'Disponível' : 'Indisponivel'
			}
		},
		{
			path: 'marca',
			name: 'Marca',
			isActiveFilter: true,
			value: filterValue.marca,
			onChange: handleChange
		},
		{
			path: 'price',
			name: 'Preço De',
			isActiveFilter: true,
			value: filterValue.price,
			onChange: handleChange
		},
		{
			path: 'fromPrice',
			name: 'Preço Por',
			isActiveFilter: true,
			value: filterValue.fromPrice,
			onChange: handleChange
		},
		,
		{
			path: 'quantity',
			name: 'Quantidade',
			isActiveFilter: false,
			attribute: (quantity, value) => {
				return (
					<CardContainer>
						<IconButton
							disabled={value.avaible == false ? true : false}
							onClick={() => { handleRemoveCart(value) }}
							style={{ width: '20px', height: '5px', borderRadius: 0, background: 'none', }} >
							<RemoveIcon style={IconStyle} />
						</IconButton>
						<Input
							onChange={(e) => { handleChangeItem(e, value) }}
							value={countItem.find(f => f.codigo == value.codigo) ?
								countItem.find(f => f.codigo == value.codigo).quantity : 0
							}
							style={{ margin: 10, height: 10, width: 40, fontSize: 11, textAlign: 'center', color: 'black', backgroundColor: '#e2e2e2' }}

						/>

						<IconButton
							disabled={value.avaible == false ? true : false}
							onClick={() => { handleAddCart(value) }}
							style={{ width: '20px', height: '20px', borderRadius: 0, background: 'none' }}
						>
							<AddIcon style={IconStyle} />
						</IconButton>
					</CardContainer>
				)

			}

		},

	]

	const columnsTableVistors = [
		{
			path: 'descricao',
			name: 'Nome do Produto',
			isActiveFilter: true,
			value: filterValue.descricao,
			onChange: handleChange,
			attribute: (value) => {
				return <p style={{ padding: 10 }}>{value}</p>
			}

		},
		{
			path: 'codigo',
			name: 'Código/SKU',
			value: filterValue.codigo,
			isActiveFilter: true,
			onChange: handleChange,


		},
		{
			path: 'categoria',
			name: 'Categoria',
			isActiveFilter: true,
			value: filterValue.categoria,
			options: category,
			onChange: handleChange
		},

		{
			path: 'avaible',
			name: 'Status',
			isActiveFilter: true,
			value: filterValue.avaible,
			onChange: handleChange,
			attribute: (value) => {
				return value == true ? 'Disponível' : 'Indisponivel'
			}
		},
		{
			path: 'marca',
			name: 'Marca',
			isActiveFilter: true,
			value: filterValue.marca,
			onChange: handleChange
		},
		{
			path: 'quantity',
			name: 'Quantidade',
			isActiveFilter: false,
			attribute: (quantity, value) => {
				return (
					<CardContainer>
						<IconButton
							disabled={value.avaible == false ? true : false}
							onClick={() => { handleRemoveCart(value) }}
							style={{ width: '20px', height: '5px', borderRadius: 0, background: 'none', }} >
							<RemoveIcon style={IconStyle} />
						</IconButton>
						<Input
							onChange={(e) => { handleChangeItem(e, value) }}
							value={countItem.find(f => f.codigo == value.codigo) ?
								countItem.find(f => f.codigo == value.codigo).quantity : 0
							}
							style={{ margin: 10, height: 10, width: 40, fontSize: 11, textAlign: 'center', color: 'black', backgroundColor: '#e2e2e2' }}

						/>

						<IconButton
							disabled={value.avaible == false ? true : false}
							onClick={() => { handleAddCart(value) }}
							style={{ width: '20px', height: '20px', borderRadius: 0, background: 'none' }}
						>
							<AddIcon style={IconStyle} />
						</IconButton>
					</CardContainer>
				)

			}

		},

	]

	const handleSubmitFilter = () => {
		setIsLoadingIcon(true)
		const filter = { ...filterValue }
		getProduct(100, pagination.currentPage, filter).then((products) => {

			if (products.result.length > 0) {
				setProductsData(products)

			}
			else {
				enqueueSnackbar('Não foi localizado nenhum item para o filtro informado !', { variant: 'info' })
				handleResetFilter()
			}
			setIsLoadingIcon(false)
		}).catch((error) => {
			if (error == 'Token expirado ou invalido !') {
				setIsAuthorized(false)
			}
		})

	}

	const handleKeyDown = (event) => {
		if (event.keyCode === 13 ||
			event.keyCode === 18) { // Verifica se a tecla pressionada é "Enter"
			handleSubmitFilter(); // Chama a função de clique no botão
		}
	};

	const generateExcelReport = async () => {
		setIsLoading(true)
		let products = []
		const produtoInicial = await getProductReport(5000, 1);

		for (let i = 1; i <= produtoInicial.totalPages; i++) {

			if (i == 1) { products.push(...produtoInicial.result) }
			else {
				const product = await getProductReport(5000, i);
				products.push(...product.result)
			}
		}

		writeXLSX('Produto.xlsx', [createWorkSheet(products, `Produtos`)]);
		setIsLoading(false)
	};

	const handleScroll = () => {

		const filtroElement = document.getElementById("filtro");
		const filtroElementContainer = document.getElementById("filtroContainer");


		if (filtroElement) {
			const shouldHideFilter = ((window.scrollY >= 500)); // Esconde o filtro a 200 pixels do topo

			if (window.scrollY <= 500) {
				setIsActiveFilter(true)
				setDisabledButton(false)
			}
			if (shouldHideFilter) {
				setDisabledButton(true)
				filtroElement.classList.add("fixo");
				filtroElementContainer.classList.add("test");

			} else if (shouldHideFilter === false) {
				filtroElement.classList.remove("fixo");
				filtroElementContainer.classList.remove("test");

			}
		}
	};

	// Adicione um evento de rolagem ao componente para controlar o filtro fixo
	useLayoutEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const iswebdevice = useMediaQuery('(max-width:768px)');

	const { report } = JSON.parse(localStorage.getItem('authToken'))
	const ref = useRef();
	const { visitor } = JSON.parse(localStorage.getItem('authToken'))

	return (
		<ProductsContainer id="filtroContainer">
			<ProductsImage src={bannerLogo} />

			<ProductsFilterContainer id='filtro' >

				{!isActiveFilter &&
					<FilterButtonItemClear>
						{disabledButton &&
							<ClearButton iconName={isActiveFilter ? 'pin_end' : 'push_pin'} label={!isActiveFilter ? "Mostrar" : "Ocultar"} onClick={() => setIsActiveFilter((state) => !state)}></ClearButton>}
					</FilterButtonItemClear>}

				{isActiveFilter &&
					<Fragment>
						<ProductsFilterItem>
							<Input
								name={`descricao`}
								onKeyUp={handleKeyDown}
								placeholder="Nome do Produto"
								onChange={handleChange}
								value={filterValue.descricao}

							/>



							<Input
								name={`codigo`}
								onKeyUp={handleKeyDown}
								placeholder="Código/SKU"
								onChange={handleChange}
								value={filterValue.codigo}

							/>
						</ProductsFilterItem>

						<ProductsFilterItem>

							<Select
								name={`categoria`}
								onKeyUp={handleKeyDown}
								placeholder="Categoria"
								onChange={handleChange}
								value={filterValue.categoria}

							>
								<option value="" hidden >{"Categoria"}</option>
								{category.length > 0 && category.map((option, indexOption) => (<>
									<option key={indexOption} value={option} >{option}</option>
								</>))
								}
							</Select>

							<Select
								name={`marca`}
								onKeyUp={handleKeyDown}
								placeholder="Marca"
								onChange={handleChange}
								value={filterValue.marca}

							>
								<option value="" hidden >{"Marca"}</option>
								{brand.length > 0 && brand.map((option, indexOption) => (<>
									<option key={indexOption} value={option} >{option}</option>
								</>))
								}
							</Select>

						</ProductsFilterItem>

						<ProductsFilterItem >

							<Select
								name={`avaible`}
								onKeyUp={handleKeyDown}
								placeholder="Status"
								onChange={handleChange}
								value={filterValue.avaible}

							>
								<option value="" hidden >{"Status"}</option>
								{status.length > 0 && status.map((option, indexOption) => (<>
									<option key={indexOption} value={option} >{option}</option>
								</>))
								}</Select>




							<FilterButtonContainer>
								{isActiveFilter && (
									<>
										<FilterButtonItem >
											<IconButton
												iconName={'search'}
												isLoadingIcon={isLoadingIcon}
												label={'Pesquisar'}
												disabled={isLoadingIcon}
												onClick={handleSubmitFilter}
											/>

										</FilterButtonItem>
										<FilterButtonItemClear>
											<ClearButton
												iconName={'clear'}
												label={'Limpar Filtros'}
												onClick={handleResetFilter}
											/>
										</FilterButtonItemClear>
										<FilterButtonItemClear>
											{disabledButton && <ClearButton iconName={isActiveFilter ? 'pin_end' : 'push_pin'} label={!isActiveFilter ? "Mostrar" : "Ocultar"} onClick={() => setIsActiveFilter((state) => !state)}></ClearButton>}
										</FilterButtonItemClear>

									</>)


								}


							</FilterButtonContainer>
						</ProductsFilterItem>


					</Fragment>}

			</ProductsFilterContainer >

			<IconButtonContainer>
				<IconButton
					disabled={isActiveCard}
					onClick={() => {
						setIsActiveCard(true);
						setIsActiveList(false)
					}} style={{ width: 60 }}>
					<GridViewOutlinedIcon />
				</IconButton>
				<IconButton
					disabled={isActiveList}
					onClick={() => {
						setIsActiveList(true);
						setIsActiveCard(false)
					}} style={{ width: 60 }}>
					<TocOutlinedIcon />
				</IconButton>
				{report && (<ButtonContainer>
					<IconButton disabled={isLoading} label="Download" onClick={() => { generateExcelReport() }} style={{ width: 160 }}>
						<Downloading />
					</IconButton>
				</ButtonContainer>)}
			</IconButtonContainer>

			<CatalogoPDF></CatalogoPDF>
			{
				isActiveCard &&
				<Card
					setPagination={setPagination}
					currentPage={productsData.currentPage}
					totalPages={productsData.totalPages}
					data={{ items: productsData.result }} />
			}
			{
				isActiveList &&
				<Table data={productsData.result} columns={visitor ? columnsTableVistors : iswebdevice ? columnsDevice : columnsTable} />
			}
			<PaginationOrders page={productsData.currentPage} totalPage={productsData.totalPages} setPagination={setPagination} />
		</ProductsContainer >
	);

}


