import React, { Fragment, useRef, useState } from 'react'
import styled from 'styled-components'
import loading from '../assets/loading.gif'
import IconButton from './IconButton'
import ModalProduct from './ModalProduct'
import { device } from '../components/device.config.js'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { RiFileCopyLine } from 'react-icons/ri';
import { Input } from './Input'
import capitalizeFirst from '../lib/capitalizeFirst.js'


const CardIContainer = styled.div`
    display:flex;
    flex-wrap:wrap;
    border-radius:5px;
    justify-content: center;

    gap:7px;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* A maioria dos navegadores */;

 
 
  
`
const CardContainerImage = styled.div`
display:flex;
justify-content:center;
`
const CardItem = styled.div`
    border: 1px solid #F5F5F5;
    display:flex;
    justify-content: center;
    flex-direction:column;
    padding:5px;
    overflow:hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    background: #FFFFFF;
    align-items:center;
    border-radius:8px;
    height:350px;
       @media ${device.desktop} { 
       width:220px;
   }
   @media ${device.mobileL} { 
    width: 100%;
   }

 
      `

const CardAvatar = styled.div`
    display:flex;
    width:210px;
    overflow:hidden;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    text-align:'center'; 
   
 `
const CardAvatarPrice = styled.div`
 display:flex;
 width:210px;
 overflow:hidden;
 flex-wrap:wrap;
 align-items: center;
 text-align:'center'; 

`
const CardAvatarAvaible = styled.div`
    display:flex;
    width:210px;
    height: 20px;
    overflow:hidden;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
    text-align:'center'; 
   
 `
const CardAvatarCodigo = styled.div`
display:flex;
width:210px;
overflow:hidden;
flex-wrap:wrap;
align-items: center;
text-align:'center'; 

`
const CardImg = styled.div`
  background-image: url(${props => props.image});
  width: 180px;
  height:180px;
  cursor:pointer;
  flex-direction:column;
  background-size: cover;
  :hover{
   cursor:pointer;
    width:200px;
    transition: 0.5s;
  }
  @media ${device.laptopL} { 
    width: 100px;
    height:100px;
    :hover{
   cursor:pointer;
    width:100px;
    transition: 0.5s;
  }
    }`

const CardImgDevice = styled.div`
  @media ${device.mobileL} { 
  background-image: url(${props => props.image});
  width: 180px;
  height:180px;
  cursor:pointer;
  flex-direction:column;
  background-size: cover;
  :hover{
   cursor:pointer;
    width:200px;
    transition: 0.5s;
  }
    width: 100px;
    height:100px;
    :hover{
   cursor:pointer;
    width:100px;
    transition: 0.5s;
  }
    }`

const Paragraph = styled.div`
font-weight:500;
font-size:12px;
color: #7C7C7C;
text-align: start;
@media ${device.laptopL} { 
    font-weight:400;
    font-size:11px;
}
`
const ParagraphPrice = styled.div`
   color: #0a0606;
   font-weight:bold;;
   font-size:12px;
   width: 220px;
 
`;

const ParagraphDescription = styled.div`
   color: #0a0606;
   font-weight:1000;;
   font-size:13px;
   line-height: 15px;
   width: 210px;
`;

const ParagraphSKU = styled.div`
font-weight:500;
font-size:12px;
margin-right:140px;
color: #7C7C7C;
text-align: start;
@media ${device.laptopL} { 
    font-weight:400;
    font-size:11px;
}
`
const ParagraphDescritorValue = styled.div`
font-weight:500;
font-size:12px;
margin-right:140px;
color: #7C7C7C;
text-align: start;
@media ${device.laptopL} { 
    font-weight:400;
    font-size:11px;
}
`
const Container = styled.div`
  display: flex;
  align-items: center;
`;



const CopyIcon = styled(RiFileCopyLine)`
  font-size: 16px;
  color: #888;
  cursor: pointer;
`;

const LinkAnchor = styled.a`
@media ${device.mobileL} { 
    display:none;
}

`


export const Card = ({ data, setPagination, currentPage = 1, totalPages = 1 }) => {

    const [countItem, setCountItem] = useState([])
    const [modal, setModal] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [copied, setCopied] = useState(false);
    ;
    const copyToClipboard = (text) => {
        const valueToCopy = text ? text.substring(0, 100) : '';
        navigator.clipboard.writeText(valueToCopy);
        setCopied(!copied);
        // Reset the "copied" state after 2 seconds
    };

    const handleAddCart = (data) => {

        let cartData = JSON.parse(localStorage.getItem('cart'))
        if (!cartData) cartData = []
        let newCardData = [...cartData]
        let quantity = 0
        quantity = newCardData.find(card => card.codigo == data.codigo) ? newCardData.find(card => card.codigo == data.codigo).quantity + 1 : 1
        let distinctItemCardData = newCardData.filter(card => card.codigo != data.codigo)


        localStorage.setItem('cart', JSON.stringify([...distinctItemCardData, {
            ...data,
            quantity,
            negotiatedPrice: 0
        }]))



        setCountItem([...distinctItemCardData, { ...data, quantity }])
    }

    const handleRemoveCart = (data) => {

        let cartItems = JSON.parse(localStorage.getItem('cart'))
        if (!cartItems) cartItems = []
        let quantity = cartItems.find(cartItem => cartItem.codigo == data.codigo) ? cartItems.find(cartItem => cartItem.codigo == data.codigo).quantity > 0 ? cartItems.find(cartItem => cartItem.codigo == data.codigo).quantity - 1 : 0 : 0
        let distinctItemCardData = cartItems.filter(cartItem => cartItem.codigo != data.codigo)

        if (quantity == 0) {
            //Apagar do Carrinho
            localStorage.setItem('cart', JSON.stringify([...distinctItemCardData]))
            setCountItem([...distinctItemCardData])
        } else {
            // Decrementar estoque e manter no carrinho
            localStorage.setItem('cart', JSON.stringify([...distinctItemCardData, { ...data, quantity }]))
            setCountItem([...distinctItemCardData, { ...data, quantity }])
        }

    }


    useState(() => {

        let cartData = JSON.parse(localStorage.getItem('cart'))
        if (cartData) {
            setCountItem([...cartData])
        }

    }, [])



    const toggleModal = () => {
        setIsOpen(!isOpen)
    }


    const IconStyle = {
        color: '#07689D',
        backgroundColor: 'white',
        fontSize: 20,
    }

    const { visitor } = JSON.parse(localStorage.getItem('authToken'))


    const quantityCardItem = (data, quantity) => {

        let cartData = JSON.parse(localStorage.getItem('cart'))
        if (!cartData) cartData = []
        let newCardData = [...cartData]
        quantity = newCardData.find(card => card.codigo == data.codigo) ? Number(quantity) : Number(quantity)
        let distinctItemCardData = newCardData.filter(card => card.codigo != data.codigo)

        if (quantity > 0) {
            localStorage.setItem('cart', JSON.stringify([...distinctItemCardData, {
                ...data,
                quantity,
                negotiatedPrice: 0
            }]))



            setCountItem([...distinctItemCardData, { ...data, quantity }])
        } else {
            localStorage.setItem('cart', JSON.stringify([...distinctItemCardData]))
            setCountItem([...distinctItemCardData, { ...data, quantity }])
        }
    }

    const handleChange = (e, data) => {
        e.preventDefault()
        const { value } = e.target;

        quantityCardItem(data, value)

    }


    return (
        <Fragment>
            <ModalProduct isOpen={isOpen} value={modal} onClose={toggleModal} />
            <CardIContainer >
                {data.items && data.items.length > 0 ? data.items.map((value, index) => {
                    return (
                        <CardItem key={index}>
                            <CardAvatar>
                                <LinkAnchor onClick={() => { toggleModal(); setModal(value) }}><CardImg image={value.imagem}></CardImg></LinkAnchor>
                                <CardImgDevice image={value.imagem}></CardImgDevice>
                            </CardAvatar>
                            <CardAvatar>
                                <Tooltip title={"Clique para copiar título"}>
                                    <Container>
                                        <ParagraphDescription onClick={() => { copyToClipboard(value.descricao) }}>
                                            {value.descricao ? capitalizeFirst(value.descricao.substring(0, 100)) : ''}
                                            <CopyIcon onClick={() => {
                                                copyToClipboard(value.descricao)
                                            }} />
                                        </ParagraphDescription>
                                    </Container>
                                </Tooltip>
                            </CardAvatar>
                            <CardAvatarCodigo>
                                <Tooltip title={"Clique para copiar SKU"}>
                                    <Container>
                                        <div>
                                            <ParagraphSKU onClick={() => { copyToClipboard(value.codigo) }}>
                                                {value.codigo ? value.codigo.substring(0, 100) : ''}
                                            </ParagraphSKU>
                                            <CopyIcon onClick={() => {
                                                copyToClipboard(value.codigo)
                                            }} />
                                        </div>
                                        <div>
                                        </div>
                                    </Container>
                                </Tooltip>
                                <ParagraphDescritorValue onClick={() => { copyToClipboard(value.valorDescritor) }}>
                                    {value.valorDescritor ? value.valorDescritor.substring(0, 100) : ''}
                                </ParagraphDescritorValue>
                            </CardAvatarCodigo>
                            <CardAvatar>
                                {!visitor && value.fromPrice > value.price && (
                                    <CardAvatarPrice>
                                        <ParagraphPrice>
                                            Preço De: <span style={{ textDecoration: 'line-through', color: 'red' }}>{value.fromPrice > 0 ? ' R$' + value.fromPrice : ' R$0.00'} </span>
                                        </ParagraphPrice>
                                    </CardAvatarPrice>)}
                                {!visitor && (<ParagraphPrice>Preço Por:{value.fromPrice > 0 ? ' R$' + value.price : ' R$0.00'} </ParagraphPrice>)}
                            </CardAvatar>
                            <CardAvatarAvaible>
                                <CardAvatarPrice>
                                    <Paragraph>{value.avaible == true ? 'Disponivel' : 'Indisponivel'} </Paragraph>
                                </CardAvatarPrice>
                                {!visitor && value.avaible == true && (<>
                                    <IconButton
                                        disabled={value.avaible == false ? true : false}
                                        onClick={() => { handleRemoveCart(value) }}
                                        style={{ width: '20px', height: '5px', borderRadius: 0, background: 'none', }} >
                                        <RemoveIcon style={IconStyle} />
                                    </IconButton>
                                    <Input
                                        onChange={(e) => { handleChange(e, value) }}
                                        value={countItem.find(f => f.codigo == value.codigo) ?
                                            countItem.find(f => f.codigo == value.codigo).quantity : 0
                                        }
                                        style={{ margin: 10, width: 40, fontSize: 11, textAlign: 'center', color: 'black', backgroundColor: '#e2e2e2' }}

                                    />

                                    <IconButton
                                        disabled={value.avaible == false ? true : false}
                                        onClick={() => { handleAddCart(value) }}
                                        style={{ width: '20px', height: '20px', borderRadius: 0, background: 'none' }}
                                    >
                                        <AddIcon style={IconStyle} />
                                    </IconButton>
                                </>)}
                            </CardAvatarAvaible>
                        </CardItem >
                    )
                }
                ) :
                    <CardContainerImage>
                        <div>
                            <img src={loading}></img>
                        </div>
                    </CardContainerImage>
                }
            </CardIContainer >

        </Fragment >
    )

}