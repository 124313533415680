


import React from 'react'
import 'material-icons/iconfont/material-icons.css'
import { Button } from './Button'
import { Tooltip } from '@mui/material'
import { Fragment } from 'react'

export const Icon = ({ name, ...others }) => {
    return (
        <i translate="no" className='material-icons'>{name ? name : ''}</i>

    )
}


const ClearButton = ({ tooltipTitle = '', reactIcons = false, iconName, background, label = 'Limpar Filtros', children, ...others }) => {
    return (
        <Fragment>
            <Tooltip title={tooltipTitle}>
                <Button  {...others} width='120px' height='20px' background='none' color='#A2A2A2'>
                    <Icon name={' ' + iconName} />
                    {label}
                    {children}
                </Button>
            </Tooltip>
        </Fragment>
    )
}

export default ClearButton