import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Products } from "./pages/Products";
import { Orders } from "./pages/Orders";
import { CartSale } from "./pages/CartSale";
import { OrdersBudget } from "./pages/OrdersBudget";
import Login from "./pages/Login";
import Customers from "./pages/Customers";
import { ProtectTalao, ProtectedRoutes } from "./PrivateRoute.jsx";
import Feature from "./pages/Feature";
import TalaoVenda from "./pages/TalaoVenda";
  
const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        {/* Rotas protegidas */}
        <Route element={<ProtectedRoutes />}>
          <Route path='/produtos' element={<Products />} />
          <Route path='/clientes' element={<Customers />} />
          <Route path='/carrinho' element={<CartSale />} />
          <Route path='/orcamento' element={<OrdersBudget />} />
          <Route path='/pedidos' element={<Orders />} />
          <Route path='/feature' element={<Feature />} />
        </Route>


        <Route element={<ProtectTalao />}>
          <Route path='/talao' element={<TalaoVenda />} />
        </Route>

        {/* Rotas publicas */}
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<Login />} />
      </Routes>
    </BrowserRouter >
  );
}

export default App;
