import styled from "styled-components";
import {device} from "../components/device.config.js"


export const FooterContainer = styled.footer`
  display: flex;
  font-size:16px;
  height: 8rem;
  color:black;
  background-color:#ffffff;
  flex-direction:column;
  margin-top: auto;
  overflow:hidden;
  margin-left:250px;
  justify-content: center;
  @media ${device.laptopL} { 
        display:none
	 }

`

const DividerHr = styled.hr`
font-weight:500;
height:3px;
margin-bottom:50px;
margin:0 auto;
background-color:black;
width:1230px;
margin-bottom:20px;
`

export const FooterItem = styled.footer`
  display: flex;
  margin: 0 auto;
  max-width: 1320px;
`

export const Footer = () => {

  return (
    <FooterContainer>
   <DividerHr/>
      <FooterItem>
      11 4652 3200 | vendas@zeene.com.br
      </FooterItem>
      <FooterItem>
      Av. Adília Barbosa Neves, 3636 - Bairro do Portão, Arujá - SP
      </FooterItem>
    </FooterContainer >
  )
}




// import styled from "styled-components";



// export const FooterContainer = styled.footer`
//   display: flex;
//   font-size:16px;
//   height: 8rem;
//   color:black;
//   margin:0 auto;
//   width: 100%;
//   justify-content:center;
//   align-items:flex-end;
//   max-width:1500px;
//   bottom:0;
//   background-color:white;
//   flex-direction:column;
//   margin-bottom:60px;
 

// `

// const DividerHr = styled.hr`
// font-weight:500;
// height:3px;
// margin-bottom:50px;
// align-items:center;
// background-color:black;
// width:1220px;
// `

// export const FooterItem = styled.footer`
//   display: flex;
//   justify-content: flex-end;
// `

// export const Footer = () => {

//   return (
//     <FooterContainer>
//       <DividerHr />
//       <FooterItem>
//         11 4652 3200 | vendas@zeene.com.br
//       </FooterItem>
//       <FooterItem>
//         Av. Adília Barbosa Neves, 3636 - Bairro do Portão, Arujá - SP
//       </FooterItem>
//     </FooterContainer >
//   )
// }



