

import React from 'react'
import styled from 'styled-components';
import IconButton from './IconButton';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: row;
  z-index:1200;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  border-radius:15px;
  z-index:1200;
  display:flex;
  justify-content:center;
  overflow: hidden;
  width:600px;
  height:300px;
  flex-direction:column;
  align-items: center;

`;
const ModalButtonContainer = styled.div`
  background-color: #fff;
  border-radius:15px;
  display:flex;
  justify-content:center;
  gap:15px;
  height:50px;
  flex-direction:row;
  align-items: center;

`;

const ModalItem = styled.div`
  background-color: #fff;
  width:100%; 

`;

const Paragraph = styled.p`
width: 435px;
left: 53px;
font-family: 'Poppins';
margin:0 auto;
font-weight: 400;
font-size: 20px;
line-height: 30px;
display: flex;
justify-content: space-evenly;
flex-direction: row;
color: black;

`

const ModalTransport = ({ isOpen, logo, handleFuncionYes, handleFuncionNot, label, img = { width: 170, height: 50 }, transport }) => {
    return (
        <>
            {isOpen && (
                <ModalOverlay>
                    <ModalContainer>
                        <img style={img} src={logo} />
                        <ModalItem>
                            <Paragraph>{label}</Paragraph>
                        </ModalItem>
                        <ModalItem>
                            <Paragraph>{transport.id}</Paragraph>
                            <Paragraph>{transport.value}</Paragraph>
                        </ModalItem>
                        <ModalButtonContainer>
                            <ModalItem>
                                <IconButton style={{ width: 100 }} onClick={handleFuncionYes} label={'Sim'} />
                            </ModalItem>
                            <ModalItem>
                                <IconButton style={{ width: 100 }} F onClick={handleFuncionNot} label={'Não'} />
                            </ModalItem>
                        </ModalButtonContainer>
                    </ModalContainer>
                </ModalOverlay >
            )}
        </>

    )
}

export default ModalTransport