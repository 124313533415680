import React from 'react'
import { getOrdersInvento } from '../http/invento.api'
import { Input } from '../components/Input'
import { useState } from 'react'
import { Fragment } from 'react'
import IconButton from '../components/IconButton'
import styled from 'styled-components'
import searchImage from '../assets/pesquisa.png'
import { ReportPDFTalao } from '../components/ReportPDFTalao'
import { useSnackbar } from 'notistack'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import Header from '../components/Header'
import { getOrderIdTalaoVenda } from '../http/portalb2b.api'

const CartItemSearch = styled.div`
    width:100%;
`
const ContainerInput = styled.div`
    display:flex;
    flex-wrap:wrap; 
    max-width:1320px;
    justify-content:space-between;
    gap:15px;
    margin:0 auto;
   `
const CartContainer = styled.div`
    flex-wrap:wrap;
    margin:10px;
    margin-bottom:40px;
    display:flex;
    gap:10px;
`
const CartSaleContainer = styled.div`
max-width:1320px;
margin:0 auto;
`
const CartTable = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
`
const TalaoVenda = () => {
    const [order, setOrder] = useState({ searchOrder: '' })
    const [isLoading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const handleChange = (e) => {

        const { name, value } = e.target
        setOrder((current) => {
            return {
                ...current,
                [name]: value ? value.toLocaleUpperCase() : value
            }

        })

    }

    const handleClick = () => {
        setLoading(true)

        if ((order.searchOrder.substring(0, 2).toLocaleUpperCase()) == 'PB') {
            getOrderIdTalaoVenda(order.searchOrder).then(async responsePortal => {
                if (!responsePortal) throw 'Error ao buscar pedido'
                if (responsePortal[0].status != 'send') {
                    await ReportPDFTalao(responsePortal, () => { })
                }
                else {
                    getOrdersInvento(order.searchOrder).then(async (responseInvento) => {
                        if (!responseInvento) throw 'Error ao buscar pedido'

                        const priceSkusPrecoDe = responsePortal.map((item) => {
                            return {
                                sku: item.sku,
                                precoDe: item.precoDe
                            }
                        })

                        const responseInventoWithPriceFrom = responseInvento.map(item => {
                            return {
                                ...item,
                                precoDe: priceSkusPrecoDe.some(price => price.sku == item.sku) ? priceSkusPrecoDe.find(price => price.sku == item.sku).precoDe : 0
                            }
                        })
                        await ReportPDFTalao(responseInventoWithPriceFrom, () => { })
                        setLoading(false)
                    }).catch((error) => {
                        enqueueSnackbar(error, { variant: 'error' })
                        setLoading(false)

                    })
                }

                setLoading(false)
            }
            )
            setLoading(false)
        } else {

            getOrdersInvento(order.searchOrder).then(async (response) => {
                if (!response) throw 'Error ao buscar pedido'
                await ReportPDFTalao(response, () => { })
                setLoading(false)
            }).catch((error) => {
                enqueueSnackbar(error, { variant: 'error' })
                setLoading(false)

            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 ||
            event.keyCode === 18) { // Verifica se a tecla pressionada é "Enter"
            handleClick(); // Chama a função de clique no botão
        }
    };


    return (
        <Fragment>

            <CartSaleContainer>

                <CartContainer>
                    <Header icon={<ReceiptLongOutlinedIcon fontSize="20px" />} name='Talão de vendas' />
                    <CartItemSearch>
                        <Input
                            name='searchOrder'
                            placeholder=' Digite o número do pedido ...'
                            onChange={handleChange}
                            type='search'
                            onKeyUp={handleKeyDown}
                            value={order.searchOrder}
                        />
                    </CartItemSearch>
                    <CartItemSearch>
                        <IconButton
                            disabled={!order.searchOrder || isLoading}
                            onClick={handleClick}
                            label='Pesquisar'
                            onKeyUp={handleKeyDown}
                            isLoadingIcon={isLoading}
                        />
                    </CartItemSearch>

                </CartContainer>
            </CartSaleContainer>
            <CartTable>

                {/* <img src={devImage}></img> */}

                <img width={'320px'} src={searchImage} />

            </CartTable>
        </Fragment>
    )
}

export default TalaoVenda