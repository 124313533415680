export const category = [
    'Iluminação',
    'Mecânica',
    'Acessorios',
    'Componentes',
    'Acabamentos',
    'Grade',
    'Latarias e Ferragens',
    'Parachoque',
    'Volantes e Acessórios',
    'Acessórios Externos',
    'Radiador',
    'Peças Elétricas',
    'Lameiro e Parabarro',
    'Acessórios internos',
    'Grades de Farois de Milha',
    'Paralama',
    'Interior',
    'Componentes do Motor',
    'Moldura',
    'Insumos',
    'farol',
    'Acessórios',
    'Alarmes e Segurança',
    'Amortecedores',
    'Eletrônicos, Áudio e Vídeo',
    'Som E Vídeo',
    'Som Automotivo',
    'Lataria',
    'Alargadores',
    'Carroceria',
    'Parachoque Dianteiro',
    'PARABARRO',
    'Suportes',
    'Motor',
    'Console',
    'Parachoque Dianteiro Liso',
    'Parachoque Dianteiro Primer',
    'Parachoque Traseiro Texturizado',
    'Parachoque Traseiro Primer',
    'Parachoque Traseiro',
    'Paralama Dianteiro',
    'Moldura Parachoque',
]