import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import { getPartner } from '../http/portalb2b.api.js'
import IconButton from '../components/IconButton'
import ModalCustomers from '../components/ModalCustomers'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Header from '../components/Header';
const Customers = () => {

    const [customers, setCustormer] = useState({ currentPage: 1, totalPages: 1, result: [] })
    const [isOpen, setIsOpen] = useState(false)
    const [sendOrder, setOrder] = useState({})
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
    })


    const { visitor } = JSON.parse(localStorage.getItem('authToken'))
    useEffect(() => {

        !visitor && getPartner(50, pagination.currentPage).then((data) => {
            setCustormer(data)

        })
    }, [pagination])


    const handleChange = (e) => {
        e.preventDefault()

    }
    const IconStyle = {
        color: '#07689D'
    }


    const columns = [

        {
            path: 'nome',
            name: 'Nome',
            onChange: handleChange,
            type: 'input',
            style: { width: 380 },
            isActiveFilter: true,
            attribute: (value) => {
                return <p style={{ padding: 10 }}>{value}</p>
            }
        },
        {
            path: 'codigo',
            name: 'Código',
            onChange: handleChange,
            type: 'input',
            style: { width: 320 },
            isActiveFilter: true,
        },

        {
            path: 'documento',
            name: 'Documento',
            onChange: handleChange,
            type: 'input',
            style: { width: 330 },
            isActiveFilter: true
        },
        {
            path: 'UF',
            name: 'UF',
            type: 'input',
        
            isActiveFilter: true
        },
        {
            path: 'status',
            name: 'Status',

            onChange: handleChange,

            isActiveFilter: true,
            attribute: (value) => {
                const replaceName =
                {
                    1: 'Inativo',
                    0: 'Ativo',
                    9: 'Desativado',
                    4: 'Financeiro em aberto'
                }

                return (
                    replaceName[value]
                )
            }
        },
        {
            path: 'UltimaCompra',
            name: 'Ultima Compra',
            attribute: (value) => {
                let date =
                    new Date(value).toLocaleDateString();
                return <p style={{ margin: 10 }}>{date}</p>
            }
        },

        {


            attribute: (isEmpty, value) => {
                return (
                    <div style={{ display: 'flex', gap: '10px', margin: 20 }}>
                        <IconButton tooltipTitle='Consultar detalhe do cliente' onClick={() => { handleIsActiveModalEdit(value) }} background={'transparent'} style={{ width: 50 }} >
                            <VisibilityOutlinedIcon style={IconStyle} />
                        </IconButton>
                    </div>)
            }
        }

    ]

    const options = [
        {
            pagination: true,
            buttonFilter: true,
        }]

    const toggleModal = () => {
        setIsOpen(!isOpen)
    }
    const handleIsActiveModalEdit = (value) => {
        setOrder(value)
        toggleModal()
    }


    return (
        <> <Header icon={<PersonIcon fontSize="20px" />} name='Clientes' />
            <ModalCustomers isOpen={isOpen} value={sendOrder} onClose={toggleModal} />
            <Table
                options={options}
                columns={columns}
                data={!visitor ? customers.result : []}
                currentPage={customers.currentPage}
                totalPages={customers.totalPages}
                setPagination={setPagination}
            />

        </>
    )
}

export default Customers