import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData.jsx";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import imgLogo from "../assets/zeene.png"
import { device } from '../components/device.config.js'


const Nav = styled.div`
  font-size: 2rem;
  top:0;
  z-index:1200;
  position:fixed;
  width:100%;
  height: 55px;
  display: none;
  justify-content: space-between;
  align-items: center;
  background: white linear-gradient(55deg, transparent 65%,   #0771AC 65%);

  @media ${device.laptopL} {
    visibility: visible;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
`;
const NavIcon = styled(Link)`
margin-left: 2rem;
font-size: 2rem;
height: 80px;
display:none;
justify-content: flex-start;
align-items: center;
@media ${device.laptopL} { 
      visibility:visible;
      margin-left: 2rem;
      font-size: 2rem;
      height: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
  }
  `;
const LogoNav = styled.div`


  @media ${device.laptopL} { 
     display:none;

  }
  `
const SidebarNav = styled.nav`
background: #0771AC;
width: 270px;
height: 100vh;
display: flex;
justify-content: center;
position: fixed;
top: 0;
box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
transition: 350ms;
z-index: 1200;

`;

const SidebarWrap = styled.div`
width: 100%;
height:86%;

@media ${device.laptop} { 
    overflow-y:scroll;
}
`;

export const Img = styled.img`
display:none;
width: 100px;
z-index:0;

@media ${device.laptopL} {
  display:flex;
  justify-content:center;
  }

`

const NavImg = styled.img`
cursor:pointer;
margin-left:35px;
margin-top:30px;
margin-bottom:30px;
width:200px;
@media ${device.desktop} { 
  margin-bottom:0px;
  margin-top:5px;
}
@media ${device.laptop} { 
  display:none;
}
`

export const NavBarItemDeviceLogo = styled.div`
display:flex;
justify-content:center;
width:10%;
margin-right:40px;
`

export const NavBarMenuItemDevice = styled.div`
display:flex;
justify-content:flex-end;
padding:15px;
width:33%;
gap:5px;

`

const Sidebar = () => {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const sidebarRef = useRef(null);
    useEffect(() => {
        function verificarTamanhoPagina() {
            const largura = window.innerWidth;

            if (largura >= 1024) {
                setSidebar(true);
            }
        }
        // Chamar a função verificarTamanhoPagina quando o componente for montado
        verificarTamanhoPagina();

        // Chamar a função verificarTamanhoPagina quando a janela for redimensionada
        window.addEventListener('resize', verificarTamanhoPagina);

        // Cleanup: remover o evento de redimensionamento quando o componente for desmontado
        return () => {
            window.removeEventListener('resize', verificarTamanhoPagina);
        };
    }, []);




    useEffect(() => {
        const handleClickOutside = (event) => {
            const largura = window.innerWidth;
            if (largura <= 1360 && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setSidebar(false)
                // Faça aqui a ação que deseja realizar ao clicar fora do sidebar
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <>
            <IconContext.Provider value={{ color: "#fff" }}>
                <Nav>
                    <NavIcon to="#">
                        <FaIcons.FaBars color="#0771AC" onClick={showSidebar} />
                    </NavIcon>
                    <NavBarMenuItemDevice>

                    </NavBarMenuItemDevice>
                    <NavBarItemDeviceLogo>
                        <Img src={imgLogo} />
                    </NavBarItemDeviceLogo>

                </Nav>
                <SidebarNav ref={sidebarRef} sidebar={sidebar}>

                    <SidebarWrap>
                        <NavIcon>
                            <AiIcons.AiOutlineClose onClick={showSidebar} />
                        </NavIcon>
                        <LogoNav>
                            <Link to="/produtos">
                                <NavImg src={imgLogo}></NavImg>
                            </Link>
                        </LogoNav>
                        {SidebarData().map((item, index) => {
                            return <SubMenu item={item} key={index} />;

                        })}
                    </SidebarWrap>
                </SidebarNav>

            </IconContext.Provider>
        </>
    );
};

export default Sidebar;
