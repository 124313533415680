import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { addMonths, isAfter, isBefore, subMonths } from 'date-fns';
import { device } from './device.config';

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');

const DatePickerContainer = styled.div`
  display: flex;
  gap:7px;
  max-width:350px;
  @media ${device.mobileL} { 
    justify-content: center;
    flex-direction:column;
	 }
     @media ${device.mobileM} { 
    justify-content: center;
    flex-direction:column;
	 }
     @media ${device.mobileS} { 
    justify-content: center;
    flex-direction:column;
	 }
`;

const DateItem = styled.div`
  display: flex;
  width:50%;
  gap:7px;`


const CustomDatePickerInput = styled.input`
    height:250px;
    overflow:hidden;
    color: #787575;
    width:100%;
    background-color:#F5F5F5; 
    max-height:50px;
    border:1px solid  #D9D9D9;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    :hover{
        cursor:pointer;
    }
   
`;

const CustomCalendarContainer = styled.div`
  font-size: 14px;
  background-color: #ffffff;
  color: #D9D9D9;
  display:flex;
  flex-direction:column;
  justify-items:center;
  border:1px solid  #e2e2e2;
`;

const CustomCalendarHeader = styled.div`
  padding: 10px;
  background-color:#07689D;
  text-align: center;
  margin-top:-15px;
  font-size:12px;
  color:white;
  font-weight:700;
`;

const CustomCalendarBody = styled.div`
   display:flex;
    font-size:16.5px;
    font-family:'Poppins';
    letter-spacing: -2px;
   justify-content:center;
    width:250px;
`;

const CustomCalendarFooter = styled.div`
  padding: 10px;
  background-color: #e2e2e2;
  color:black;
  text-align: center;
`;


const DateRangePicker = ({ setEndDateTable, setStartDateTable, clear }) => {

    const [startDate, setStartDate] = useState(new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)));
    const [endDate, setEndDate] = useState(new Date(new Date().setHours(23, 59, 59, 0)));
    const maxEndDate = addMonths(startDate, 3);

    useEffect(() => {
        setEndDate(endDate)
        setStartDate(startDate)
    }, [])


    const handleStartDateChange = (date) => {

        if (isBefore(date, endDate) || !endDate) {
            setStartDate(date);
            setStartDateTable(date)
        } else {
            setStartDate(null);
            setEndDate(null);
            setStartDateTable(null)
            setEndDateTable(null)
        }
    };

    const handleEndDateChange = (date) => {
   
        if (isAfter(date, startDate) || !startDate) {
            setEndDate(date);
            setEndDateTable(date)
        } else {
            setStartDate(null);
            setEndDate(null);
            setStartDateTable(null)
            setEndDateTable(null)
        }
    };

    useEffect(() => {
        setStartDate(new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)));
        setEndDate(new Date(new Date()));
        setStartDateTable(new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)));
        setEndDateTable(new Date(new Date()));
    }, [clear])



    const CustomCalendar = ({ children }) => (
        <CustomCalendarContainer>
            <CustomCalendarHeader>
                Calendario
            </CustomCalendarHeader>
            <CustomCalendarBody>
                {children}
            </CustomCalendarBody>
            <CustomCalendarFooter>
                Periodo maximo de 3 meses
            </CustomCalendarFooter>
        </CustomCalendarContainer>
    );

    return (
        <DatePickerContainer>
            <DateItem>
                <DatePicker
                    name='dateStart'
                    selected={startDate}
                    onChange={handleStartDateChange}
                    placeholderText="Data inicial"
                    dateFormat="dd/MM/yyyy"
                    maxDate={endDate}
                    customInput={<CustomDatePickerInput />}
                    calendarContainer={CustomCalendar}
                />
            </DateItem>
            <DateItem>
                <DatePicker
                    name='dateEnd'
                    selected={endDate}
                    onChange={handleEndDateChange}
                    placeholderText="Data final"
                    dateFormat="dd/MM/yyyy"
                    minDate={startDate}
                    customInput={<CustomDatePickerInput />}
                    calendarContainer={CustomCalendar}
                />

            </DateItem>

        </DatePickerContainer>
    );
};

export default DateRangePicker;
