import React from 'react'
import styled from 'styled-components'


const ContainerHeader = styled.div`
margin-top:50px;
text-align:center;
margin-bottom:30px;

`
const ParagraphyHeader = styled.p`
font-weight:700;
text-align:start;
margin-left:170px;
margin:0 auto;
font-size:30px;
`

const Header = ({ name, icon }) => {
    return (
        <ContainerHeader>
            <ParagraphyHeader>{icon} {name}</ParagraphyHeader>
        </ContainerHeader>
    )
}

export default Header