import styled from "styled-components";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { useNavigate } from "react-router-dom";
import logo from "../assets/zeene-blue.png"
import { useSnackbar } from 'notistack'
import authApi from "../http/auth.api.js";
import { useEffect, useState } from "react";
import { GoogleAuthenticatorQRCode } from "../components/2FA";
import IconButton from '../components/IconButton'
import jwtDecode from "jwt-decode";
import gpsImg from "../assets/GPS.gif"
import passwordImg from "../assets/password.gif"
import { device } from '../components/device.config.js';
import React, { useRef } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { useAtom } from "jotai";
import { authToken } from "../context/user";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LinkInput = styled.input`
  flex: 1;
  border: none;
  background-color: #f5f5f5;
  padding: 5px;
  margin-right: 5px;
`;

const CopyButton = styled.button`
  border: none;
  background-color: #f5f5f5;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CopyIcon = styled(MdContentCopy)`
  margin-right: 5px;
`;

const Paragraph = styled.p`
font-family: 'Poppins';
font-size: 25px;
width:350px;
display: flex;
color: black;
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
  align-items: center;

`;

const ModalContainer = styled.div`
  background-color: #fff;
  border-radius:15px;
  padding:10px;
  display:flex;
  gap:20px;
  flex-direction:column;
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 400px;
  max-height: 700px;

  @media ${device.laptopL} { 
	background-color: white;
	border-radius:15px;
	display:flex;
	gap:20px;
	flex-direction:column;
	box-shadow: 0 1px 35px rgb(0 0 0 / 0.2);
	justify-content: center;
	align-items: center;
	min-height: 400px;
    max-height: 700px;
  }
 
`;

export const LoginContainer = styled.div`
width: 100vw;
height: 100vh;
background:white;
gap:15px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`


export const LoginContainerButton = styled.div`
display:flex;
gap:10px;
 ;
`
export const LoginItem = styled.div`
  max-width: 1320px;
  text-align: center;
 
`
export const Img = styled.img`
width: 370px;
height: 90px;
 @media ${device.laptopL} { 
        display:none
	 }


`
export const LoginContainerUser = styled.div`
display:flex;
align-items:center;

 @media ${device.laptopL} { 

	 }

`
export const Hidden = styled.div`
 @media ${device.laptopL} { 
display:none;
}
`
export const LoginItemUser = styled.div`
width:450px;
`
export const Label = styled.p`
width:450px;
text-align:start;
margin-left:100px;
font-weight:600;
`
export const LabelGPS = styled.p`
width:350px;
text-align:center;
font-weight:600;
`
const Login = () => {

	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [value, setValue] = useState({})
	const [isLogin, setIsLogin] = useState(false)
	const [decode, setDecode] = useState({ isActive: false });
	const [isGPSEnabled, setIsGPSEnabled] = useState(false);
	const [_, setAuthToken] = useAtom(authToken);
	
	const inputRef = useRef(null);

	const copyToClipboard = () => {
		inputRef.current.select();
		document.execCommand('copy');
	};


	useEffect(() => {
		if ('geolocation' in navigator) {

			const json = navigator
			navigator.geolocation.getCurrentPosition(
				position => {
					setIsGPSEnabled(true);
					setValue((currentValue) => {
						return {
							...currentValue,
							geolocation: {
								latitude: position.coords.latitude,
								longitude: position.coords.longitude,
								timestamp: position.timestamp
							}

						}
					})
				},
				error => {
					setIsGPSEnabled(false);
				}
			);
		} else {
			// O navegador não suporta a API Geolocation
			setIsGPSEnabled(false);
		}
	}, []);

	const handleChange = ({ target }) => {

		const { name, value } = target
		setValue((currentValue) => {
			return {
				...currentValue,
				[name]: value

			}
		})
	}

	const handleDisabled = () => {
		return value.email != "" && value.password != "" ? false : true
	}

	const handleSubmit = async () => {
		try {
			const sucesso = await authApi.authLogin(value.email, value.password, value.geolocation)
			if (sucesso) {
				const decodedToken = jwtDecode(sucesso.authToken);
				setDecode(decodedToken)
				if (!decodedToken.visitor) {
					localStorage.removeItem('authToken')
					const payload = sucesso
					setValue((currentValue) => {
						return {
							...currentValue,
							payload
						}
					})
					setIsLogin(true)
				} else if (decodedToken.visitor) {
					setAuthToken({ ...decodedToken, ...sucesso })
					localStorage.setItem('authToken', JSON.stringify({ ...decodedToken, ...sucesso }))
					navigate('/produtos')
					enqueueSnackbar('Autorizado', { variant: 'success' })

				}
			}

		} catch (response) {
			enqueueSnackbar(response.data.message, { variant: 'error' })
		}
	};

	const handleSubmitOTP = async () => {
		try {
			const sucesso = await authApi.authLoginOTP(value.email, value.password, value.code.substring(0, 6), value.payload.authToken)
			if (sucesso) {
				if (sucesso.codeVerify && !sucesso.isActive) {
					localStorage.setItem('authToken', JSON.stringify(value.payload))
					navigate('/produtos')
					enqueueSnackbar('Código Autenticado, Faça o login novamente!', { variant: 'success' })
				} else {
					localStorage.setItem('authToken', JSON.stringify({ ...value.payload, ...sucesso }))
					navigate('/produtos')
					setAuthToken({ ...value.payload, ...sucesso })
					enqueueSnackbar('Autorizado!', { variant: 'success' })
				}
			}

		} catch (response) {
			enqueueSnackbar(response.data.message, { variant: 'error' })
		}
	};
	const handleKeyDown = (event) => {
		if (isLogin && event.keyCode === 13 ||
			isLogin && event.keyCode === 18) { // Verifica se a tecla pressionada é "Enter"
			handleSubmitOTP(); // Chama a função de clique no botão
		}
	};


	return (
		<LoginContainer>
			{!isGPSEnabled && (
				<ModalOverlay>
					<ModalContainer>
						<LoginItem>
							<img style={{ width: '250px' }} src={gpsImg} />
							<LabelGPS>
								Atenção!
							</LabelGPS>
							<LabelGPS>
								é necessário ativar sua localização no seu Navegador Após ativação do gps clique em atualizar.
							</LabelGPS>
						</LoginItem>
						<IconButton iconName={'refresh'} onClick={() => { window.location.reload() }} label={'Atualizar'} />
					</ModalContainer>
				</ModalOverlay>
			)
			}
			{
				isLogin && (
					<>
						<ModalOverlay>
							<ModalContainer>
								<LoginItem>
									{isLogin && decode.isActive && (<img style={{ width: '250px' }} src={passwordImg} />)}
								</LoginItem>
								<LoginItem>
									<Paragraph>Verificação em duas etapas</Paragraph>
									{isLogin && !decode.isActive && (
										<>
											<GoogleAuthenticatorQRCode secret={decode.secret} accountName={value.email} />
											<Container>
												<LinkInput ref={inputRef} value={decode.secret} readOnly />
												<CopyButton onClick={copyToClipboard}>
													<CopyIcon />
													Copy
												</CopyButton>
											</Container>
										</>)}
								</LoginItem>
								<LoginItem>
									<Input
										autoFocus
										type="number"
										onKeyUp={handleKeyDown}
										name="code"
										onChange={handleChange}
										value={value.code ? value.code.substring(0, 6) : ''}
										required
										placeholder="Código validação"
									/>
								</LoginItem>
								<LoginContainerButton>
									<LoginItem>
										<IconButton
											style={{ width: 150 }}
											disabled={value.code && value.code.length > 3 ? false : true}
											onClick={handleSubmitOTP}
											iconName={'done'}
											label={'Validar'} />
									</LoginItem>
									<LoginItem>
										<IconButton style={{ width: 150 }} onClick={() => { setIsLogin(false) }} iconName={'closed'} label={'Fechar'} />
									</LoginItem>
								</LoginContainerButton>
							</ModalContainer>
						</ModalOverlay>
					</>
				)
			}
			{
				!isLogin && (<>
					<LoginContainerUser >
						<Hidden>
							<LoginItemUser>
								<Img src={logo} />
							</LoginItemUser>
						</Hidden>
						<LoginItemUser>
							<ModalContainer >
								<LoginItem>
									<Paragraph>Acesso exclusivo</Paragraph>
									<Paragraph>para colaborador</Paragraph>
								</LoginItem>
								<LoginItem>
									<Label>Usuário:</Label>
									<Input
										style={{ width: 350, background: '#D9D9D9' }}
										name="email"
										onChange={handleChange}
										value={value.email}
										required

									/>
								</LoginItem>
								<LoginItem>
									<Label>Senha:</Label>
									<Input
										style={{ width: 340, background: '#D9D9D9' }}
										name="password"
										onChange={handleChange}
										value={value.password}
										required
										type="password"

									/>
								</LoginItem>
								<LoginItem>
									<Button style={{ width: 150 }} background="#0771AC" disabled={handleDisabled()} onClick={handleSubmit}>Entrar </Button>
								</LoginItem>

							</ModalContainer>
						</LoginItemUser>
					</LoginContainerUser>
				</>)
			}

		</LoginContainer >
	)

}


export default Login;