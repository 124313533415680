import React, { useEffect } from 'react';
import styled from 'styled-components';
import IconButton from './IconButton';
import ImageRender from './ImageMagnifier';
import { device } from './device.config';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:1200;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  border-radius:15px;
  overflow: hidden;
  max-width: 800px;
  min-width: 800px;
  min-height: 500px;
  max-height: 800px;
  @media ${device.laptopL} {
    height:400px;
}
`;

const ModalButton = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  display:flex;
  margin: 20px;
  margin-top:50px;
  align-items: center;
  `;
const ModalItem = styled.p`
  text-align:start;
  width:50%;
  overflow:hidden;

`;


const ModalItemButtom = styled.p`
  text-align:end;

`;
const ModalContainerAvatar = styled.div`
  background-color: #fff;
  display:flex;
  padding:10px;
  flex-direction: row-reverse;
  max-width: 800px;
  min-width: 800px;
  min-height: 500px;
  max-height: 600px;

`;

const ModalContainerText = styled.div`
  display:flex;
width: 50%;
  flex-direction: column;
  margin:20px;
  @media ${device.laptopL} {
    height:600px;
}

`;

const ModalPrice = styled.div`
display:flex;
flex-direction:column;
@media ${device.laptopL} {
  border: none;
    margin-top:0px;
}
`


const ModalProduct = ({ isOpen, onClose, value }) => {

  if (!isOpen) {
    return null;
  }


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 27) {
        onClose()
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleClick = () => {
    onClose()
  };

  const { visitor } = JSON.parse(localStorage.getItem('authToken'))

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalContainerAvatar>
          <ModalContainerText>
            <ModalItem>
              <b>Descrição</b> <p>{value.descricao}</p>
            </ModalItem>
            <ModalItem>
              <b>Categoria:</b> <p>{value.categoria}</p>
            </ModalItem>
            <ModalItem>
              <b>Status:</b> <p>{value.avaible === true ? 'Disponivel' : 'Indisponivel'}</p>
            </ModalItem>
            <ModalItem>
              <b>Marca:</b><p> {value.marca}</p>
            </ModalItem>
            <ModalItem>
              <b>SKU:</b> <p>{value.codigo}</p>
            </ModalItem>
            <ModalPrice>
              <ModalItem>
                {!visitor && value.fromPrice > value.price && (<> <b>Preço De:</b> <p style={{ textDecoration: 'line-through', color: 'red' }}>R$ {value.fromPrice}</p></>)}
              </ModalItem>
              <ModalItem>
                {!visitor && (<>  <b>Preço Por:</b><h1 style={{ color: 'green' }}>R$ {value.price}</h1></>)}
              </ModalItem>
              <ModalItemButtom>
                <ModalButton>
                  <IconButton
                    onClick={handleClick}
                    style={{ width: '150px' }}
                    iconName={'closed'}
                    label={'Fechar (ESC)'} />
                </ModalButton>
              </ModalItemButtom>
            </ModalPrice>

          </ModalContainerText>
          <ModalItem>
            <ImageRender src={value.imagem} />
          </ModalItem>

        </ModalContainerAvatar>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ModalProduct;
