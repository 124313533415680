export const transport = [
    {
        "id": "13270375000113",
        "value": "ZURICK LOG"
    },
    {
        "id": "47579923000103",
        "value": "ZUQUIM"
    },
    {
        "id": "13148262000140",
        "value": "ZN LOG LOGISTICA E TRANSPORTES LTDA ME"
    },
    {
        "id": "08262037000108",
        "value": "ZIYAD TRANSPORTES LTDA EPP"
    },
    {
        "id": "05294609000215",
        "value": "ZIRANLOG TRANSPORTES LTDA"
    },
    {
        "id": "03088450000176",
        "value": "ZERO HORA TRANSPORTES E ENCOMENDAS LTDA"
    },
    {
        "id": "04012744000349",
        "value": "ZERO GRAU LOGISTICA LTDA"
    },
    {
        "id": "04263384000439",
        "value": "ZEAGOSTINHO LOGISTICA, TRANSPORTES E DISTRIBUICAO LTDA"
    },
    {
        "id": "15597216000234",
        "value": "ZAZ LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "54635420000173",
        "value": "ZARGO TRANSPORTES LTDA"
    },
    {
        "id": "17749746000103",
        "value": "ZANOTELLI TRANSPORTES E LOGISTICA EIRELI"
    },
    {
        "id": "03671418000119",
        "value": "YOSSEF AKY TRANSPORTES M.E"
    },
    {
        "id": "02732881000241",
        "value": "YANO TRANSPORTES RODOVIARIOS LTDA EPP"
    },
    {
        "id": "14834939000166",
        "value": "WRJ TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "16585219000300",
        "value": "WINLOGIS GESTAO LOGISTICA EIRELI"
    },
    {
        "id": "08402055000300",
        "value": "WINGS TRANSPORTADORA EIRELI"
    },
    {
        "id": "60792405000808",
        "value": "WIND EXPRESS TRANSPORTES URGENTES LTDA"
    },
    {
        "id": "03496827000126",
        "value": "WILSON LUIS TRANSP E SERVICOS LTDA (EXPRESSO PETROLINA)"
    },
    {
        "id": "03242692000172",
        "value": "WEM TRANSPORTES LTDA"
    },
    {
        "id": "11412476000156",
        "value": "WEB MONTREAL CARGAS LOGISTICA DE TRANSPORTES EIRELI ME"
    },
    {
        "id": "12861518000360",
        "value": "WATEX TRANSPORTES E LOGISTICA LTDA ME"
    },
    {
        "id": "09307383000110",
        "value": "WAGNER TARCISIO MOREIRA IZZO ME"
    },
    {
        "id": "57546265096",
        "value": "Wagner"
    },
    {
        "id": "01442657000252",
        "value": "WA TRANSPORTES"
    },
    {
        "id": "05073233000219",
        "value": "W. G. FOLKAS LOGISTICA"
    },
    {
        "id": "19490780000123",
        "value": "W M F TRANSPORTES E LOGISTICAS LTDA"
    },
    {
        "id": "00915514000177",
        "value": "W A S TRANSPORTES LTDA ME"
    },
    {
        "id": "02297092000220",
        "value": "VS TRANSPORTES E SERVIÇOS LTDA"
    },
    {
        "id": "07575651001554",
        "value": "VRG LINHAS AEREAS S/A"
    },
    {
        "id": "05897963000234",
        "value": "VOO TRANSPORTES"
    },
    {
        "id": "02486147000169",
        "value": "VISAR TRANSPORTES LTDA"
    },
    {
        "id": "10783704000212",
        "value": "VISAO TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "04188086000260",
        "value": "VIPEX TRANSPORTES LTDA"
    },
    {
        "id": "15103383000796",
        "value": "VIOPEX TRANSPORTES LTDA."
    },
    {
        "id": "35672782000270",
        "value": "VINI LOG TRANSPORTES"
    },
    {
        "id": "14187611000103",
        "value": "VIKTORIA CARGAS E DESPACHOS LTDA"
    },
    {
        "id": "22348201000206",
        "value": "VIEIRA & PAULA TRANSPORTES LTDA ME"
    },
    {
        "id": "18131868000196",
        "value": "VICAR TRANSPORTE DE CARGA LTDA"
    },
    {
        "id": "08375813000178",
        "value": "VIARAPIDA TRANSPORTES"
    },
    {
        "id": "53185633000188",
        "value": "VIAEXPRESS LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "20146015000331",
        "value": "VIACAO SAO CRISTOVAO LTDA"
    },
    {
        "id": "52771516013898",
        "value": "VIACAO SANTA CRUZ LTDA"
    },
    {
        "id": "60829264000184",
        "value": "VIACAO NOVO HORIZONTE LTDA"
    },
    {
        "id": "55340921000195",
        "value": "VIACAO MOTTA LTDA"
    },
    {
        "id": "78055084000905",
        "value": "VIACAO JOIA LTDA"
    },
    {
        "id": "78586674008515",
        "value": "VIACAO GARCIA LTDA"
    },
    {
        "id": "03232675002521",
        "value": "VIACAO CRUZEIRO DO SUL LTDA"
    },
    {
        "id": "61084018000103",
        "value": "VIACAO COMETA S/A"
    },
    {
        "id": "45998028000106",
        "value": "VIACAO CAPRIOLI LTDA"
    },
    {
        "id": "19125863000202",
        "value": "VIACAO CAMPO BELO LTDA"
    },
    {
        "id": "19339415000465",
        "value": "VIACAO CAMBUI TURISMO"
    },
    {
        "id": "14457031000180",
        "value": "VIA VERDE"
    },
    {
        "id": "11779836000152",
        "value": "VIA MASTER LOGISTICA E TURISMO LTDA ME"
    },
    {
        "id": "05347219000266",
        "value": "VIA MANAUS LTDA ME"
    },
    {
        "id": "24189426000101",
        "value": "VIA BARCELONA"
    },
    {
        "id": "27323399000106",
        "value": "VERDE VALLE SAO FERNANDO TRANSPORTES EIRELI"
    },
    {
        "id": "09416634000102",
        "value": "VENOS TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "57894016000102",
        "value": "VENETO TRANSPORTES LTDA"
    },
    {
        "id": "41924408000218",
        "value": "VENCEDOR TRANSPORTES LTDA"
    },
    {
        "id": "32767123000149",
        "value": "VELOCARGAS BRASIL TRANSPORTE RODOVIARIO E LOGISTICA LTDA"
    },
    {
        "id": "38493863000200",
        "value": "VCB CARGAS LTDA"
    },
    {
        "id": "02486990000307",
        "value": "VC CARGAS LTDA"
    },
    {
        "id": "04502970000146",
        "value": "VANSUL TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "04502970000308",
        "value": "VANSUL TRANSPORTES LTDA"
    },
    {
        "id": "00967324000101",
        "value": "VANAMA TRANSPORTES EIRELI ME"
    },
    {
        "id": "09341782000288",
        "value": "VALENTINUS TRANSPORTES LTDA"
    },
    {
        "id": "17467330000275",
        "value": "VALE TRANSPORTES E LOGISTICA LTDA EPP"
    },
    {
        "id": "14422729000241",
        "value": "VALCIR PECCINI EPP (TRANSCHICO)"
    },
    {
        "id": "10248305000170",
        "value": "V&R LOG TRANSPORTES DE CARGAS LTDA ME"
    },
    {
        "id": "00972696000118",
        "value": "V M RAMOS E CIA LTDA (GENEROSO)"
    },
    {
        "id": "10348701000170",
        "value": "UTI LOGISTICA"
    },
    {
        "id": "10637812000104",
        "value": "UP TRANSPORTES LTDA"
    },
    {
        "id": "16776798000289",
        "value": "UNIVERSUS M&A TRANSPORTE DE CARGAS LTDA ME"
    },
    {
        "id": "01878096000239",
        "value": "UNIVERSAL TRANSPORTES LTDA ME"
    },
    {
        "id": "13190756000273",
        "value": "UNITRANS TRANSPORTES COM SERV LTDA"
    },
    {
        "id": "83346932001432",
        "value": "UNIRIOS RODOFLUVIAL E LOGISTICA LTDA"
    },
    {
        "id": "08360758000223",
        "value": "UNIEXPRESSES"
    },
    {
        "id": "09433853000272",
        "value": "UNIDOS TRANSPORTES ALAGOANOS LTDA"
    },
    {
        "id": "06897194000156",
        "value": "UNIAO TRANSPORTE DE ENCOMENDAS E COMERCIO DE VEICULOS LTDA."
    },
    {
        "id": "34668129000356",
        "value": "UNIAO TRANSPORTE"
    },
    {
        "id": "08323344000305",
        "value": "UNI RAPIDO TRANSPORTES DE CARGAS LTDA ME"
    },
    {
        "id": "02612250000370",
        "value": "ULTRAPRESS"
    },
    {
        "id": "05287790000313",
        "value": "ULTRA SETE TRANSPORTES LTDA"
    },
    {
        "id": "02985440000251",
        "value": "ULTRA RAPIDO BEATRIZ LTDA"
    },
    {
        "id": "08733805000237",
        "value": "UDLOG TRANSPORTE RODOVIARIO LTDA"
    },
    {
        "id": "08831345000360",
        "value": "UBERLOG LOGISTICA"
    },
    {
        "id": "09272560000250",
        "value": "TWR TRANSPORTADORA LTDA"
    },
    {
        "id": "89317697003743",
        "value": "TW TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "40001948000186",
        "value": "TVT TRANSPORTES LTDA"
    },
    {
        "id": "05121433000208",
        "value": "TVM TRANSPORTES LTDA (TRANSMEARIM)"
    },
    {
        "id": "03915923001133",
        "value": "TUT TRANSPORTES LTDA"
    },
    {
        "id": "02488357000358",
        "value": "TURBO TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "02311001000263",
        "value": "TTR TRANSPORTE RODOVIARIO TTR LTDA"
    },
    {
        "id": "00634453000501",
        "value": "TSV TRANSPORTES RAPIDOS LTDA"
    },
    {
        "id": "29264107000471",
        "value": "TSL TRANSPORTES"
    },
    {
        "id": "08388118000574",
        "value": "TSF TRANSPORTADORA SANTA FE LTDA"
    },
    {
        "id": "17590760000107",
        "value": "TSB LOGISTICA E TRANSPORTE DE CARGAS EIRELI - EPP"
    },
    {
        "id": "13067478000180",
        "value": "TS LOG TRANSPORTES"
    },
    {
        "id": "29379007000148",
        "value": "TS - CURSINO TRANSPORTE E LOGISTICA EIRELI"
    },
    {
        "id": "50773191000364",
        "value": "TS"
    },
    {
        "id": "13483986000220",
        "value": "TRON - EXPRESS TRANSPORTE E LOGISTICA"
    },
    {
        "id": "00193687000200",
        "value": "TROCA TRANSPORTES LTDA"
    },
    {
        "id": "10655525000119",
        "value": "TRL TRANSPORTE RIO LESTE LTDA ME"
    },
    {
        "id": "11372320000270",
        "value": "TRIPOLI LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "07241838000205",
        "value": "TRES ESTRELAS TRANSPORTES"
    },
    {
        "id": "05215364000455",
        "value": "TRD TRANSPORTES LOGISTICA LTDA"
    },
    {
        "id": "24112730000223",
        "value": "TRB TRANSPORTES"
    },
    {
        "id": "04585940000221",
        "value": "TRASMAR"
    },
    {
        "id": "50587963000101",
        "value": "TRANSZECAO TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "52105491000139",
        "value": "TRANSWINTER TRANSPORTE E LOGISTICA LTDA"
    },
    {
        "id": "36476245000137",
        "value": "TRANSWILMA EXPRESS"
    },
    {
        "id": "94697372000162",
        "value": "TRANSWILLIAN TRANSPORTES LTDA"
    },
    {
        "id": "60157377000180",
        "value": "TRANSWELS EXPRESSO RODOVIARIO LTDA"
    },
    {
        "id": "57695405000290",
        "value": "TRANS-WAR-TRANSPORTES LTDA"
    },
    {
        "id": "55948020000180",
        "value": "TRANSWAP AIR CARGO LTDA"
    },
    {
        "id": "55188619000244",
        "value": "TRANSWAGO TRANSPORTE RODOVIARIO DE CARGAS LTDA"
    },
    {
        "id": "82604042000287",
        "value": "TRANSVILLE TRANSPORTES E ENCOMENDAS LTDA"
    },
    {
        "id": "02187754000128",
        "value": "TRANSVIAGEM ENCOMENDAS E CARGAS LTDA (PAPALEGUAS)"
    },
    {
        "id": "77039006000440",
        "value": "TRANSVALTER LIMITADA"
    },
    {
        "id": "11832441000257",
        "value": "TRANSUNIVERSO TRANSPORTES RODOVIARIO DE CARGA LTDA"
    },
    {
        "id": "09012502000107",
        "value": "TRANSUNI - ENCOMENDAS E CARGAS LTDA"
    },
    {
        "id": "62286935000133",
        "value": "TRANSTONE TRANSPORTE LTDA"
    },
    {
        "id": "05187769000265",
        "value": "TRANSTITANIO TRANSPORTES RODOVIARIOS EIRELI"
    },
    {
        "id": "11807525000230",
        "value": "TRANSTILOG TRANSPORTES LTDA"
    },
    {
        "id": "03980435000138",
        "value": "TRANSTAVARES ENCOMENDAS E LOGISTICA LTDA"
    },
    {
        "id": "11341235000406",
        "value": "TRANSSUL"
    },
    {
        "id": "85057412000544",
        "value": "TRANSSIMAO TRANSPORTES LTDA"
    },
    {
        "id": "12991594000129",
        "value": "TRANSRUFINO TRANSPORTE E LOGISTICA LTDA"
    },
    {
        "id": "07203405000257",
        "value": "TRANSRUAS TRANSPORTES LTDA EPP"
    },
    {
        "id": "10713646000170",
        "value": "TRANSROSSI LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "10199219000115",
        "value": "TRANSREVENDA TRANSPORTES E LOGISTICA LTDA ME"
    },
    {
        "id": "22980155000336",
        "value": "TRANSPRESS TRANSPORTE EXPRESSO LTDA"
    },
    {
        "id": "84828425000264",
        "value": "TRANSPOTADORA ALKIMAAR LTDA"
    },
    {
        "id": "07291272000218",
        "value": "TRANSPOSUL TRANSPORTES LTDA"
    },
    {
        "id": "01784706000235",
        "value": "TRANSPORTES WARTHA LTDA"
    },
    {
        "id": "41758160000343",
        "value": "TRANSPORTES VT LTDA"
    },
    {
        "id": "44215713000300",
        "value": "TRANSPORTES VIA SATELITE LTDA"
    },
    {
        "id": "50638410000211",
        "value": "TRANSPORTES VALE DO RIBEIRA LTDA"
    },
    {
        "id": "89823918000225",
        "value": "TRANSPORTES TRANSLOVATO LTDA"
    },
    {
        "id": "05724952000171",
        "value": "TRANSPORTES SERRANA MULLER LTDA"
    },
    {
        "id": "11389565000200",
        "value": "TRANSPORTES SERRANA"
    },
    {
        "id": "11393773000100",
        "value": "TRANSPORTES SERRANA"
    },
    {
        "id": "83029140000543",
        "value": "TRANSPORTES SATELITE LTDA"
    },
    {
        "id": "90863325000352",
        "value": "TRANSPORTES RODOVIA SUL LTDA"
    },
    {
        "id": "09097839000245",
        "value": "TRANSPORTES RODOFUZIL LTDA EPP"
    },
    {
        "id": "10628161000269",
        "value": "TRANSPORTES RAPIDO VENTO SUL LTDA"
    },
    {
        "id": "03566842000446",
        "value": "TRANSPORTES RAPIDO OURO SUL LTDA"
    },
    {
        "id": "19300257000278",
        "value": "TRANSPORTES RAPIDO ITAQUA FORTALEZA EIRELI"
    },
    {
        "id": "95795043000460",
        "value": "TRANSPORTES RABAIOLLI LTDA"
    },
    {
        "id": "90300583000835",
        "value": "TRANSPORTES PELLENZ LTDA"
    },
    {
        "id": "43025774000260",
        "value": "TRANSPORTES PANEX"
    },
    {
        "id": "04195643000601",
        "value": "TRANSPORTES OURO NEGRO LTDA"
    },
    {
        "id": "07777323000216",
        "value": "TRANSPORTES OLIVEIRA"
    },
    {
        "id": "08025389000212",
        "value": "TRANSPORTES NOVO NORDESTE LTDA"
    },
    {
        "id": "05014472000117",
        "value": "TRANSPORTES MAGAZONIA LTDA"
    },
    {
        "id": "02146823000155",
        "value": "TRANSPORTES MAGALHAES LTDA"
    },
    {
        "id": "11388193000205",
        "value": "TRANSPORTES MACAPAZINHO LOGISTICA"
    },
    {
        "id": "87051363000304",
        "value": "TRANSPORTES LHD SA"
    },
    {
        "id": "82666983000326",
        "value": "TRANSPORTES KELLER LTDA"
    },
    {
        "id": "02913478000219",
        "value": "TRANSPORTES JEQUITIBA LTDA"
    },
    {
        "id": "09653410000106",
        "value": "TRANSPORTES J E CAMPO GRANDE LTDA"
    },
    {
        "id": "57634677000107",
        "value": "TRANSPORTES ITAPIRENSE BERTINI LTDA"
    },
    {
        "id": "19485408000200",
        "value": "TRANSPORTES ITANHANDU"
    },
    {
        "id": "01241212000204",
        "value": "TRANSPORTES IAZZETTI E LOGISTICA LTDA"
    },
    {
        "id": "11754722000239",
        "value": "TRANSPORTES GUARAMIRIM LTDA"
    },
    {
        "id": "21308332000108",
        "value": "TRANSPORTES FLAVINHO LTDA"
    },
    {
        "id": "04861279000159",
        "value": "TRANSPORTES FERRARI & MARTONI LTDA ME"
    },
    {
        "id": "13689876000139",
        "value": "TRANSPORTES E LOGISTICA GRANDE MAIA LTDA"
    },
    {
        "id": "65599060000118",
        "value": "TRANSPORTES E LOGISTICA D A SOUSA LTDA EPP"
    },
    {
        "id": "20177671001101",
        "value": "TRANSPORTES E CONSTRUCOES LTDA (MINAX)"
    },
    {
        "id": "90735549003647",
        "value": "TRANSPORTES COLETIVOS TURIJUI LTDA"
    },
    {
        "id": "25335282000370",
        "value": "TRANSPORTES CAMILO DO SANTOS LTDA"
    },
    {
        "id": "79818746000248",
        "value": "TRANSPORTES BRUSVILLE LTDA"
    },
    {
        "id": "85487379000240",
        "value": "TRANSPORTES ANDRADE LTDA"
    },
    {
        "id": "03160431000103",
        "value": "TRANSPORTES ALTERNATIVOS EIRELI ME"
    },
    {
        "id": "06018935000262",
        "value": "TRANSPORTE TEMPO REAL"
    },
    {
        "id": "03610402000104",
        "value": "TRANSPORTE RAPIDO ITAQUA EIRELI"
    },
    {
        "id": "20175042000251",
        "value": "TRANSPORTE OLIVEIRA E MELLO EIRELI"
    },
    {
        "id": "78663788000294",
        "value": "TRANSPORTE MANN LTDA"
    },
    {
        "id": "07410513000209",
        "value": "TRANSPORTE LOGISTICA REAL LTDA"
    },
    {
        "id": "57634677000280",
        "value": "TRANSPORTE ITAPIRENSE BERTINI LTDA"
    },
    {
        "id": "12119411000180",
        "value": "TRANSPORTE EXCELLENT SP LTDA"
    },
    {
        "id": "79607685000270",
        "value": "TRANSPORTE ET LTDA"
    },
    {
        "id": "17250689000458",
        "value": "TRANSPORTE EMERICK LTDA"
    },
    {
        "id": "14061099000227",
        "value": "TRANSPORTE CAMPOS DE ARARAQUARA LTDA"
    },
    {
        "id": "04503660001460",
        "value": "TRANSPORTE BERTOLINI LTDA"
    },
    {
        "id": "08853605000218",
        "value": "TRANSPORTE ALAGOANO LTDA ME"
    },
    {
        "id": "49548969000271",
        "value": "TRANSPORTARA ITAPETININGA LTDA"
    },
    {
        "id": "46874079000261",
        "value": "TRANSPORTADORA ZIGUEZIGUE SALTENSE LTDA"
    },
    {
        "id": "63848550000266",
        "value": "TRANSPORTADORA VISAO LTDA"
    },
    {
        "id": "45362970000337",
        "value": "TRANSPORTADORA VALENCIA  "
    },
    {
        "id": "05357114000298",
        "value": "TRANSPORTADORA TRANSVITALINO LTDA"
    },
    {
        "id": "10538648000250",
        "value": "TRANSPORTADORA TRANSLITORAL LTDA."
    },
    {
        "id": "33842451000206",
        "value": "TRANSPORTADORA TRANSLITORAL LTDA"
    },
    {
        "id": "57703571000100",
        "value": "TRANSPORTADORA TRANSGRECO LTDA"
    },
    {
        "id": "31491590000207",
        "value": "TRANSPORTADORA TRANSFINAL LTDA"
    },
    {
        "id": "61060794000254",
        "value": "TRANSPORTADORA TRANSCARGA DE SAO CARLOS LTDA"
    },
    {
        "id": "59365502000240",
        "value": "TRANSPORTADORA TRANS REAL RIO PRETO LTDA"
    },
    {
        "id": "59753475000282",
        "value": "TRANSPORTADORA TONIZZA LTDA"
    },
    {
        "id": "13330404000195",
        "value": "TRANSPORTADORA TIME EXPRESS TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "01167334000206",
        "value": "TRANSPORTADORA TESPAL PR LTDA"
    },
    {
        "id": "87550281007902",
        "value": "TRANSPORTADORA TEGON VALENTI S/A"
    },
    {
        "id": "45503836000228",
        "value": "TRANSPORTADORA TATUI LTDA"
    },
    {
        "id": "78747136000238",
        "value": "TRANSPORTADORA TAMOYO"
    },
    {
        "id": "10636133000293",
        "value": "TRANSPORTADORA SUL MINEIRA"
    },
    {
        "id": "50067271000302",
        "value": "TRANSPORTADORA SCARPATO LTDA EPP"
    },
    {
        "id": "04165849000257",
        "value": "TRANSPORTADORA SAO JOSE"
    },
    {
        "id": "46921086000358",
        "value": "TRANSPORTADORA SAO JOAO LTDA"
    },
    {
        "id": "72354004000260",
        "value": "TRANSPORTADORA SANTANENSE LTDA"
    },
    {
        "id": "59512962000172",
        "value": "TRANSPORTADORA SABIA DE MARILIA LTDA"
    },
    {
        "id": "09181518000305",
        "value": "TRANSPORTADORA RODRIGUES MIRANDA DE JANAUBA LTDA"
    },
    {
        "id": "53542957000126",
        "value": "TRANSPORTADORA RODOZE LTDA"
    },
    {
        "id": "08770427000180",
        "value": "TRANSPORTADORA RODOVARIS LTDA EPP"
    },
    {
        "id": "58970377000153",
        "value": "TRANSPORTADORA RODOMALIA LTDA - EPP"
    },
    {
        "id": "06073075000260",
        "value": "TRANSPORTADORA RODO CLAM LTDA EPP"
    },
    {
        "id": "87867545000420",
        "value": "TRANSPORTADORA ROCHA LTDA"
    },
    {
        "id": "11443025000267",
        "value": "TRANSPORTADORA ROCHA BRASIL LTDA EPP"
    },
    {
        "id": "52661634001322",
        "value": "TRANSPORTADORA RISSO LTDA"
    },
    {
        "id": "05412961000208",
        "value": "TRANSPORTADORA RGN LTDA"
    },
    {
        "id": "12463679000215",
        "value": "TRANSPORTADORA REBOUCAS"
    },
    {
        "id": "00792522000173",
        "value": "TRANSPORTADORA RAPIDO TAMBAU LTDA EPP"
    },
    {
        "id": "01350187000205",
        "value": "TRANSPORTADORA RAPIDO REAL LOGISTICA LTDA."
    },
    {
        "id": "46472288000289",
        "value": "TRANSPORTADORA RANCHARIENSE LTDA"
    },
    {
        "id": "41797192000439",
        "value": "TRANSPORTADORA PRINCESA DO SUL LTDA EPP"
    },
    {
        "id": "15129315000887",
        "value": "TRANSPORTADORA PRIMEIRA DO NORDESTE LTDA"
    },
    {
        "id": "00260413000296",
        "value": "TRANSPORTADORA PONTUAL LOGISTICA E DISTRIBUICAO LTDA"
    },
    {
        "id": "88085485004959",
        "value": "TRANSPORTADORA PLIMOR LTDA"
    },
    {
        "id": "86501400001259",
        "value": "TRANSPORTADORA PITUTA LTDA"
    },
    {
        "id": "44798650000361",
        "value": "TRANSPORTADORA PINHALENSE LTDA"
    },
    {
        "id": "62955596000130",
        "value": "TRANSPORTADORA PENHENSE LTDA"
    },
    {
        "id": "49567076000273",
        "value": "TRANSPORTADORA PATONA LTDA"
    },
    {
        "id": "12531985000160",
        "value": "TRANSPORTADORA PADRE CICERO"
    },
    {
        "id": "09416480000140",
        "value": "TRANSPORTADORA ONIAS LTDA ME"
    },
    {
        "id": "03581697000634",
        "value": "TRANSPORTADORA OESTE LTDA"
    },
    {
        "id": "09170878000220",
        "value": "TRANSPORTADORA NOVA GERACAO LTDA"
    },
    {
        "id": "01225464000240",
        "value": "TRANSPORTADORA NOVA AMERICA BRAGANCA LTDA EPP"
    },
    {
        "id": "03835508000289",
        "value": "TRANSPORTADORA NOSSA SENHORA DA PENHA"
    },
    {
        "id": "91381095000277",
        "value": "TRANSPORTADORA NORTE GAUCHO LTDA"
    },
    {
        "id": "88057146000203",
        "value": "TRANSPORTADORA NIMEC LTDA"
    },
    {
        "id": "08791315000106",
        "value": "TRANSPORTADORA NACIONAL TURISMO E FRETAMENTO LTDA"
    },
    {
        "id": "87183570000223",
        "value": "TRANSPORTADORA MINUANO LIMITADA"
    },
    {
        "id": "12643969000240",
        "value": "TRANSPORTADORA MARTINS DE MELO LTDA"
    },
    {
        "id": "04155259000243",
        "value": "TRANSPORTADORA M M A LTDA"
    },
    {
        "id": "22417084000104",
        "value": "TRANSPORTADORA LUMAS LTDA"
    },
    {
        "id": "20026779000555",
        "value": "TRANSPORTADORA LUANDRI LTDA"
    },
    {
        "id": "34280900000151",
        "value": "TRANSPORTADORA LEAO DE JUDA"
    },
    {
        "id": "16621163000202",
        "value": "TRANSPORTADORA LEAL EIRELI"
    },
    {
        "id": "20151346000360",
        "value": "TRANSPORTADORA L G LTDA"
    },
    {
        "id": "16526576000208",
        "value": "TRANSPORTADORA JUPITER LTDA"
    },
    {
        "id": "64125644000270",
        "value": "TRANSPORTADORA JOTAFAZIO LTDA"
    },
    {
        "id": "21191580000200",
        "value": "TRANSPORTADORA JOIA LTDA"
    },
    {
        "id": "17294356000340",
        "value": "TRANSPORTADORA JANUARIA LTDA"
    },
    {
        "id": "06987014000208",
        "value": "TRANSPORTADORA JAGUARI"
    },
    {
        "id": "07241838000116",
        "value": "TRANSPORTADORA J.D.F. LTDA EPP (TRES ESTRELAS)"
    },
    {
        "id": "20122910000154",
        "value": "TRANSPORTADORA J H M LTDA"
    },
    {
        "id": "17245895000208",
        "value": "TRANSPORTADORA ITACOLOMY LTDA"
    },
    {
        "id": "55118129000190",
        "value": "TRANSPORTADORA IRMAOS PEREIRA LTDA ME"
    },
    {
        "id": "01347561000124",
        "value": "TRANSPORTADORA IRMAOS BERTO LTDA EPP"
    },
    {
        "id": "18079359000161",
        "value": "TRANSPORTADORA HELLUS BRASIL EIRELI EPP"
    },
    {
        "id": "00087897000213",
        "value": "TRANSPORTADORA GUIMARAES LTDA"
    },
    {
        "id": "10267201000618",
        "value": "TRANSPORTADORA GRANDE RIO LTDA"
    },
    {
        "id": "05909733000167",
        "value": "TRANSPORTADORA GOLDEN LTDA ME"
    },
    {
        "id": "77505550000278",
        "value": "TRANSPORTADORA GOBOR LTDA"
    },
    {
        "id": "18345564000202",
        "value": "TRANSPORTADORA GNT EIRELI ME"
    },
    {
        "id": "44695088000260",
        "value": "TRANSPORTADORA GERBI LTDA EPP"
    },
    {
        "id": "77778892000695",
        "value": "TRANSPORTADORA GAMPER LTDA"
    },
    {
        "id": "47005699000146",
        "value": "TRANSPORTADORA GAINO LTDA"
    },
    {
        "id": "87157061000226",
        "value": "TRANSPORTADORA FREE WAY LTDA"
    },
    {
        "id": "89916449000108",
        "value": "TRANSPORTADORA FOSS LTDA"
    },
    {
        "id": "79290557000407",
        "value": "TRANSPORTADORA FLUORITA LTDA"
    },
    {
        "id": "56678030000297",
        "value": "TRANSPORTADORA FLORESTA LTDA"
    },
    {
        "id": "00252984000360",
        "value": "TRANSPORTADORA FIOROT LTDA"
    },
    {
        "id": "05666598000257",
        "value": "TRANSPORTADORA FELIPETI"
    },
    {
        "id": "03060874000203",
        "value": "TRANSPORTADORA FABRIS LTDA"
    },
    {
        "id": "25412222000142",
        "value": "TRANSPORTADORA EMBORCACAO LTDA"
    },
    {
        "id": "80157936000240",
        "value": "TRANSPORTADORA EDUARDO LTDA EPP"
    },
    {
        "id": "70037379000947",
        "value": "TRANSPORTADORA ECONOMICA LTDA"
    },
    {
        "id": "10488240000130",
        "value": "TRANSPORTADORA E LOGISTICA MM SANTOS LTDA EPP"
    },
    {
        "id": "05877801000153",
        "value": "TRANSPORTADORA E LOCADORA BUSTAMANTE LTDA (TLB)"
    },
    {
        "id": "59470518000131",
        "value": "TRANSPORTADORA DOLLAR LTDA ME"
    },
    {
        "id": "15308085000147",
        "value": "TRANSPORTADORA DIAMETRAL LTDA (ITACOLOMY)"
    },
    {
        "id": "05770492000118",
        "value": "TRANSPORTADORA DE CARGAS ORLANDIA LTDA EPP"
    },
    {
        "id": "08781702000242",
        "value": "TRANSPORTADORA CRISTINA LTDA"
    },
    {
        "id": "28141158000796",
        "value": "TRANSPORTADORA CONTINENTAL LTDA"
    },
    {
        "id": "18907634000198",
        "value": "TRANSPORTADORA COMBOIO BR LTDA"
    },
    {
        "id": "33530734003408",
        "value": "TRANSPORTADORA COLATINENSE LTDA"
    },
    {
        "id": "01631590000628",
        "value": "TRANSPORTADORA CIRCUITO DAS AGUAS LTDA"
    },
    {
        "id": "40582397000272",
        "value": "TRANSPORTADORA CENTRAL LTDA"
    },
    {
        "id": "05111046000100",
        "value": "TRANSPORTADORA CARAVAGGIO LTDA"
    },
    {
        "id": "50109701000212",
        "value": "TRANSPORTADORA CALDERAN LTDA"
    },
    {
        "id": "03419010000154",
        "value": "TRANSPORTADORA BR"
    },
    {
        "id": "81580367000204",
        "value": "TRANSPORTADORA BOMBONATTO LTDA"
    },
    {
        "id": "70033451000291",
        "value": "TRANSPORTADORA BOM JESUS"
    },
    {
        "id": "39475196000106",
        "value": "TRANSPORTADORA BOM FALCAO"
    },
    {
        "id": "29613519000127",
        "value": "TRANSPORTADORA BELA VISTA"
    },
    {
        "id": "16883860000746",
        "value": "TRANSPORTADORA BEIRA RIO LTDA"
    },
    {
        "id": "04841846000296",
        "value": "TRANSPORTADORA ASSUNCAO E ITAPETININGA LTDA"
    },
    {
        "id": "53982542000254",
        "value": "TRANSPORTADORA AQUARIUN LTDA"
    },
    {
        "id": "86049806000270",
        "value": "TRANSPORTADORA ANTONIO GROSSL LIMITADA"
    },
    {
        "id": "43244631002102",
        "value": "TRANSPORTADORA AMERICANA LTDA"
    },
    {
        "id": "00418557000228",
        "value": "TRANSPORTADORA ALMEIDA FANTE LTDA"
    },
    {
        "id": "00348040000129",
        "value": "TRANSPORTADORA AJATO VALE LTDA"
    },
    {
        "id": "87300695000458",
        "value": "TRANSPORTADORA ADUBO LTDA"
    },
    {
        "id": "13585324000180",
        "value": "TRANSPORTADORA 3D"
    },
    {
        "id": "13713781000103",
        "value": "TRANSPIONEIRA TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "44433407000188",
        "value": "TRANSPEROLA"
    },
    {
        "id": "63863997000204",
        "value": "TRANSPEP TRASPORTES LTDA"
    },
    {
        "id": "75156265001588",
        "value": "TRANSPEN"
    },
    {
        "id": "41247891000243",
        "value": "TRANSPECAS TRANSPORTES"
    },
    {
        "id": "25142922000245",
        "value": "TRANSPAULO CARGAS EIRELI"
    },
    {
        "id": "05696996000135",
        "value": "TRANSPAULISTANA TRANSPORTE E LOGISTICA INTEGRADA"
    },
    {
        "id": "09158543000106",
        "value": "TRANSPATRIMON HIDRAULICA, FERRAGENS E TRANSPORTES LTDA - EPP"
    },
    {
        "id": "06311940000188",
        "value": "TRANSPARANORTE"
    },
    {
        "id": "86933033000371",
        "value": "TRANSPAESE TRANSPORTES EIRELI"
    },
    {
        "id": "47011077000120",
        "value": "TRANSP NOVA ALIANCA DE PIRACICABA LTDA ME"
    },
    {
        "id": "03419346000206",
        "value": "TRANSP EXPRESSO AMAZONICO LTDA"
    },
    {
        "id": "43096577000151",
        "value": "TRANSP D'AGOSTINI E REPRESENTACOES LTDA"
    },
    {
        "id": "44765261000259",
        "value": "TRANSOUZA TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "84600378000205",
        "value": "TRANSNORTE TRANSPORTES"
    },
    {
        "id": "18650773000189",
        "value": "TRANSNORTE LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "65293383001401",
        "value": "TRANSNORTE CARGAS E ENCOMENDAS EIRELI"
    },
    {
        "id": "65293383000189",
        "value": "TRANSNORTE CARGAS E ENCOMENDAS"
    },
    {
        "id": "20693532000240",
        "value": "TRANSNORTE ALOG TRANSPORTES EIRELI - EPP"
    },
    {
        "id": "20693532000169",
        "value": "TRANSNORTE ALOG"
    },
    {
        "id": "05404287000210",
        "value": "TRANSNOBREGA TRANSPORTES"
    },
    {
        "id": "46929725000240",
        "value": "TRANSMUDANCA SDS"
    },
    {
        "id": "44682618000154",
        "value": "TRANSMOZER TRANSPORTES & LOGISTICA"
    },
    {
        "id": "75092999000226",
        "value": "TRANSMODULO TRANSPORTADORA RODOVIARIA LTDA"
    },
    {
        "id": "08980543000205",
        "value": "TRANSMODELO LOGISTICA E TRANSPORTE LTDA ME"
    },
    {
        "id": "41368580000232",
        "value": "TRANSMIRANTE TRANSPORTE"
    },
    {
        "id": "07523109000152",
        "value": "TRANSMIR TRANSPORTES"
    },
    {
        "id": "09489111000269",
        "value": "TRANSMINEIRO"
    },
    {
        "id": "08097804000243",
        "value": "TRANSMINATO TRANSPORTES EIRELI"
    },
    {
        "id": "15177661000245",
        "value": "TRANSMETELLO"
    },
    {
        "id": "10372036000150",
        "value": "TRANSMETA GUARULHOS TRANSPORTE E LOGISTICA EIRELI"
    },
    {
        "id": "04987437000200",
        "value": "TRANSMESQUITA"
    },
    {
        "id": "09581843000284",
        "value": "TRANSMEDEIROS"
    },
    {
        "id": "07378968000286",
        "value": "TRANSMATRAL TRANSPORTES - EIRELI"
    },
    {
        "id": "09281153000200",
        "value": "TRANSMASTER"
    },
    {
        "id": "11371629000164",
        "value": "TRANSMARTE SP TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "16802308000271",
        "value": "TRANSMARRECO LTDA"
    },
    {
        "id": "19440740000410",
        "value": "TRANSMARINHO TRANSPORTES E LOGISTICA"
    },
    {
        "id": "24994889000138",
        "value": "TRANSMALU TRANSPORTES"
    },
    {
        "id": "08148642000225",
        "value": "TRANSMALOG TRANSPORTE LTDA ME"
    },
    {
        "id": "79942140001291",
        "value": "TRANSMAGNA TRANSPORTES EIRELI"
    },
    {
        "id": "12968910000141",
        "value": "TRANSMAC LOGISTICA E TRANSPORTE"
    },
    {
        "id": "27398457000152",
        "value": "TRANSLUZ TRANSPORTES"
    },
    {
        "id": "10741115000271",
        "value": "TRANSLOTE LOGISTICA LTDA ME"
    },
    {
        "id": "46983425000240",
        "value": "TRANSLOPES TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "03629957000602",
        "value": "TRANSLIGUE"
    },
    {
        "id": "06072860000117",
        "value": "TRANSLIG LOGISTICA LTDA"
    },
    {
        "id": "35030025000384",
        "value": "TRANSLIBERDADE TRANSPORTE & LOGISTICA"
    },
    {
        "id": "17142262000193",
        "value": "TRANSLEME"
    },
    {
        "id": "07655778000269",
        "value": "TRANSLATINO"
    },
    {
        "id": "07574298000192",
        "value": "TRANSLASIL CARGAS LTDA"
    },
    {
        "id": "10407802000174",
        "value": "TRANSJOTAPE"
    },
    {
        "id": "83630053000385",
        "value": "TRANSJOI TRANSPORTES LTDA"
    },
    {
        "id": "23208639000243",
        "value": "TRANSIBITINGA"
    },
    {
        "id": "12147861000267",
        "value": "TRANSHOFF CARGAS LTDA"
    },
    {
        "id": "01039925000290",
        "value": "TRANSHIZZA TRANSPORTES DE CARGAS E ENCOMENDAS LTDA"
    },
    {
        "id": "53363743000192",
        "value": "TRANS-HARO TRANSPORTES LTDA"
    },
    {
        "id": "08753822000317",
        "value": "TRANSGOSS TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "20093095000223",
        "value": "TRANSGOSS"
    },
    {
        "id": "22520418000162",
        "value": "TRANSGAP LOGISTICA"
    },
    {
        "id": "07598579000185",
        "value": "TRANSFRI 01 TRANSPORTE E COMERCIO DE FRIBURGO LTDA"
    },
    {
        "id": "14066781000202",
        "value": "TRANSFIGUEREDO"
    },
    {
        "id": "00616683000378",
        "value": "TRANSFIEL TRANSPORTES E LOGISTICA EXPRESS LTDA"
    },
    {
        "id": "32772281000197",
        "value": "TRANSFERRER"
    },
    {
        "id": "09091520000121",
        "value": "TRANSFAX LOG TRANSPORTES LTDA"
    },
    {
        "id": "07901280000239",
        "value": "TRANSESTILO TRANSPORTES COM E REP LTDA"
    },
    {
        "id": "28186406000120",
        "value": "TRANSESIO TRANSPORTES"
    },
    {
        "id": "43458751000240",
        "value": "TRANSELERI"
    },
    {
        "id": "03289726000346",
        "value": "TRANSEIS TRANSPORTADORA ARAUTO LTDA"
    },
    {
        "id": "10686690000138",
        "value": "TRANSEG - TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "02484555000939",
        "value": "TRANSDUARTE"
    },
    {
        "id": "22081621000180",
        "value": "TRANSDELLOG TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "11456954000200",
        "value": "TRANSCORSINI"
    },
    {
        "id": "47439310000332",
        "value": "TRANSCOR TRANSPORTADORA CORREA LTDA"
    },
    {
        "id": "32717811000285",
        "value": "TRANSCOMPRAS"
    },
    {
        "id": "01064508000388",
        "value": "TRANSCOMAP TRANSPORTES EIRELI"
    },
    {
        "id": "00133617000347",
        "value": "TRANSCOLINAS TRANSPORTADORA SETE COLINAS LTDA"
    },
    {
        "id": "62140835000366",
        "value": "TRANSCOLE TRANSPORTES URGENTES EIRELI EPP"
    },
    {
        "id": "00008419000190",
        "value": "TRANSCHICAO TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "02457005000173",
        "value": "TRANSCAXIAS LOGISTICA EM MODAL EIRELI"
    },
    {
        "id": "02110781000364",
        "value": "TRANSCARGAS"
    },
    {
        "id": "23025851000275",
        "value": "TRANSCARGA AGENCIAMENTO E DESPACHOS DE CARGA LTDA EPP"
    },
    {
        "id": "11441387000138",
        "value": "TRANSBRITTO EXPRESS LTDA"
    },
    {
        "id": "08472795000304",
        "value": "TRANSBENIX LTDA"
    },
    {
        "id": "00026360000162",
        "value": "TRANSATUAL"
    },
    {
        "id": "04838692000283",
        "value": "TRANSARAGUAIA"
    },
    {
        "id": "08636848000385",
        "value": "TRANSAMEX"
    },
    {
        "id": "49994304000291",
        "value": "TRANSAM"
    },
    {
        "id": "00214121000217",
        "value": "TRANSAL TRANSPORTADORA SALVAN LTDA"
    },
    {
        "id": "12062320000155",
        "value": "TRANS VMC GUARULHOS"
    },
    {
        "id": "01522487000135",
        "value": "TRANS VITORIA"
    },
    {
        "id": "00226637000281",
        "value": "TRANS VENTURA"
    },
    {
        "id": "02058894000104",
        "value": "TRANS SENA"
    },
    {
        "id": "37201516000295",
        "value": "TRANS SD TRANSPORTES RODOVIARIOS S/A"
    },
    {
        "id": "01555896000138",
        "value": "TRANS SAMPAIO TRANSPORTES LTDA"
    },
    {
        "id": "03510361000176",
        "value": "TRANS PORTO GERAL TRANSPORTES E LOGISTICA LTDA ME"
    },
    {
        "id": "95826285000120",
        "value": "TRANS PEPERI GUACU PASSAGEIROS CARGAS E MUDANCAS LTDA"
    },
    {
        "id": "08143416000251",
        "value": "TRANS MOGI LTDA"
    },
    {
        "id": "01169444000380",
        "value": "TRANS MINAS TRANSPORTES LTDA"
    },
    {
        "id": "00750607000270",
        "value": "TRANS MG"
    },
    {
        "id": "07446357000570",
        "value": "TRANS HELTRANS TRANSPORTES SERVICOS E REP LTDA"
    },
    {
        "id": "08613270000180",
        "value": "TRANS GOSS TRANPORTE E LOGISTICA LTDA"
    },
    {
        "id": "14329995000143",
        "value": "TRANS G Y TRANSPORTES LTDA"
    },
    {
        "id": "61683652000243",
        "value": "TRANS FACE TRANSPORTES"
    },
    {
        "id": "19898972000335",
        "value": "TRANS DELTA TRANSPORTADORA DELTA LTDA"
    },
    {
        "id": "01561845000380",
        "value": "TRANS COBRA ROTA OESTE TRANSPORTES LTDA"
    },
    {
        "id": "07021668000245",
        "value": "TRANS CAMPOS DAVID SOARES DE CAMPOS JUNIOR ME"
    },
    {
        "id": "21782545000258",
        "value": "TRANS CALDAS EXPRESS"
    },
    {
        "id": "02764798000688",
        "value": "TRANS CACHOEIRO TRANSPORTE DE CARGAS"
    },
    {
        "id": "02764798000173",
        "value": "TRANS CACHOEIRO TRANSPORTE DE CARGAS"
    },
    {
        "id": "01721991000326",
        "value": "TRANS BERNARDES"
    },
    {
        "id": "00019132000165",
        "value": "TRANS BELLONI LTDA"
    },
    {
        "id": "07770042000150",
        "value": "TRANS APUCARANA TRANSPORTES RODOVIARIOS EIRELI"
    },
    {
        "id": "20471254000286",
        "value": "TRANS - AMIGOS TRANSPORTE E LOGISTICA"
    },
    {
        "id": "00225119000243",
        "value": "TRANGENIO"
    },
    {
        "id": "03415854000470",
        "value": "TRAIN TRANSPORTES INTELIGENTES LTDA EPP"
    },
    {
        "id": "03960921000194",
        "value": "TRADICAO-TRANSPORTE, ARMAZENAGEM E DISTRIBUICAO LTDA"
    },
    {
        "id": "97519146000260",
        "value": "TPL LOGISTICA NORTE"
    },
    {
        "id": "25319886000242",
        "value": "TOTAL TRANSPORTES E LOGISTICA LTDA - ME"
    },
    {
        "id": "22261862000100",
        "value": "TORNADO LOGISTICA EIRELI"
    },
    {
        "id": "46556446000106",
        "value": "TODOBRASIL TRANSPORTES"
    },
    {
        "id": "07784847000234",
        "value": "TOCANTINS TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "07784847000820",
        "value": "TOCANTINS TRANSPORTES"
    },
    {
        "id": "95591723008101",
        "value": "TNT MERCURIO CARGAS E ENCOMENDAS EXPRESSAS LTDA"
    },
    {
        "id": "10321424000101",
        "value": "TNB - TRANSPORTES"
    },
    {
        "id": "09243799000200",
        "value": "TML TRANSPORTES LTDA"
    },
    {
        "id": "10895417000385",
        "value": "TML LOGISTICA"
    },
    {
        "id": "11682198000319",
        "value": "TM FRACTION TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "37738946000240",
        "value": "TLC EXPRESS"
    },
    {
        "id": "11538487000268",
        "value": "TLA LOGISTICA"
    },
    {
        "id": "21340293000630",
        "value": "TJOR TRANSPORTES E LOGISTICA EIRELI"
    },
    {
        "id": "11423942000107",
        "value": "TJB TRANSPORTES"
    },
    {
        "id": "15005858000116",
        "value": "TIMELY LOGISTICA INTEGRADA LTDA"
    },
    {
        "id": "01515934000390",
        "value": "TIME EXPRESS"
    },
    {
        "id": "08974318000266",
        "value": "TGM TRANSPORTES"
    },
    {
        "id": "06092042000267",
        "value": "TEXAS EXPRESS"
    },
    {
        "id": "06367990004921",
        "value": "TEX TRANSPORTE DE ENCOMENDAS EXPRESSAS LTDA."
    },
    {
        "id": "06146343000145",
        "value": "TERRAPLENAGEM TRINDADE"
    },
    {
        "id": "11552312000710",
        "value": "TERMACO LOGISTICA"
    },
    {
        "id": "17615252000209",
        "value": "TEMPO REAL TRANSPORTES & LOGISTICA"
    },
    {
        "id": "07883602000183",
        "value": "TELECARGA EXPRESS"
    },
    {
        "id": "44795516000226",
        "value": "TEL TRANSPORTES ESPECIALIZADOS EIRELI"
    },
    {
        "id": "07251617000129",
        "value": "TEKNOLOGICA"
    },
    {
        "id": "04607157000130",
        "value": "TEHIAR SISTEMAS DE TRANSPORTES E LOJISTICA LTDA"
    },
    {
        "id": "60541240000125",
        "value": "TECNOLOG EXPRESS CARGO"
    },
    {
        "id": "68032937000182",
        "value": "TECEA TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "45253739000224",
        "value": "TEC TRANSPORTE ENCOMENDASE CARGAS LTDA"
    },
    {
        "id": "01610798000156",
        "value": "TEC MAR"
    },
    {
        "id": "08968289000149",
        "value": "TDN TRANSPORTES LTDA"
    },
    {
        "id": "13653603000134",
        "value": "TDL LOGISTICA E ARMAZENAGEM LTDA"
    },
    {
        "id": "33187155000219",
        "value": "TDEX LOGISTICA"
    },
    {
        "id": "06880071000706",
        "value": "T-DAGO TRANSPORTES - SP"
    },
    {
        "id": "09194437000188",
        "value": "TC TRANSCARPAL TRANSPORTE RODOVIARIO DE CARGAS LTDA."
    },
    {
        "id": "00737487000190",
        "value": "TC EXPRESSO DE ENCOMENDAS RAPIDAS LTDA ME"
    },
    {
        "id": "07781766000280",
        "value": "TC BLUMENAU TRANSPORTES DE CARGAS EIRELI"
    },
    {
        "id": "04956556000107",
        "value": "TBB CARGO LTDA (BENTO BELEM)"
    },
    {
        "id": "48733968000153",
        "value": "TAPATI ENCOMENDAS LTDA"
    },
    {
        "id": "03053802000158",
        "value": "TAP EXPRESS EIRELI"
    },
    {
        "id": "60652120000286",
        "value": "TADEX TRANSPORTES EIRELI"
    },
    {
        "id": "11508889000139",
        "value": "TABAJARA LOGISTICA LTDA"
    },
    {
        "id": "21942593000185",
        "value": "T.I. TRANSPORTES"
    },
    {
        "id": "84964840001217",
        "value": "T.G.M. TRANSPORTES"
    },
    {
        "id": "08345638000257",
        "value": "T R S TRANSPORTE DE CARGAS LTDA"
    },
    {
        "id": "21942593000266",
        "value": "T I TRANSPORTES EIRELI EPP"
    },
    {
        "id": "40870024000205",
        "value": "T E TRANSPORTE EXPRESSO LTDA"
    },
    {
        "id": "84550847000210",
        "value": "SUPERCARGAS"
    },
    {
        "id": "02343801000428",
        "value": "SUDOESTE TRANSPORTES EIRELI"
    },
    {
        "id": "12542685000691",
        "value": "SUD LOG TRANSPORTE DE CARGAS"
    },
    {
        "id": "02748818000112",
        "value": "SUA MAJESTADE TRANSPORTES LOGISTICA E ARMAZENAGEM EIRELI"
    },
    {
        "id": "40628140000123",
        "value": "STLGR DISTRIBUICAO LTDA"
    },
    {
        "id": "07159982000450",
        "value": "STILLO TRANSPORTES LTDA"
    },
    {
        "id": "08318452000209",
        "value": "STC SERVICOS DE TRANSPORTES"
    },
    {
        "id": "11576583000381",
        "value": "STAREX TRANSPORTES ROD DE CARGAS E LOG LTDA"
    },
    {
        "id": "07703052000173",
        "value": "STANCANELLI TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "13327196000175",
        "value": "ST SOLUCAO TRANSPORTES E LOGISTICA"
    },
    {
        "id": "07164952000190",
        "value": "SSVV TRANSPORTES LTDA"
    },
    {
        "id": "38007717000138",
        "value": "SSORI SP TRANSPORTES LTDA"
    },
    {
        "id": "22977628000265",
        "value": "SS LOGISTICA"
    },
    {
        "id": "06013646000351",
        "value": "SR LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "43289630000130",
        "value": "SPOEC TRANSPORTES"
    },
    {
        "id": "34270446000158",
        "value": "SPO CAMPINAS"
    },
    {
        "id": "29306746000100",
        "value": "SPMINAS TRANSPORTADORA"
    },
    {
        "id": "03757239000280",
        "value": "SPEED WORK TRANSPORTES EIRELI"
    },
    {
        "id": "08766044000210",
        "value": "SPC TRANSPORTES C. TRANSPORTE"
    },
    {
        "id": "08527918000103",
        "value": "SPBELEM TRANSPORTES - EIRELI"
    },
    {
        "id": "01336703000409",
        "value": "SOUSA & SOUSA TRANSPORTES LTDA"
    },
    {
        "id": "50335371000283",
        "value": "SOTRAMPO TRANSPORTES"
    },
    {
        "id": "14084137000267",
        "value": "SOMA LOGISTICA E TRANSPORTES LTDA ME"
    },
    {
        "id": "01974764000221",
        "value": "SOLUCAO TRANSPORTES LTDA"
    },
    {
        "id": "13327196000256",
        "value": "SOLUCAO TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "25255435000108",
        "value": "SOLUCAO EM NEGOCIOS GENERAL RESEARCH LTDA"
    },
    {
        "id": "08759512000148",
        "value": "SOLNORDESTE TRANSPORTES LTDA"
    },
    {
        "id": "00833219000252",
        "value": "SOLIDEZ TRANSPORTES"
    },
    {
        "id": "02723994000108",
        "value": "SOLIDEZ COMERCIO DE ACESSORIOS PARA VEICULOS LTDA"
    },
    {
        "id": "74167222000210",
        "value": "SOLIDA TRANSPORTE LTDA"
    },
    {
        "id": "05397007000374",
        "value": "SOLICITY EXPRESS"
    },
    {
        "id": "58450842000125",
        "value": "SOL NATAL TRANSPORTES E REPRESENTACOES - EIRELI"
    },
    {
        "id": "05615080000294",
        "value": "SOL DE MINAS TRANSPORTE E TURISMO LTDA"
    },
    {
        "id": "60765823000130",
        "value": "SOCIEDADE BENEF ISRAELITABRAS HOSPITAL ALBERT EINSTEIN"
    },
    {
        "id": "01163051000440",
        "value": "SOCARGA EXPRESS"
    },
    {
        "id": "07656873000287",
        "value": "SL TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "00966339000228",
        "value": "SKYMASTER AIRLINES LTDA"
    },
    {
        "id": "06062322000222",
        "value": "SJP TRANSPORTES"
    },
    {
        "id": "13053769000209",
        "value": "SJK TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "03021108000159",
        "value": "SISTEMA INTERNACIONAL DE CARGAS ARMAZENAGEM E LOGISTICA LTDA"
    },
    {
        "id": "12385965000129",
        "value": "SIRENE CARGAS"
    },
    {
        "id": "47225995000152",
        "value": "SILVEIRA TRANSPORTES E LOGISTICA"
    },
    {
        "id": "02765172000262",
        "value": "SILLAS TRANSPORTES"
    },
    {
        "id": "02046673000108",
        "value": "SICALL CARGAS E ENCOMENDAS"
    },
    {
        "id": "00008420000114",
        "value": "SHIRA TRANSPORTES DE CARGAS LTDA ME"
    },
    {
        "id": "03857894000200",
        "value": "SG LOGISTICA E TRANSPORTES LTDA EPP"
    },
    {
        "id": "27133475000102",
        "value": "SF TRANSPORTES E LOGISTICA DE CARGAS LTDA"
    },
    {
        "id": "04377377000387",
        "value": "SEVEN TRANSPORTE, SEVEN LOGISTICA, SEVEN LOG E SEVEN TL"
    },
    {
        "id": "03098929000517",
        "value": "SETE LAGOAS TRANSPORTES LTDA"
    },
    {
        "id": "52314309000230",
        "value": "SERVICO RODOFERROVIARIO SERFEL LTDA"
    },
    {
        "id": "04100709000110",
        "value": "SERVICE EXPRESS DISTRIBUIDORA E LOGISTICA LTDA"
    },
    {
        "id": "30786189000150",
        "value": "SERRA DO MAR"
    },
    {
        "id": "12156778000254",
        "value": "SERGIPE TRANSPORTES E LOCACOES LTDA"
    },
    {
        "id": "00472038000248",
        "value": "SENE EMPRESA DE TRANSPORTE DE CARGAS E ENCOMENDAS LTDA -"
    },
    {
        "id": "36761633000240",
        "value": "SEM FURO TRANSPORTES"
    },
    {
        "id": "07334878000102",
        "value": "SDF TRANSPORTES"
    },
    {
        "id": "12613474000198",
        "value": "SCUDERIA TRANSPORTES LTDA"
    },
    {
        "id": "01382692000142",
        "value": "SCR TRANSPORTES ARMAZENAGENS E LOGISTICA LTDA"
    },
    {
        "id": "10349430000258",
        "value": "SCHREIBER LOGISTICA"
    },
    {
        "id": "97358667000283",
        "value": "SAO LUIZ TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "32070641000451",
        "value": "SAO GERALDO TRANSPORTES RODOVIARIOS E MULTIMODAIS LTDA"
    },
    {
        "id": "24071400000156",
        "value": "SAO FERNANDO TRANSPORTES EIRELI EPP"
    },
    {
        "id": "39445439000163",
        "value": "SANTA CLARA TRANSPORTES & LOGISTICA"
    },
    {
        "id": "01484094000184",
        "value": "SANSEI TRANSPORTES"
    },
    {
        "id": "02804180000190",
        "value": "SANDEX LOGISTICA"
    },
    {
        "id": "04233201000270",
        "value": "SANAVE TRANSPORTES RODOVIARIOS E LOGISTICA LTDA"
    },
    {
        "id": "32423277000113",
        "value": "SALTO LOG EXPRESS"
    },
    {
        "id": "19315118000137",
        "value": "SAI DE COMPANHIA SAO GERALDO DE VIACAO"
    },
    {
        "id": "14312323000125",
        "value": "S4 TRANSPORTES RODO AEREO LTDA ME"
    },
    {
        "id": "11287989000182",
        "value": "S.A TRANSPORTE"
    },
    {
        "id": "12875842000176",
        "value": "RW TRANSPORTES LOGISTICA E SERVICOS LTDA"
    },
    {
        "id": "02600079000117",
        "value": "RVA DISTRIBUICAO E TRANSPORTES LTDA EPP"
    },
    {
        "id": "07905608000376",
        "value": "RUMONORTE TRANSPORTES LTDA"
    },
    {
        "id": "13735945000101",
        "value": "RUFINO TRANSPORTES AEREOS"
    },
    {
        "id": "03233998000162",
        "value": "RTVM"
    },
    {
        "id": "31419587000100",
        "value": "RTS TRANSPORTE E LOGISTICA"
    },
    {
        "id": "30670827000173",
        "value": "RSS TRANSPORTES E LOGISTICAS"
    },
    {
        "id": "17749910000174",
        "value": "RSS TRANSPORTES"
    },
    {
        "id": "12320456000118",
        "value": "RS CARGO TRANSPORTES LTDA"
    },
    {
        "id": "55724447000103",
        "value": "RRC - RAPIDO RODOVIARIO CURITIBA LTDA"
    },
    {
        "id": "17378954000135",
        "value": "RP TRANSPORTES"
    },
    {
        "id": "26010257000118",
        "value": "ROTA AIR LOG BRASIL LTDA"
    },
    {
        "id": "09491638000228",
        "value": "ROTA 381 TRANSPORTES LTDA"
    },
    {
        "id": "04430233000266",
        "value": "ROSA MISTICA"
    },
    {
        "id": "19650097000297",
        "value": "RONDONIA EXPRESS TRANSPORTES LTDA ME"
    },
    {
        "id": "10873393000282",
        "value": "ROMADELI TRANSPORTES LTDA"
    },
    {
        "id": "04391246000192",
        "value": "ROMA TRANSPORTES"
    },
    {
        "id": "18328842000213",
        "value": "RODRIGO DUARTE FERNANDES ME"
    },
    {
        "id": "08408736000296",
        "value": "RODOVITOR - TRANSPORTES E LOCACAO DE VEICULOS LTDA"
    },
    {
        "id": "03646703000341",
        "value": "RODOVIARIO WILSON LTDA"
    },
    {
        "id": "03183363000106",
        "value": "RODOVIARIO UNICARGA EIRELI"
    },
    {
        "id": "01144602000266",
        "value": "RODOVIARIO SUICA BRASILEIRA"
    },
    {
        "id": "02234917000267",
        "value": "RODOVIARIO SOL NASCENTE LTDA"
    },
    {
        "id": "06238238000640",
        "value": "RODOVIARIO RS"
    },
    {
        "id": "10793990000388",
        "value": "RODOVIARIO RECIFENSE LTDA"
    },
    {
        "id": "01195317000274",
        "value": "RODOVIARIO RACAL LTDA"
    },
    {
        "id": "13885161000151",
        "value": "RODOVIARIO NASA LTDA"
    },
    {
        "id": "02921338000292",
        "value": "RODOVIARIO N.SRA. DAS GRACAS EIRELI"
    },
    {
        "id": "48349104000213",
        "value": "RODOVIARIO LAMESA"
    },
    {
        "id": "01537083000549",
        "value": "RODOVIARIO GOYAZ"
    },
    {
        "id": "20033874000233",
        "value": "RODOVIARIO FRUTALENSE EIRELI"
    },
    {
        "id": "05868169000262",
        "value": "RODOVIARIO FRIBURGO"
    },
    {
        "id": "61917340000176",
        "value": "RODOVIARIO FRANCANO LTDA"
    },
    {
        "id": "60433877000106",
        "value": "RODOVIARIO CINCO ESTRELAS LTDA"
    },
    {
        "id": "66812736000253",
        "value": "RODOVIARIO CAMPINAS"
    },
    {
        "id": "19451038002403",
        "value": "RODOVIARIO CAMILO DOS SANTOS FILHO LTDA"
    },
    {
        "id": "26290395000289",
        "value": "RODOVIARIO ANDRADE LTDA-ME"
    },
    {
        "id": "81156945000280",
        "value": "RODOVIARIO AFONSO"
    },
    {
        "id": "07676187000197",
        "value": "RODOTEM"
    },
    {
        "id": "02306346000229",
        "value": "RODOSUL TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "23738252000118",
        "value": "RODOSP TRANSPORTES RODOVIARIO SP EIRELI"
    },
    {
        "id": "73692899000205",
        "value": "RODOSOLO RODO ROLAN TRANSPORTES LTDA"
    },
    {
        "id": "00849291000278",
        "value": "RODOPAN"
    },
    {
        "id": "44914992001371",
        "value": "RODONAVES TRANSPORTES E ENCOMENDAS LTDA"
    },
    {
        "id": "10386287000274",
        "value": "RODONASA"
    },
    {
        "id": "07156788000179",
        "value": "RODOMEN EXPRESS LOGISTICA E TRANSPORTES LTDA."
    },
    {
        "id": "39235505000251",
        "value": "RODOMARY TRANSPORTES LTDA"
    },
    {
        "id": "39235505000170",
        "value": "RODOMARY TRANSPORTES LTDA"
    },
    {
        "id": "29285239000137",
        "value": "RODOLETE TRANSPORTES"
    },
    {
        "id": "04895882000151",
        "value": "RODOJUNIOR"
    },
    {
        "id": "43566686000195",
        "value": "RODOJAN TRANSPORTES LTDA"
    },
    {
        "id": "34934522000263",
        "value": "RODOILHA - FILIAL SAO PAULO"
    },
    {
        "id": "34934522000182",
        "value": "RODOILHA"
    },
    {
        "id": "60960473000243",
        "value": "RODOGARCIA TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "05214772000905",
        "value": "RODOFLY"
    },
    {
        "id": "45386448000204",
        "value": "RODOCERTO TRANSPORTES LTDA"
    },
    {
        "id": "07494510000373",
        "value": "RODOCARGAS"
    },
    {
        "id": "01119497000205",
        "value": "RODOBARRA TRANSPORTE"
    },
    {
        "id": "01225301000267",
        "value": "RODOANI TRANSPORTES LTDA"
    },
    {
        "id": "06697386000119",
        "value": "RODOALTO TRANSPORTES"
    },
    {
        "id": "02085838000312",
        "value": "RODO CARGO ENCOMENDAS URGENTES EIRELI"
    },
    {
        "id": "04124624000252",
        "value": "RODA VIVA"
    },
    {
        "id": "24341042000235",
        "value": "ROBSON FLORENCIO GUALBERTO"
    },
    {
        "id": "12832570000127",
        "value": "RN FAST TRANSPORTE RODOVIARIO DE CARGAS LTDA"
    },
    {
        "id": "67447409000202",
        "value": "RKM TRANSPORTES LTDA"
    },
    {
        "id": "05209691000232",
        "value": "RISSO ENCOMENTAS CENTRO OESTE PAULISTA"
    },
    {
        "id": "05209691000828",
        "value": "RISSO ENCOMENDAS"
    },
    {
        "id": "06925462000791",
        "value": "RIOUNI TRANSPORTES DE CARGAS S/A."
    },
    {
        "id": "08052725000216",
        "value": "RIO DO NORTE TRANSPORTES"
    },
    {
        "id": "08581458000274",
        "value": "RICARDO DE MELO SILVA TRANSPORTES ME"
    },
    {
        "id": "02592421000184",
        "value": "RG OSASCO TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "86720786000218",
        "value": "REZZE TRANSPORTES LTDA"
    },
    {
        "id": "03864554000125",
        "value": "REUS"
    },
    {
        "id": "83083428000334",
        "value": "REUNIDAS"
    },
    {
        "id": "20344185000169",
        "value": "RESGATE SAO PAULO TRANSPORTES LTDA"
    },
    {
        "id": "01034009000429",
        "value": "RENT A TRUCK OPERADOR LOGISTICO LTDA"
    },
    {
        "id": "11252257000238",
        "value": "RENOVAR EXPRESS LOGISTICA EIRELI"
    },
    {
        "id": "05832055000181",
        "value": "RENOVACAO TRANSPORTES"
    },
    {
        "id": "34261507000110",
        "value": "Renato"
    },
    {
        "id": "66772229000152",
        "value": "RENADIS - TRANSPORTES LTDA"
    },
    {
        "id": "75324905001945",
        "value": "REMAC S A TRANSPORTES RODOVIARIOS"
    },
    {
        "id": "20546130000212",
        "value": "RELAMPAGO LOGISTICA"
    },
    {
        "id": "08192055000233",
        "value": "REIS TRANSPORTES"
    },
    {
        "id": "04391246000273",
        "value": "REGINALDO MOREIRA DE ANDRADE"
    },
    {
        "id": "04121789000190",
        "value": "REDYAR OTM TRANSPORTE LTDA"
    },
    {
        "id": "02006292000103",
        "value": "REDIGOLO & REDIGOLO S J CAMPOS LTDA"
    },
    {
        "id": "92598853000357",
        "value": "RECRIS"
    },
    {
        "id": "17683119000290",
        "value": "REBOUCAS TRANSPORTES"
    },
    {
        "id": "03312913000212",
        "value": "REBELO JUNIOR TRANSPS LTDA"
    },
    {
        "id": "09465862000326",
        "value": "REAL LOGISTICA LTDA"
    },
    {
        "id": "21773395001450",
        "value": "REAL ENCOMENDAS E CARGAS LTDA"
    },
    {
        "id": "06122826000100",
        "value": "RCC TRANSPORTES"
    },
    {
        "id": "10887811000100",
        "value": "RBL TRANSPORTES RODOVIARIOS"
    },
    {
        "id": "14682495000190",
        "value": "RAPIDOBR TRANSPORTE & LOGISTICA"
    },
    {
        "id": "27775231000204",
        "value": "RAPIDO XAPURI TRANSPORTES E LOGISTICA"
    },
    {
        "id": "07331961000128",
        "value": "RAPIDO TRES PONTAS PAULISTA TRANSPORTES RODOVIARIOS LTDA."
    },
    {
        "id": "88317847003080",
        "value": "RAPIDO TRANSPAULO LTDA"
    },
    {
        "id": "23391623000138",
        "value": "RAPIDO SUL LOGISTICA E TRANSPORTES EIRELI"
    },
    {
        "id": "00004102000266",
        "value": "RAPIDO SAO CARLOS LTDA"
    },
    {
        "id": "04281036000737",
        "value": "RAPIDO RORAIMA LTDA"
    },
    {
        "id": "32540881000120",
        "value": "RAPIDO PAULISTANA"
    },
    {
        "id": "86479268000254",
        "value": "RAPIDO PANAMERICANO EIRELI"
    },
    {
        "id": "10382132000189",
        "value": "RAPIDO MONTES CLAROS"
    },
    {
        "id": "28350049000436",
        "value": "RAPIDO MINEIRO"
    },
    {
        "id": "46178828000135",
        "value": "RAPIDO LONDON S/A"
    },
    {
        "id": "45900333000105",
        "value": "RAPIDO LOG PRESS"
    },
    {
        "id": "88380498000297",
        "value": "RAPIDO LABARCA TRANSPORTES LTDA"
    },
    {
        "id": "87858361000313",
        "value": "RAPIDO GARIBALDI"
    },
    {
        "id": "68426188000178",
        "value": "RAPIDO FIGUEIREDO TRANSPORTES LTDA"
    },
    {
        "id": "10973773000108",
        "value": "RAPIDO FIGUEIREDO LOGISTICA E TRANSPORTES EIRELI"
    },
    {
        "id": "12962900000280",
        "value": "RAPIDO CENTRAL TRANSPORTES DE CARGA LTDA"
    },
    {
        "id": "12385156000389",
        "value": "RAPIDO BRASIL SUL TRANSPORTES RODOVIARIO E LOGISTICA LTDA"
    },
    {
        "id": "25864877000313",
        "value": "RAPIDO BETIM LTDA"
    },
    {
        "id": "66199068000230",
        "value": "RAPIDO ALEM PARAIBA LTDA"
    },
    {
        "id": "67787663000250",
        "value": "RAPIDO 90 TRANSPORTES LTDA"
    },
    {
        "id": "17441769000229",
        "value": "RAPIDO 700 LOGISTICA E TRANSPORTES EIRELI"
    },
    {
        "id": "02398208000136",
        "value": "RAPIDAO SAO PAULO"
    },
    {
        "id": "03728172000174",
        "value": "RAPAL PAULISTA"
    },
    {
        "id": "03728172000506",
        "value": "RAPAL"
    },
    {
        "id": "67257766000208",
        "value": "RAITO TRANSPORTE EIRELI"
    },
    {
        "id": "14853650000437",
        "value": "RAFFO TRANSPORTES LTDA"
    },
    {
        "id": "24882886000293",
        "value": "RADLOG"
    },
    {
        "id": "09516181000269",
        "value": "RADIANTE TRANSPORTE LTDA"
    },
    {
        "id": "63935688000111",
        "value": "RACA TRANSPORTES LTDA"
    },
    {
        "id": "39298018000157",
        "value": "RAC EXPRESS"
    },
    {
        "id": "00564267000292",
        "value": "R.R. TRANSPORTES LTDA"
    },
    {
        "id": "12236074000291",
        "value": "R.J.F. TRANSPORTE DE CARGAS LTDA"
    },
    {
        "id": "10480914000150",
        "value": "R.G.L. LOGISTICA E TRANSPORTES LTDA."
    },
    {
        "id": "01283759000272",
        "value": "R.F.ZANINI - TRANSPORTES"
    },
    {
        "id": "20706810000256",
        "value": "R. M. TRANSPORTES RODOVIARIO DE CARGAS"
    },
    {
        "id": "08797426000200",
        "value": "R T TRANSP DE CARGAS RODOFLUVIAL LTDA ME"
    },
    {
        "id": "08855929000104",
        "value": "R M DE JESUS TRANSPORTES"
    },
    {
        "id": "08826480000156",
        "value": "QUANTA EXPRESS SERVICOS DE ENTREGAS RAPIDAS LTDA EPP"
    },
    {
        "id": "04485767000451",
        "value": "PVH OTM TRANSPORTES EIRELI"
    },
    {
        "id": "05331394000184",
        "value": "PULSAR LOGISTICA LTDA"
    },
    {
        "id": "06911090000159",
        "value": "PSC TRANSPORTES PESTANA LTDA"
    },
    {
        "id": "13639824000239",
        "value": "PRONTO CARGO"
    },
    {
        "id": "10580938000181",
        "value": "PROGRESSO LOGISTICA"
    },
    {
        "id": "14141317000152",
        "value": "PRIORITY TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "28621994000182",
        "value": "PRINCESA DO SUL TRANSPORTES"
    },
    {
        "id": "07095710000272",
        "value": "PRIMO LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "30677944000169",
        "value": "PRESS SERVICE TRANSPORTES LTDA"
    },
    {
        "id": "17059789000230",
        "value": "PREMIUM"
    },
    {
        "id": "09296339000232",
        "value": "PREFERENCIAL TRANSPORTADORA LTDA ME"
    },
    {
        "id": "46174884000100",
        "value": "PRATA TRANSPORTES"
    },
    {
        "id": "29459679000244",
        "value": "POWER TRANSPORTES"
    },
    {
        "id": "71154462000373",
        "value": "POWER EXPRESS LOGISTICA E TRANSPORTES EIRELI"
    },
    {
        "id": "07136315000100",
        "value": "POTENZA"
    },
    {
        "id": "05511905000140",
        "value": "POTENCIAL EXPRESS"
    },
    {
        "id": "05812176000242",
        "value": "POSITIVA LOGISTICA E TRANSPORTE"
    },
    {
        "id": "02866031000155",
        "value": "PORTRANS"
    },
    {
        "id": "27008019000130",
        "value": "PORTELOG EXPRESS"
    },
    {
        "id": "01237388000292",
        "value": "PONTUAL TRANSPORTE DE CARGAS LTDA ME"
    },
    {
        "id": "05698101000363",
        "value": "POLO LOGISTICA"
    },
    {
        "id": "20111917000170",
        "value": "PLURAL LOGISTICA E TRANSPORTES LTDA EPP"
    },
    {
        "id": "76530278000213",
        "value": "PLUMA CONFORTO E TURISMO S A EM RECUPERACAO JUDICIAL"
    },
    {
        "id": "06937433000235",
        "value": "PLSC TRANSPORTE RODOVIARIO LTDA"
    },
    {
        "id": "90735549009335",
        "value": "PLANALTO ENCOMENDAS LTDA"
    },
    {
        "id": "01953950000101",
        "value": "PITANGUI TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "06896941000131",
        "value": "PIRACICABANA TRANSPORTE DE CARGAS E ENCOMENDAS LTDA"
    },
    {
        "id": "59460592000177",
        "value": "PIQUETUR PASSAGENS E TURISMO LIMITADA"
    },
    {
        "id": "07784126000143",
        "value": "PIONEIRO TRANSPORTE DE CARGAS EIRELI"
    },
    {
        "id": "17317717000245",
        "value": "PIONEIRA LOG"
    },
    {
        "id": "20009687000133",
        "value": "PIN EXPRESS"
    },
    {
        "id": "21570775000687",
        "value": "PICORELLI S A TRANSPORTES"
    },
    {
        "id": "01496359000245",
        "value": "PICCOLI TRANSPORTES"
    },
    {
        "id": "10239426000237",
        "value": "PICANCIO & LIMA CARGAS E MUDANCAS LTDA ME"
    },
    {
        "id": "06039758000110",
        "value": "PHENIX - COMERCIO, LOCACOES, LOGISTICA, SERVICOS & TRAN"
    },
    {
        "id": "19263972000105",
        "value": "PHE TRANSPORTE E LOGISTICA LTDA"
    },
    {
        "id": "06318618000342",
        "value": "PEXLOG"
    },
    {
        "id": "17348237000241",
        "value": "PETROCARGAS TRANSPORTES"
    },
    {
        "id": "01757449000323",
        "value": "PETROCARGAS"
    },
    {
        "id": "29972839000173",
        "value": "PEL TRANSPORTES LTDA"
    },
    {
        "id": "17555527000266",
        "value": "PEDRAL TRANSPORTE"
    },
    {
        "id": "11074841000160",
        "value": "PECAS AUTOMOTIVAS ZEENE LTDA"
    },
    {
        "id": "18848388000140",
        "value": "PAULO VICENTE BERTIN DA ROCHA TRANSPORTES"
    },
    {
        "id": "17463456000352",
        "value": "PATRUS TRANSPORTES LTDA"
    },
    {
        "id": "61563557000125",
        "value": "PASSARO MARRON"
    },
    {
        "id": "23079740000322",
        "value": "PARTNERS LOGISTICS LTDA"
    },
    {
        "id": "00140296000146",
        "value": "PARDAL TRANSPORTES LTDA"
    },
    {
        "id": "06649013000253",
        "value": "PARANA SUL"
    },
    {
        "id": "46970030000202",
        "value": "PANTANAL TRANSPORTES E LOGISTICA "
    },
    {
        "id": "08662661000275",
        "value": "PANTANAL LOGISTICA"
    },
    {
        "id": "03301398000275",
        "value": "PANAMERICANO TRANSPORTE RODOVIARIO DE CARGAS LTDA"
    },
    {
        "id": "01321367000170",
        "value": "PAN CARGO EXPRESS TRANSPORTES LTDA EPP"
    },
    {
        "id": "25683209000390",
        "value": "PAJEU TRANSPORTES"
    },
    {
        "id": "02964147000127",
        "value": "PACIFICO LOG LOGISTICA E TRANSPORTES EIRELI"
    },
    {
        "id": "07846212000214",
        "value": "P B EXPRESS"
    },
    {
        "id": "82918939000285",
        "value": "OZELLAME CARGAS URGENTES EIRELI EPP"
    },
    {
        "id": "18602775000100",
        "value": "OUT SIDE TRANSPORTES LTDA ME"
    },
    {
        "id": "10845461000119",
        "value": "OTIMA TRANSPORTES LTDA"
    },
    {
        "id": "13382263000154",
        "value": "OSWALDO ESTIVAN (SERRA DO MAR)"
    },
    {
        "id": "19872823000135",
        "value": "OSTEU TRANSPORTES"
    },
    {
        "id": "08435450000380",
        "value": "OSMAR LUIZ TAVARES EIRELI"
    },
    {
        "id": "02906547000267",
        "value": "ORLA H D L TRANSPORTES"
    },
    {
        "id": "07531716000164",
        "value": "OPCAO TRANSPORTES"
    },
    {
        "id": "07333662000122",
        "value": "ONCA TRANSPORTES"
    },
    {
        "id": "01784408000407",
        "value": "OMNI WAY TRANSPORTES"
    },
    {
        "id": "11318182000160",
        "value": "OLIVEIRA E OLIVEIRA TRANSPORTES LTDA-ME"
    },
    {
        "id": "00246289000204",
        "value": "OITAVA REGIAO TRANSPORTES EIRELI"
    },
    {
        "id": "06281008000583",
        "value": "OESTESUL TRANSPORTES LTDA"
    },
    {
        "id": "47276654000106",
        "value": "O.T.I. ORGANIZACAO DE TRANSPORTES INTEGRADOS - EIRELI"
    },
    {
        "id": "01411363000425",
        "value": "O S TRANSPORTES LTDA"
    },
    {
        "id": "35632522000352",
        "value": "O C T TRANSPORTES DE CARGAS E LOGISTICA LTDA"
    },
    {
        "id": "05156656000383",
        "value": "NSA LOG"
    },
    {
        "id": "18783678000153",
        "value": "NRE TRANSPORTES"
    },
    {
        "id": "17442669000306",
        "value": "NPT - NOVO PROGRESSO TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "06349754000138",
        "value": "NOVO VALE TRANSPORTES"
    },
    {
        "id": "07287489000173",
        "value": "NOVO STILO CARGAS EIRELI"
    },
    {
        "id": "06066698000205",
        "value": "NOVO REINO LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "40583156000248",
        "value": "NOVA VARGINHA TRANSPORTES E LOGISTICA"
    },
    {
        "id": "66629627000203",
        "value": "NOVA UNIAO TRANSPORTES RODOVIARIO DE CARGAS LTDA"
    },
    {
        "id": "15137769000204",
        "value": "NOVA TJ TRANSPORTES"
    },
    {
        "id": "03380600000110",
        "value": "NOVA STAR TRANSPORTES LTDA EPP"
    },
    {
        "id": "28116482000240",
        "value": "NOVA ROTA TRANSPORTES"
    },
    {
        "id": "05278012000104",
        "value": "NOVA OPCAO DANIEL NOGUEIRA DA COSTA TRANSPORTES EPP"
    },
    {
        "id": "42934489000208",
        "value": "NOVA MINAS LOGISTICA"
    },
    {
        "id": "59456699000141",
        "value": "NOVA IBIUNA TRANSPORTE E COMERCIO DE MATERIAL DE CONSTRUCAO LTDA"
    },
    {
        "id": "13371755000225",
        "value": "NOVA GERACAO TRANSPORTES LTDA"
    },
    {
        "id": "10227207000319",
        "value": "NOVA DIRECAO TRANSPORTES LTDA"
    },
    {
        "id": "09620736000137",
        "value": "NOVA CLASSIC TRANSPORTES LTDA"
    },
    {
        "id": "07179580000256",
        "value": "NOSSA TRANSPORTADORA LTDA"
    },
    {
        "id": "09651932000179",
        "value": "NOSSA GENTE TRANSPORTES LTDA"
    },
    {
        "id": "23926683000299",
        "value": "NORTHE CARGAS"
    },
    {
        "id": "05028573000228",
        "value": "NORTEX NORTE TRANSPORTES EXPRESSO LTDA"
    },
    {
        "id": "02336452000255",
        "value": "NORTE SUL CARGO LTDA"
    },
    {
        "id": "31600526000136",
        "value": "NORTE EXPRESSO"
    },
    {
        "id": "13838931001268",
        "value": "NORDEX LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "05098093000233",
        "value": "NORDESTE TRANSPORTE REAL LTDA"
    },
    {
        "id": "12884866000191",
        "value": "NMF DA COSTA TRANSPORTES E LOGISTICA"
    },
    {
        "id": "35315829000320",
        "value": "NDT TRANSPORTES COM E REPRESENTACOES LTDA"
    },
    {
        "id": "01222681000529",
        "value": "NASCISUL TRANSPORTES LTDA"
    },
    {
        "id": "18260422000242",
        "value": "NACIONAL EXPRESSO"
    },
    {
        "id": "09323807000130",
        "value": "N. BARIAO TRANSPORTADORA ME"
    },
    {
        "id": "06016175000173",
        "value": "N P J EXPRESS TRANSPORTES LTDA"
    },
    {
        "id": "05003906000264",
        "value": "MVS RIO PRETO TRANSPORTES LIMITADA EPP"
    },
    {
        "id": "53905725000195",
        "value": "MULTIPLA LOGISTICA E TRANSPORTES LTDA EPP"
    },
    {
        "id": "07525020000397",
        "value": "MULTIPLA B.G."
    },
    {
        "id": "19029870000200",
        "value": "MULTILOG - TRANSPORTES"
    },
    {
        "id": "10449779000261",
        "value": "MULTIFOX TRANSPORTES LTDA"
    },
    {
        "id": "04974172000206",
        "value": "MULTICARGAS LTDA"
    },
    {
        "id": "03304119000146",
        "value": "MULTI OESTE TRANSPORTE E LOGISTICA LTDA"
    },
    {
        "id": "14547062000203",
        "value": "MULT CARGAS TRANSPORTES LTDA"
    },
    {
        "id": "07360468000136",
        "value": "MTR LOGISTICA EIRELI"
    },
    {
        "id": "73316630000224",
        "value": "MTL TRANSPORTES"
    },
    {
        "id": "19386072000147",
        "value": "MST TRANSPORTES RODO-AEREO"
    },
    {
        "id": "15627294000153",
        "value": "MRT EXPRESS TRANSPORTES LTDA"
    },
    {
        "id": "23864838000471",
        "value": "MOVVI LOGISTICA LTDA"
    },
    {
        "id": "27418811000163",
        "value": "MOTO HELP SP LOGISTICA E TRANSPORTES EIRELI"
    },
    {
        "id": "19548621000132",
        "value": "MORAES TRANSPORTES"
    },
    {
        "id": "88009030000371",
        "value": "MODULAR TRANSPORTES LTDA"
    },
    {
        "id": "07070900000316",
        "value": "MMC TRANSPORTES E LOGISTICA EIRELI"
    },
    {
        "id": "11114508000218",
        "value": "MLS TRANSPORTE DE CARGAS LTDA"
    },
    {
        "id": "01515639000253",
        "value": "ML LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "15039118000362",
        "value": "MISTAO"
    },
    {
        "id": "58506155002551",
        "value": "MIRA OTM TRANSPORTES LTDA"
    },
    {
        "id": "03565095000260",
        "value": "MIR TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "03290503000137",
        "value": "MINAS WAY TRANSPORTES S/A"
    },
    {
        "id": "41676545000280",
        "value": "MINAS STAR EXPRESS LTDA"
    },
    {
        "id": "71219778000215",
        "value": "MINAS SAO PAULO TRANSPORTES LTDA"
    },
    {
        "id": "00091369000238",
        "value": "MILENIUM TRANSPORTES LTDA"
    },
    {
        "id": "07697617000238",
        "value": "MILANO"
    },
    {
        "id": "54769765000110",
        "value": "MILANI LOGISTICA TRANSPORTES"
    },
    {
        "id": "68266030000188",
        "value": "MIL KM TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "12148802000203",
        "value": "MGA LOGISTICA"
    },
    {
        "id": "18435877000170",
        "value": "MG TRANSPORTES"
    },
    {
        "id": "08800073000250",
        "value": "MF TRANSPORTE LOGISTICA"
    },
    {
        "id": "45506977000202",
        "value": "MF LOGISTICA"
    },
    {
        "id": "10992167003660",
        "value": "METAR LOGISTICA LTDA."
    },
    {
        "id": "10992167004046",
        "value": "METAR LOGISTICA LTDA"
    },
    {
        "id": "20121850000155",
        "value": "MERCADO ENVIOS"
    },
    {
        "id": "30300117000151",
        "value": "MEMFS TRANSPORTES"
    },
    {
        "id": "07420253000163",
        "value": "MELITTIO TRANSPORTES LTDA"
    },
    {
        "id": "26182255000106",
        "value": "MEIDEX LOGISTICA E MANUTENCAO DE ELETRONICOS LTDA"
    },
    {
        "id": "05221058000189",
        "value": "MEGABASI TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "12877025000157",
        "value": "MEGA PARTES COMERCIAL"
    },
    {
        "id": "20121850000317",
        "value": "ME FULFILLMENT"
    },
    {
        "id": "19878473000203",
        "value": "MDX LOGISTICA"
    },
    {
        "id": "57686057000103",
        "value": "MCP TRANSPORTES LTDA."
    },
    {
        "id": "01310603000152",
        "value": "MBS MULTI BENS TRANSPORTES LTDA"
    },
    {
        "id": "12920525000205",
        "value": "MAY TRANSPORTES E LOGISTICA EIRELI"
    },
    {
        "id": "09262107000182",
        "value": "MAXX EXPRESS"
    },
    {
        "id": "04910940000179",
        "value": "MAXIMA SERVICOS E LOGISTICA LTDA"
    },
    {
        "id": "18376776000176",
        "value": "MAXIMA LOG TRANSPORTE EIRELI"
    },
    {
        "id": "07879189000183",
        "value": "MAXICARGO"
    },
    {
        "id": "00281778000388",
        "value": "MAUTTO MTL TRANSPORTES LTDA"
    },
    {
        "id": "02665427000215",
        "value": "MATEUS & SILVEIRA LTDA"
    },
    {
        "id": "05151100000213",
        "value": "MARSUL TRANSPORTES LTDA"
    },
    {
        "id": "12681861000161",
        "value": "MARLON DIAS FERNANDES - ME"
    },
    {
        "id": "08765896000292",
        "value": "MARILINS MUDANCA E TRANSPORTE LTDA ME"
    },
    {
        "id": "07053225000155",
        "value": "MARIA CATARINA TRANS ROD LTDA"
    },
    {
        "id": "07046852000240",
        "value": "MARCOPOLO COMERCIO E LOGISTICA LTDA ME"
    },
    {
        "id": "05481918000113",
        "value": "Marcone"
    },
    {
        "id": "19867774000142",
        "value": "MARCIO FIRMO COMERCIO E SERVICOS DE TRANSPORTE ME"
    },
    {
        "id": "03186461000199",
        "value": "MARCELO ROCHA EIRELI"
    },
    {
        "id": "05852359000100",
        "value": "MARCELO MARQUES DE SOUZA TRANSPORTES ME"
    },
    {
        "id": "07328722000119",
        "value": "Marcelo"
    },
    {
        "id": "02973203000190",
        "value": "MARA-JA TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "06072297000268",
        "value": "MAR E SOL TRASPORTES"
    },
    {
        "id": "00538704000201",
        "value": "MANIR TRANSPORTES LTDA"
    },
    {
        "id": "83646562000304",
        "value": "MANIQUE ALGEMIRO BARRETO E CIA LTDA"
    },
    {
        "id": "03298420000356",
        "value": "MANDA LA TRANSPORTES DE CARGAS"
    },
    {
        "id": "13119676000221",
        "value": "MANASSES TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "26590178000123",
        "value": "MALTANELLI TRANSPORTES"
    },
    {
        "id": "32893453000180",
        "value": "MAIS BRASIL LOGISTICA"
    },
    {
        "id": "10204509000290",
        "value": "MAIK MIRANDA TRANSPORTES LTDA EPP"
    },
    {
        "id": "09230232000372",
        "value": "MAGALHAES LOGISTICA - SP"
    },
    {
        "id": "67743625000114",
        "value": "MAEX BRASIL"
    },
    {
        "id": "08657939000135",
        "value": "MACAE EXPRESS"
    },
    {
        "id": "14586146000256",
        "value": "MAC - TRANS EXPRESSO RODOVIARIO LTDA"
    },
    {
        "id": "16816491000264",
        "value": "M-10 TRANSPORTADORA E LOGISTICA LTDA"
    },
    {
        "id": "29737498000151",
        "value": "M.V.X. TRANSPORTES"
    },
    {
        "id": "05081404000170",
        "value": "M.K TRANSPORTES LTDA"
    },
    {
        "id": "10884076000180",
        "value": "M.C. POLETTI RODRIGUES TRANSPORTES EIRELI"
    },
    {
        "id": "17178151000218",
        "value": "M. FREIRE BEZERRA DE MORAIS"
    },
    {
        "id": "59159715000215",
        "value": "M&A TRANSPORTES LTDA"
    },
    {
        "id": "17676693001373",
        "value": "M W TRANSPORTES LTDA"
    },
    {
        "id": "13500799000208",
        "value": "M T TORIGOE TRANSPORTES ME"
    },
    {
        "id": "04289284000139",
        "value": "M O S LOGISTICA"
    },
    {
        "id": "18905361000142",
        "value": "M BRASIL SERVICE EXPRESS"
    },
    {
        "id": "04287948000201",
        "value": "M A F - TRANSPORTES LTDA"
    },
    {
        "id": "03962199000127",
        "value": "LYON EXPRESS LTDA"
    },
    {
        "id": "08992005000150",
        "value": "LUNA EXPRESS"
    },
    {
        "id": "15386166000165",
        "value": "Luis"
    },
    {
        "id": "08037312000181",
        "value": "LUFEX"
    },
    {
        "id": "78386617000184",
        "value": "Luciano"
    },
    {
        "id": "03351862000238",
        "value": "LOVITHA TRANSPORTES LTDA"
    },
    {
        "id": "14207428000203",
        "value": "LOSANE VARIEDADES"
    },
    {
        "id": "10266668000139",
        "value": "LORD COCKRANE TRANSPORTES RODOVIARIOS ME"
    },
    {
        "id": "14991975000303",
        "value": "LOPINE TRANSPORTE E LOGISTICA LTDA EPP"
    },
    {
        "id": "05423509000756",
        "value": "LOPES SUL"
    },
    {
        "id": "06037644000130",
        "value": "LONGNAT TRANSPORTES LTDA"
    },
    {
        "id": "12529058000297",
        "value": "LOGT LOGISTICA E TRANSPORTES LTDA EPP"
    },
    {
        "id": "04903350000380",
        "value": "LOGISTICA TPL LTDA - EPP"
    },
    {
        "id": "15053254000498",
        "value": "LOGISTICA E TRANSPORTES SAO LUIZ LTDA."
    },
    {
        "id": "06647064000165",
        "value": "LOGISTICA E TRANSPORTES GR LTDA"
    },
    {
        "id": "07717437000270",
        "value": "LOGISTICA E TRANSPORTES ASA NORTE LTDA"
    },
    {
        "id": "09211427000103",
        "value": "LOGISTICA CENTRO OESTE"
    },
    {
        "id": "00822786000203",
        "value": "LOGISTICA CARLAO CAMINHOES"
    },
    {
        "id": "04135801000115",
        "value": "LOGISCOOPER COOPERATIVA DE TRABALHO DE PROFISSIONAIS"
    },
    {
        "id": "04994859000203",
        "value": "LOGIBRAS EXPRESS LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "33156044000164",
        "value": "LOG7 LOGISTICA"
    },
    {
        "id": "06995647000187",
        "value": "LOG EXPRESS TRANSPORTES LTDA"
    },
    {
        "id": "07039762000221",
        "value": "LOG CARGO TRANSPORTES E LOGISTICA LTDA - ME"
    },
    {
        "id": "16926574000125",
        "value": "LOG 100 LOGISTICA"
    },
    {
        "id": "01792706000104",
        "value": "LOCATELLI TRANSPORTES RODOVIARIOS"
    },
    {
        "id": "22861879000107",
        "value": "LOCAL PECAS AUTOMOTIVAS RS LTDA - ME"
    },
    {
        "id": "07043293000214",
        "value": "LOBATRANS TRANSPORTES LTDA EPP"
    },
    {
        "id": "03872250000290",
        "value": "LINSKY TRANSPORTES E TURISMO"
    },
    {
        "id": "13468183000205",
        "value": "LIGIA DE CASSIA DIAS ENTREGAS RAPIDAS ME"
    },
    {
        "id": "12283010000322",
        "value": "LIDERANCA TRANSPORTES E LOGISTICA LTDA ME"
    },
    {
        "id": "11807198000136",
        "value": "LFGLOG"
    },
    {
        "id": "02259840000360",
        "value": "LEOFRAN TRANSPORTES LTDA"
    },
    {
        "id": "11115632000206",
        "value": "LEMA REZENDE TRANSPORTES LTDA"
    },
    {
        "id": "07985812000182",
        "value": "LEM TRANSPORTES E DISTRIBUICAO DE CARGAS LTDA"
    },
    {
        "id": "00522610000236",
        "value": "LEIVA TUR"
    },
    {
        "id": "07020236000643",
        "value": "LEITE EXPRESS TRANSPORTES"
    },
    {
        "id": "02725917000260",
        "value": "LEANDRO TRANSPS E ENCOMENDAS DE CARGAS LTDA EPP"
    },
    {
        "id": "11011480000293",
        "value": "LEANDRO TRANSPORTES"
    },
    {
        "id": "09411448000253",
        "value": "LDB TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "44349199000133",
        "value": "LAURIMAR TRANSPORTES GERAIS LTDA"
    },
    {
        "id": "02513099000150",
        "value": "LATORRE TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "02012862000160",
        "value": "LATAM AIRLINES BRASIL"
    },
    {
        "id": "02012862002294",
        "value": "LATAM AIRLINES BRASIL"
    },
    {
        "id": "00525563000284",
        "value": "LASTRO TRANSPORTES LTDA"
    },
    {
        "id": "10450801000102",
        "value": "LAN EXPRESS"
    },
    {
        "id": "06203406000158",
        "value": "LAG EXPRESS"
    },
    {
        "id": "05879924000204",
        "value": "LA PALOMA TRANSPORTES LTDA"
    },
    {
        "id": "17832853000192",
        "value": "L.R.N TRANSPORTES"
    },
    {
        "id": "58636739000255",
        "value": "L.L.A. LIOMAR OPERADOR LOGISTICO E TRANSPORTES LTDA -EPP"
    },
    {
        "id": "06293958000285",
        "value": "L VENTURA TRANSPORTES LTDA"
    },
    {
        "id": "10302375000321",
        "value": "L V DE OLIVEIRA TRANSPORTES ME"
    },
    {
        "id": "10834852000219",
        "value": "L M TRANSPORTES E LOGISTICA LTDA ME"
    },
    {
        "id": "10911777000242",
        "value": "L & N AMARAL TRANSPORTES LTDA"
    },
    {
        "id": "04929826000190",
        "value": "L & C LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "29576292000278",
        "value": "KRG TRANSPORTES LOGISTICA E DISTRIBUICAO"
    },
    {
        "id": "05597965000127",
        "value": "KR TRANSPORTES E LOGISTICA LTDA."
    },
    {
        "id": "03011765000204",
        "value": "KM TRANSPORTES RODOVIARIOS DE CARGAS LTDA"
    },
    {
        "id": "08397742000295",
        "value": "KM TRANSPORTES LTDA EPP"
    },
    {
        "id": "10524538000159",
        "value": "KM MULTIMODAL TRANSPORTADORA E LOGISTICA LTDA"
    },
    {
        "id": "24586325000166",
        "value": "KLNEXPRESS TRANSPORTES RODOVIARIOS EIRELI"
    },
    {
        "id": "25330908000275",
        "value": "KLNCE EXPRESS TRANSPORTES EIRELI"
    },
    {
        "id": "08989198000270",
        "value": "KLIN LOGISTICA EIRELI"
    },
    {
        "id": "34844868000190",
        "value": "KLEBOWSKI EXPRESS"
    },
    {
        "id": "16596455000151",
        "value": "KHAN EXPRESS LOGISTICA EIRELI"
    },
    {
        "id": "17279960000135",
        "value": "KGB TRANSPORTES EXPRESSOS LTDA ME"
    },
    {
        "id": "03225625000140",
        "value": "KENYA S/A TRANSPORTE E LOGISTICA"
    },
    {
        "id": "14410508000334",
        "value": "KARISA TRANSPORTES RODOFLUVIAL"
    },
    {
        "id": "21456829000173",
        "value": "KADOSHI TRANSPORTES"
    },
    {
        "id": "20288444000424",
        "value": "K D L & CIA LTDA ME (F1 TRANSPORTES)"
    },
    {
        "id": "07367581000143",
        "value": "JURUA LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "45356396000142",
        "value": "JUNEX TRANSPORTES"
    },
    {
        "id": "40839221000172",
        "value": "JTO TRANSPORTES"
    },
    {
        "id": "09120611000490",
        "value": "JS EXPRESSO E LOGISTICA LTDA"
    },
    {
        "id": "09491356000140",
        "value": "JRS DE PAULA TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "22604364000113",
        "value": "JR TRANSPORTES"
    },
    {
        "id": "04731862000145",
        "value": "JR SEVERO TRANSPORTES LTDA ME"
    },
    {
        "id": "10717454000131",
        "value": "JOSE LUCIANO BESERRA ARAUJO TRANSPORTE"
    },
    {
        "id": "19343827000126",
        "value": "JOSE EDJANIO FREITAS DO NASCIMENTO"
    },
    {
        "id": "14027977000298",
        "value": "JOSE COSTA DE SOUZA JUNIOR"
    },
    {
        "id": "04805994000174",
        "value": "JORTEK EXPRESS"
    },
    {
        "id": "60319985000144",
        "value": "JOMED TRANSPORTES E LOGISTICA EIRELI"
    },
    {
        "id": "22060628000115",
        "value": "JOLUCARFE AUTO PECAS LTDA"
    },
    {
        "id": "29302477000375",
        "value": "JOLEEMIX TRANSPORTADORA LTDA"
    },
    {
        "id": "38456294000214",
        "value": "JOLEEMAX TRANSPORTADORA LTDA"
    },
    {
        "id": "10517853000230",
        "value": "JOLEELOG TRANSPORTES LTDA"
    },
    {
        "id": "18469384000151",
        "value": "JOELITO ALVES DOS SANTOS"
    },
    {
        "id": "08974293000110",
        "value": "JOEL SALMISTRARO - TRANSPORTES"
    },
    {
        "id": "20416098000250",
        "value": "JM TRANSPORTES"
    },
    {
        "id": "69083244000181",
        "value": "JL FERREIRA TRANSPORTES"
    },
    {
        "id": "23361265000200",
        "value": "JK TRANSBARCELLOS"
    },
    {
        "id": "08696588000171",
        "value": "JJ TRANSPORTES DE CARGAS LTDA ME"
    },
    {
        "id": "19386361000146",
        "value": "JIREH TRANSPORTES"
    },
    {
        "id": "41621896000103",
        "value": "JHX EXPRESS"
    },
    {
        "id": "09152676000175",
        "value": "JFAUSTER REPRESENTACAO COMERCIAL LTDA ME"
    },
    {
        "id": "10218054000181",
        "value": "JF ADMINISTRADORA DE SERVICOS LTDA ME"
    },
    {
        "id": "03900533000118",
        "value": "JET - EXPRESS"
    },
    {
        "id": "15001850000181",
        "value": "JEOMAR"
    },
    {
        "id": "15001850000343",
        "value": "JEOMAR"
    },
    {
        "id": "04964686000272",
        "value": "JEFTE TRANSPORTADORA LTDA"
    },
    {
        "id": "22988856000150",
        "value": "JEB DE SOUZA TRANSPORTES ME"
    },
    {
        "id": "07732827000210",
        "value": "JCV TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "05079973000181",
        "value": "JC TRANSFORT LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "15519340000281",
        "value": "JB TURISMO"
    },
    {
        "id": "17805062000173",
        "value": "JATOLOG TRANSPORTES"
    },
    {
        "id": "39124797000174",
        "value": "JATEX TRANSPORTES LTDA"
    },
    {
        "id": "13787682000257",
        "value": "JANAUBA TRANSPORTE & LOGISTICA LTDA."
    },
    {
        "id": "20147617002276",
        "value": "JAMEF ENCOMENDAS URGENTES"
    },
    {
        "id": "02365289000178",
        "value": "JAGUARE TRANSPORTE"
    },
    {
        "id": "06036129000217",
        "value": "JACOBINA TRANSPORTES"
    },
    {
        "id": "10747661000139",
        "value": "J.O.S EXPRESS LOG TRANSPORTES LTDA"
    },
    {
        "id": "25866971000220",
        "value": "J.E. MEDES LTDA"
    },
    {
        "id": "19831250000100",
        "value": "J.D. TRANSPORTES"
    },
    {
        "id": "11885568000153",
        "value": "J. E. S PRESTACAO DE SERVICOS"
    },
    {
        "id": "06536610000271",
        "value": "J W A TRANSPORTES DE CARGAS EIRELI ME"
    },
    {
        "id": "11032114000210",
        "value": "J SERVICE"
    },
    {
        "id": "09307394000108",
        "value": "J M TRANSPORTES E LOGISTICA MATAO LTDA"
    },
    {
        "id": "02058135000303",
        "value": "J L LAGUNA TRANSPORTES LTDA"
    },
    {
        "id": "82406679000269",
        "value": "J J 2000 TRANSPORTES - EIRELI"
    },
    {
        "id": "10545213000152",
        "value": "J D B LOGISTICA & TRANSPORTADORA LTDA ME"
    },
    {
        "id": "08604074000149",
        "value": "IW TRANSPORTES"
    },
    {
        "id": "04156577000148",
        "value": "IURY GUARU TRANSPORTES LTDA"
    },
    {
        "id": "05397031000656",
        "value": "ITAMARATI EXPRESS TRANSPORTE DE CARGAS E ENCOMENDAS"
    },
    {
        "id": "01445448000262",
        "value": "ITALIA TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "69907541000285",
        "value": "ITAGUARY AGENCIAMENTO E TRANSPORTE DE CARGAS LTDA"
    },
    {
        "id": "11163585000268",
        "value": "ITABAIANA LOGISTICA E TRANSPORTES LTDA ME"
    },
    {
        "id": "35239463000195",
        "value": "IRIMAR TRANSPORTES EIRELI"
    },
    {
        "id": "14419997000123",
        "value": "INTRACARGO TRANSPORTES LTDA"
    },
    {
        "id": "10308645000492",
        "value": "INTERFORT LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "08133373000142",
        "value": "INTEGRACAO TRANSPORTES LTDA"
    },
    {
        "id": "04989756000751",
        "value": "INOVAR"
    },
    {
        "id": "23807028000130",
        "value": "IJD TRANSPORTES"
    },
    {
        "id": "15653368000126",
        "value": "IGUACU LOG TRANSPORTES"
    },
    {
        "id": "18875053000111",
        "value": "IDEIA DE MINAS TRANSPORTES"
    },
    {
        "id": "31716355000105",
        "value": "IDEALIZA TRANSPORTES EIRELI"
    },
    {
        "id": "03558055000886",
        "value": "IBL LOGISTICA"
    },
    {
        "id": "33745192000203",
        "value": "I.E TRANSPORTES EIRELI"
    },
    {
        "id": "17927431000281",
        "value": "HORUS TRANSPORTES"
    },
    {
        "id": "05101509000144",
        "value": "HORIUN TRANSPORTES LTDA"
    },
    {
        "id": "34697256000383",
        "value": "HOFFLOG TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "07130717000369",
        "value": "HJ PADUA TRANSPORTES LTDA - EPP"
    },
    {
        "id": "45133709000101",
        "value": "HERVI"
    },
    {
        "id": "22801212000100",
        "value": "HERDEIROS TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "03686726000207",
        "value": "HELTRAN TRANSPORTES LTDA"
    },
    {
        "id": "28749154000108",
        "value": "HELIO RAMOS TRANSPORTES DE CARGAS EM GERAL ME"
    },
    {
        "id": "09645580000149",
        "value": "HELENA BARBOSA DE OLIVEIRA SILVERIO"
    },
    {
        "id": "56215882000167",
        "value": "HEGLO TRANSPORTES LTDA"
    },
    {
        "id": "92528538000434",
        "value": "HB TRANSPORTES E LOGISTICA EIRELI"
    },
    {
        "id": "03541723000196",
        "value": "H.J. ENTREGAS RAPIDAS"
    },
    {
        "id": "05012090000154",
        "value": "H.G. SANTOS TRANSPORTES EIRELI"
    },
    {
        "id": "11040609000372",
        "value": "H. D. LOG TRANSPORTES"
    },
    {
        "id": "01747038000438",
        "value": "H&R DA AMAZONIA"
    },
    {
        "id": "08058379000283",
        "value": "H E TRANSPORTES"
    },
    {
        "id": "09592157000209",
        "value": "GVG TRANSPORTES"
    },
    {
        "id": "12412994000647",
        "value": "GUAIRA CATARINENSE"
    },
    {
        "id": "21730208000136",
        "value": "GUA-DESTE TRANSPORTE E LOGISTICA EIRELI - ME"
    },
    {
        "id": "09108658000196",
        "value": "GTE GOMES TRANSPORTES E ENCOMENDAS LTDA EPP"
    },
    {
        "id": "11758701000100",
        "value": "GRUPO DAGO"
    },
    {
        "id": "37347843000250",
        "value": "GRUPO BRASIL TRANSPORTES"
    },
    {
        "id": "19733186000116",
        "value": "GRU TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "27073936000107",
        "value": "GRT TRANSPORTE RODOVIARIO LTDA"
    },
    {
        "id": "25406384000178",
        "value": "GRIFE TRANSPORTES LTDA."
    },
    {
        "id": "14309089000187",
        "value": "GRIDLOG TRANSPORTES EIRELI"
    },
    {
        "id": "04507739000145",
        "value": "GRANPORT MULTIMODAL LTDA - EM RECUPERACAO JUDICIAL"
    },
    {
        "id": "23341445000130",
        "value": "GRANITENSE"
    },
    {
        "id": "25786831000242",
        "value": "GRANCUCAR TRANSPORTES LTDA"
    },
    {
        "id": "05915569000109",
        "value": "GRAN CARGO TRANSPORTES"
    },
    {
        "id": "06186728000136",
        "value": "GRAF TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "09687053000105",
        "value": "GR CRUZ TRANSPORTES LTDA"
    },
    {
        "id": "06701081000214",
        "value": "GOLD MILENIO"
    },
    {
        "id": "07858696000300",
        "value": "GOLD BRASIL LOGISTICA - EIRELI"
    },
    {
        "id": "04020028002276",
        "value": "GOL TRANSPORTES AEREOS SA"
    },
    {
        "id": "00945958000155",
        "value": "GLOBAL AIR CARGO"
    },
    {
        "id": "53579892000193",
        "value": "GIROTTO TRANSPORTADORA & LOGISTICA LTDA ME"
    },
    {
        "id": "03083948000227",
        "value": "GIRO CERTO CARGAS E ENCOMENDAS"
    },
    {
        "id": "01708878000220",
        "value": "GIBI TRANSPORTES LTDA"
    },
    {
        "id": "12483717000110",
        "value": "GETAL CARGO"
    },
    {
        "id": "11766759000287",
        "value": "GERALOG TRANSPORTES LTDA"
    },
    {
        "id": "18493350000283",
        "value": "GD LOGISTICA"
    },
    {
        "id": "06207305000236",
        "value": "GB VIEIRA BASTOS TRANSPORTES LTDA"
    },
    {
        "id": "10862012000260",
        "value": "GB TRANSPORTES"
    },
    {
        "id": "02865298000209",
        "value": "GAUMINAS TRANSPORTES"
    },
    {
        "id": "04667964000149",
        "value": "GARRA"
    },
    {
        "id": "21384314000105",
        "value": "GARCIA DE CARVALHO TRANSPORTES LTDA"
    },
    {
        "id": "19011664000265",
        "value": "GAMA'S TRANSPORTE, SERVICO E LOGISTICA LTDA EPP"
    },
    {
        "id": "14395943000175",
        "value": "G.T. GUIDER TRANSPORTES LTDA"
    },
    {
        "id": "09592157000128",
        "value": "G V G TRANSPORTES E LOGISTICA - EIRELI"
    },
    {
        "id": "12599793000196",
        "value": "G N Y"
    },
    {
        "id": "00835212000350",
        "value": "G N TRANSPORTE RODOVIARIO DE CARGAS FERNANDOPOLIS LTDA E"
    },
    {
        "id": "13178768000282",
        "value": "FSR TRANSPORTE LTDA"
    },
    {
        "id": "10989954000122",
        "value": "FRS TRANSPORTE"
    },
    {
        "id": "06979577000173",
        "value": "FRILOG"
    },
    {
        "id": "11863254000150",
        "value": "FRENTE TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "13939803000158",
        "value": "FRANCISCO LUCIVAN ALVES FERREIRA ME"
    },
    {
        "id": "08738235000278",
        "value": "FRANCISCO DONIZETI PENHA DA SILVA"
    },
    {
        "id": "01818827000250",
        "value": "FRANCAL TRANSPORTE DE CARGAS LTDA"
    },
    {
        "id": "02597900000274",
        "value": "FRANCA EXPRESS TRANSPORTES E ARMAZENAGEM DE PRODUTOS EM GERAL LTDA"
    },
    {
        "id": "11192659000294",
        "value": "FR RAGAZZI TRANSPORTES - EIRELI"
    },
    {
        "id": "11192659000103",
        "value": "FR RAGAZZI TRANSPORTES - EIRELI"
    },
    {
        "id": "04927218000147",
        "value": "FORMULA 1 LOGISTICA E TRANSPORTES LTDA EPP"
    },
    {
        "id": "13727240000134",
        "value": "FORMATO TRANSPORTES"
    },
    {
        "id": "08427008000221",
        "value": "FONTANELLA LOGISTICA & TRANSPORTES LTDA"
    },
    {
        "id": "28645839000104",
        "value": "FOCO TRANSPORTES"
    },
    {
        "id": "69309706000217",
        "value": "FNA TRANSPORTES LTDA"
    },
    {
        "id": "17414684000170",
        "value": "FLOT TRANSPORTES EXPRESS LTDA"
    },
    {
        "id": "21934388000259",
        "value": "FLEXLOG TRANSPORTES LTDA"
    },
    {
        "id": "42558304000542",
        "value": "FLEXA DE OURO TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "01695633000205",
        "value": "FLECHA AZUL KEILANDO TRANSPORTES LTDA"
    },
    {
        "id": "88906857000116",
        "value": "Flavio Donizete"
    },
    {
        "id": "26885138000109",
        "value": "FISE LOG TRANSPORTES INTEGRADOS LTDA EPP"
    },
    {
        "id": "08098211000110",
        "value": "FINTRANS TRANSPORTES LTDA"
    },
    {
        "id": "12354460007",
        "value": "FINANCEIRO IMPORTS TRANSPORTES"
    },
    {
        "id": "02897982000191",
        "value": "FERCALON TRANSPORTES ESPECIALIZADOS LTDA."
    },
    {
        "id": "10970887000285",
        "value": "FEDEX BRASIL LOGISTICA E TRANSPORTE LTDA"
    },
    {
        "id": "27905992000152",
        "value": "FDB COSTA TRANSPORTES EIRELI"
    },
    {
        "id": "01743404000138",
        "value": "FAVORITA TRANSPORTES LTDA"
    },
    {
        "id": "11586546000273",
        "value": "FAMA TRANSPORTES"
    },
    {
        "id": "11586546000192",
        "value": "FAMA TRANSPORTES"
    },
    {
        "id": "05805337000270",
        "value": "F.P. TRANSPORTES"
    },
    {
        "id": "50963339000242",
        "value": "EXPRESSO VILARENSE DE JUNDIAI LTDA EPP"
    },
    {
        "id": "02066800000211",
        "value": "EXPRESSO VILA VELHA LOGISTICA LTDA"
    },
    {
        "id": "08952739000296",
        "value": "EXPRESSO VIDA TRANSPORTES RODOVIARIOS LTDA EPP"
    },
    {
        "id": "24725764000293",
        "value": "EXPRESSO VERA CRUZ LTDA"
    },
    {
        "id": "09469718000104",
        "value": "EXPRESSO VALMAR LTDA ME"
    },
    {
        "id": "04529941000259",
        "value": "EXPRESSO UNIAO"
    },
    {
        "id": "05264805000166",
        "value": "EXPRESSO TUBIENSE LTDA"
    },
    {
        "id": "48300099000254",
        "value": "EXPRESSO TRANSPORTE JORDANENSE LTDA"
    },
    {
        "id": "04783467000297",
        "value": "EXPRESSO TRANSBRISA LTDA"
    },
    {
        "id": "06948630000179",
        "value": "EXPRESSO THIVAL"
    },
    {
        "id": "12331372000180",
        "value": "EXPRESSO SOFIA"
    },
    {
        "id": "00428307001917",
        "value": "EXPRESSO SAO MIGUEL LTDA"
    },
    {
        "id": "00428307000600",
        "value": "EXPRESSO SAO MIGUEL LTDA"
    },
    {
        "id": "01543354002270",
        "value": "EXPRESSO SAO LUIZ DE GOIAS"
    },
    {
        "id": "43500198000347",
        "value": "EXPRESSO SANTA LUZIA LTDA"
    },
    {
        "id": "52046422000100",
        "value": "EXPRESSO SANTA CRUZ INTERIOR"
    },
    {
        "id": "07353826000264",
        "value": "EXPRESSO SANTA CLAUDIA"
    },
    {
        "id": "59965152000171",
        "value": "EXPRESSO SALOME"
    },
    {
        "id": "10552640000249",
        "value": "EXPRESSO ROMANELI E CAETANO LTDA"
    },
    {
        "id": "47585377000385",
        "value": "EXPRESSO RODOVIARIO REGE LTDA."
    },
    {
        "id": "04799398000471",
        "value": "EXPRESSO RODOMINAS"
    },
    {
        "id": "50386473000228",
        "value": "EXPRESSO RODO JABOTI LTDA"
    },
    {
        "id": "07146353000305",
        "value": "EXPRESSO ROCHI TRANSPORTES LTDA"
    },
    {
        "id": "07655407000287",
        "value": "EXPRESSO RIO VERMELHO TRANSPORTES LTDA"
    },
    {
        "id": "47585377000113",
        "value": "EXPRESSO REGE"
    },
    {
        "id": "04113171000269",
        "value": "EXPRESSO RADAR LTDA"
    },
    {
        "id": "11442203000154",
        "value": "EXPRESSO R & R TRANSPORTE LTDA"
    },
    {
        "id": "80227796001473",
        "value": "EXPRESSO PRINCESA DOS CAMPOS SA"
    },
    {
        "id": "29863420000345",
        "value": "EXPRESSO PREDILETO"
    },
    {
        "id": "11495757000110",
        "value": "EXPRESSO PORTO NOVO"
    },
    {
        "id": "08634040000369",
        "value": "EXPRESSO PONTUAL"
    },
    {
        "id": "03638709000105",
        "value": "EXPRESSO PARA DE MINAS"
    },
    {
        "id": "76533777004090",
        "value": "EXPRESSO NORDESTE"
    },
    {
        "id": "76533777000183",
        "value": "EXPRESSO NORDESTE"
    },
    {
        "id": "19368927002150",
        "value": "EXPRESSO NEPOMUCENO S/A"
    },
    {
        "id": "03392405000100",
        "value": "EXPRESSO NAVEGANTES FABIANA ROSA DE SA M.E"
    },
    {
        "id": "05514580000159",
        "value": "EXPRESSO MONTCAR 2003 LTDA"
    },
    {
        "id": "66341876000100",
        "value": "EXPRESSO MONTANHES"
    },
    {
        "id": "04882329000265",
        "value": "EXPRESSO MONTANHA CARGAS LTDA"
    },
    {
        "id": "16644083000272",
        "value": "EXPRESSO MILLES TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "78384674000124",
        "value": "EXPRESSO MARINGA TRANSPORTES LTDA"
    },
    {
        "id": "26341222000242",
        "value": "EXPRESSO M-2000 LTDA"
    },
    {
        "id": "11730651000153",
        "value": "EXPRESSO LUDEX"
    },
    {
        "id": "02633583000890",
        "value": "EXPRESSO LEOMAR"
    },
    {
        "id": "21228754000416",
        "value": "EXPRESSO LAMOUNIER EIRELI"
    },
    {
        "id": "61239968000160",
        "value": "EXPRESSO KIMAR LTDA"
    },
    {
        "id": "50935436002780",
        "value": "EXPRESSO JUNDIAI LOGISTICA E TRANSPORTE LTDA"
    },
    {
        "id": "09589155000180",
        "value": "EXPRESSO JC"
    },
    {
        "id": "88610266000520",
        "value": "EXPRESSO JAVALI S.A"
    },
    {
        "id": "18511800000132",
        "value": "EXPRESSO ITAGUAI EIRELI"
    },
    {
        "id": "10213679000312",
        "value": "EXPRESSO IDEAL, CARGAS, ENCOMENDAS E LOGISTICA LTDA"
    },
    {
        "id": "13987573000526",
        "value": "EXPRESSO GUANAMBI LTDA"
    },
    {
        "id": "49914641000655",
        "value": "EXPRESSO GARDENIA LTDA"
    },
    {
        "id": "01527825000202",
        "value": "EXPRESSO FLOR DE MINAS LTDA"
    },
    {
        "id": "08896014000228",
        "value": "EXPRESSO E LOGISTICA K N"
    },
    {
        "id": "09028123000296",
        "value": "EXPRESSO DOURADOS TRANSPORTES ROD DE CARGAS LTDA"
    },
    {
        "id": "02701609000113",
        "value": "EXPRESSO DESCALVADO"
    },
    {
        "id": "22313159000360",
        "value": "EXPRESSO DALVA EIRELI"
    },
    {
        "id": "89405336001381",
        "value": "EXPRESSO CONVENTOS LTDA"
    },
    {
        "id": "10646882000110",
        "value": "EXPRESSO CENTRO OESTE MINEIRO EIRELI"
    },
    {
        "id": "27721174000361",
        "value": "EXPRESSO CANTAGALO LTDA"
    },
    {
        "id": "21623475000104",
        "value": "EXPRESSO CAMILLO DOS SANTOS LTDA - EPP"
    },
    {
        "id": "07814918000202",
        "value": "EXPRESSO BR500"
    },
    {
        "id": "27278100000211",
        "value": "EXPRESSO BOAS NOVAS LIMITADA"
    },
    {
        "id": "84058809000245",
        "value": "EXPRESSO BOA VISTA LTDA"
    },
    {
        "id": "26042876000194",
        "value": "EXPRESSO AVILA"
    },
    {
        "id": "03965917000119",
        "value": "EXPRESSO ANDRADINA LTDA"
    },
    {
        "id": "21392903000135",
        "value": "EXPRESSO 45 TRANSPORTES EIRELI"
    },
    {
        "id": "06249629000237",
        "value": "EXPRESSO 1000TINHO TRANSPORTES LTDA"
    },
    {
        "id": "46411021000109",
        "value": "EXPRESSINHO SANTISTA LTDA"
    },
    {
        "id": "13587123000384",
        "value": "EXPRESSAO VLT TRANSPORTES"
    },
    {
        "id": "01834475000227",
        "value": "EXPRESS TCM LTDA"
    },
    {
        "id": "01834475000146",
        "value": "EXPRESS TCM"
    },
    {
        "id": "14666995000139",
        "value": "EXPRESS CARGA"
    },
    {
        "id": "01163254000193",
        "value": "EXPIR TRANSPORTES"
    },
    {
        "id": "27545023000138",
        "value": "EXPEDITION TRANSLOG"
    },
    {
        "id": "41038695000188",
        "value": "EXCLUSIVE CARGAS E ENCOMENDAS"
    },
    {
        "id": "07320363000234",
        "value": "EXCLUSIVA TRANSPORTES LTDA"
    },
    {
        "id": "02942192000695",
        "value": "EXCELLENCE TRANSPORTES"
    },
    {
        "id": "06186733000220",
        "value": "EXATA CARGO"
    },
    {
        "id": "07632502000265",
        "value": "EVIDENCIA LOGISTICA TRANSPORTES LTDA"
    },
    {
        "id": "76080738001654",
        "value": "EUCATUR"
    },
    {
        "id": "02023485000164",
        "value": "ESTRELA DOURADA TRANSPORTES"
    },
    {
        "id": "06049122000230",
        "value": "ESTRELA DOURADA TRANSPORTES"
    },
    {
        "id": "24683087000298",
        "value": "ESTRELA DE DAVI LOGISTICA"
    },
    {
        "id": "02617709000247",
        "value": "EPST"
    },
    {
        "id": "07686248000105",
        "value": "ENTREGADORA WESTRUP EIRELI ME"
    },
    {
        "id": "16041592004702",
        "value": "EMTRAM EMPRESA DE TRANSPORTES MACAUBENSE LTDA."
    },
    {
        "id": "44993632000179",
        "value": "EMPRESAS REUNIDAS PAULISTA DE TRANSPORTES LTDA"
    },
    {
        "id": "21566120003812",
        "value": "EMPRESA UNIDA MANSUR & FILHOS LTDA"
    },
    {
        "id": "45467636000267",
        "value": "EMPRESA RODOVIARIA SCALET"
    },
    {
        "id": "44207223000280",
        "value": "EMPRESA DE TRANSPORTES SOPRO DIVINO S/A EM RECUPERACAO JUDICIAL"
    },
    {
        "id": "25858721000249",
        "value": "EMPRESA DE TRANSPORTES SANTA TEREZINHA LTDA"
    },
    {
        "id": "53237962000125",
        "value": "EMPRESA DE TRANSPORTES PAJUCARA LTDA"
    },
    {
        "id": "21565569000255",
        "value": "EMPRESA DE TRANSPORTES OLIVEIROS FILIAL SAO PAULO"
    },
    {
        "id": "17191172000435",
        "value": "EMPRESA DE TRANSPORTES MARTINS LTDA"
    },
    {
        "id": "60173424000260",
        "value": "EMPRESA DE TRANSPORTES ITAICI LTDA"
    },
    {
        "id": "14121859000244",
        "value": "EMPRESA DE TRANSPORTES CABANOS LTDA"
    },
    {
        "id": "02150579000285",
        "value": "EMPRESA DE TRANSPORTES ARALDI LTDA"
    },
    {
        "id": "06367990001744",
        "value": "EMPRESA DE ONIBUS NOSSA SENHORA DA PENHA S/S"
    },
    {
        "id": "44581056000152",
        "value": "EMPRESA AUTO ONIBUS MANOEL RODRIGUES SA"
    },
    {
        "id": "02388417000360",
        "value": "ELETROLAR TRANSPORTES"
    },
    {
        "id": "10793139000193",
        "value": "ELADIO VIEIRA MARANHAO ME"
    },
    {
        "id": "22174313000107",
        "value": "EGNALDO ANTONIO DA SILVEIRA ME"
    },
    {
        "id": "00650831000290",
        "value": "EFITRANS TRANSPORTES LTDA"
    },
    {
        "id": "03642171000374",
        "value": "EFICACIA TRANSPORTES LTDA"
    },
    {
        "id": "00030112000195",
        "value": "EDVALDO FERREIRA CALADO TRANSPORTES"
    },
    {
        "id": "37556691000114",
        "value": "EDL LOGISTICA, TRANSPORTE E DISTRIBUICAO"
    },
    {
        "id": "07831796000254",
        "value": "EDITAL CARGA EXPRESS LTDA"
    },
    {
        "id": "13205953000219",
        "value": "EDEMIR OLIVEIRA CRUZ TRANSPORTES ME"
    },
    {
        "id": "04362137000228",
        "value": "EBTRANS - EXPRESSO BRASILEIRO DE TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "08394216000271",
        "value": "E.T.R. TRANSPORTES"
    },
    {
        "id": "05011394000105",
        "value": "E. MANSSINI"
    },
    {
        "id": "02773789000149",
        "value": "E S T TRANSPORTES LTDA ME"
    },
    {
        "id": "44010320000106",
        "value": "E MARTINS DOS SANTOS TRANSPORTES"
    },
    {
        "id": "11482515000272",
        "value": "E C DE ASSIS TRANSPORTES"
    },
    {
        "id": "20856135000160",
        "value": "DVAL TRANSPORTES"
    },
    {
        "id": "04175312000197",
        "value": "DUARTE EXPRESS LTDA"
    },
    {
        "id": "29709924000143",
        "value": "DU NONDAS TRANSPORTES E LOGISTICA"
    },
    {
        "id": "08706965000106",
        "value": "DSRVLOX LOGISTICA E DISTRIBUICAO LTDA"
    },
    {
        "id": "06276611000224",
        "value": "DRACOLN LOGISTICA E TRANSPORTES EIRELI"
    },
    {
        "id": "41880937000186",
        "value": "DOC LYNX TRANSPORTES"
    },
    {
        "id": "11970436000200",
        "value": "DMARI LOGISTICA"
    },
    {
        "id": "29242951000159",
        "value": "DL3 LOG"
    },
    {
        "id": "08959761000420",
        "value": "DJ - TRANSPORTES"
    },
    {
        "id": "86430675000277",
        "value": "DISTRIBUIDORA E TRANSPORTADORA KINDERMANN LTDA"
    },
    {
        "id": "03592033000247",
        "value": "DISTRIBUI LOGISTICA LTDA"
    },
    {
        "id": "08785692000213",
        "value": "DIRETAO SAO PAULO"
    },
    {
        "id": "08219203001157",
        "value": "DIRECIONAL"
    },
    {
        "id": "17142262000274",
        "value": "DIAS LEMES & LEME LTDA ME"
    },
    {
        "id": "13220164000176",
        "value": "DHV TRANSPORTES LOGISTICA LTDA"
    },
    {
        "id": "17084438000106",
        "value": "DFR TRANSPORTES"
    },
    {
        "id": "09813215000287",
        "value": "DESTAQUE LOGISTICA"
    },
    {
        "id": "05813363000240",
        "value": "DESTAK TRANSPORTES"
    },
    {
        "id": "55411706000219",
        "value": "DELTA BRASIL TRANSPORTES LTDA"
    },
    {
        "id": "07725522000280",
        "value": "DE PAULA LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "00574570000195",
        "value": "DE LAZARI AGENCIA DE CARGA"
    },
    {
        "id": "27892201000105",
        "value": "DDC LOGISTICA"
    },
    {
        "id": "14995536000107",
        "value": "DDC LOGISTICA"
    },
    {
        "id": "35329885000232",
        "value": "DATALOG TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "05993655000202",
        "value": "DATA TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "05993655000393",
        "value": "DATA TRANSPORTES"
    },
    {
        "id": "49512916000110",
        "value": "DANUBIO AZUL TRANSPORTES DE CARGAS E ENCOMENDAS LTDA"
    },
    {
        "id": "00657921000393",
        "value": "DANI TRANSPORTE RODOVIARIO DE CARGAS EIRELI"
    },
    {
        "id": "15510578000165",
        "value": "DAJEX TRANSPORTES LTDA"
    },
    {
        "id": "07793602000192",
        "value": "D P DE NAZARE ME"
    },
    {
        "id": "22823827000480",
        "value": "D HORTENCIO LTDA"
    },
    {
        "id": "02226990000106",
        "value": "D G EXPRESS TRANSPORTES LTDA"
    },
    {
        "id": "15345103000160",
        "value": "CYGNUOS TRANSPORTES"
    },
    {
        "id": "29261089000121",
        "value": "CWLOG TRANSPORTES LTDA"
    },
    {
        "id": "07659578000101",
        "value": "CUIABA EXPRESS TRANSPORTES LTDA ME"
    },
    {
        "id": "11888661000111",
        "value": "CTR CONFIANCA TRANSPORTES RODOVIARIOS LTDA EPP"
    },
    {
        "id": "05891200000286",
        "value": "CRISTALLI INDUSTRIA E COMERCIO LTDA"
    },
    {
        "id": "69248854000198",
        "value": "CRISTALINA TRANSPORTES LTDA"
    },
    {
        "id": "10237429000231",
        "value": "CRISTAL TRANSPORTES"
    },
    {
        "id": "03006819000316",
        "value": "CR TRANSPORTE DE CARGAS LTDA"
    },
    {
        "id": "48060297000107",
        "value": "COSTEIRA TRANSPORTES E SERVICOS LTDA"
    },
    {
        "id": "02503314000728",
        "value": "COSER LOCACOES LTDA"
    },
    {
        "id": "34028316003129",
        "value": "CORREIOS"
    },
    {
        "id": "34028316000103",
        "value": "Correios"
    },
    {
        "id": "12131529000123",
        "value": "CORREIAO ACESSORIOS"
    },
    {
        "id": "39246046000201",
        "value": "COOPERLAGOS COOP DOS TRANSP ROD DE CARGAS LTDA"
    },
    {
        "id": "06011531000166",
        "value": "COOPERCAB"
    },
    {
        "id": "02898012000291",
        "value": "COOPERATIVA DE TRANSPORTADORES AUTON DE BARRA BONITA"
    },
    {
        "id": "06018722000230",
        "value": "CONFIANCA MACAE TRANSPORTADORA LTDA"
    },
    {
        "id": "03119616000255",
        "value": "CONEXAO-BAHIA TRANSPORTES DE CARGA LTDA"
    },
    {
        "id": "03493973000106",
        "value": "CONEXAO BRASIL SERVICOS E TRANSPORTES LTDA EPP"
    },
    {
        "id": "13175897000217",
        "value": "CONECT CARGAS ITAUNA LTDA"
    },
    {
        "id": "01166686000158",
        "value": "CONDEX EXPRESS LTDA"
    },
    {
        "id": "17791834000165",
        "value": "COMERCIAL RCC TRANSPORTES LTDA"
    },
    {
        "id": "04271846000117",
        "value": "COMAM TRANSPORTES"
    },
    {
        "id": "36457969000214",
        "value": "COLLEN TRANSPORTES"
    },
    {
        "id": "11612960000129",
        "value": "CLICK MAXLOG"
    },
    {
        "id": "14288580000250",
        "value": "CLAUDIA JOSSELY SOUZA DA CAMARA EPP"
    },
    {
        "id": "06989879000122",
        "value": "CLAROS TRANSPORTES EIRELI"
    },
    {
        "id": "61923397000188",
        "value": "CIMCAL COMERCIO, SERVICOS E SOLUCOES LOGISTICAS LTDA - EM RECUPERACAO JUDICIAL"
    },
    {
        "id": "08630094000194",
        "value": "CIDADE EXPRESS"
    },
    {
        "id": "19315118018265",
        "value": "CIA SAO GERALDO DE VIACAO"
    },
    {
        "id": "73202640000824",
        "value": "CHTRANS TRANSPORTES RODOVIARIOS LTDA"
    },
    {
        "id": "08530368000254",
        "value": "CHJ TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "04580790000182",
        "value": "CHAPECO"
    },
    {
        "id": "58426156000119",
        "value": "CEU MAR TRANSPORTES LTDA"
    },
    {
        "id": "79808986000180",
        "value": "CETROL CENTRAL DE TRANSP E LOGISTICA LTDA"
    },
    {
        "id": "01010442000181",
        "value": "CESAR DINIZ TRANSPORTE"
    },
    {
        "id": "26312856000358",
        "value": "CERTA TRANSPORTES LOGISTICA E ARMAZENAGEM LTDA"
    },
    {
        "id": "55925416000293",
        "value": "CERQUILHOS TRANSPORTES LTDA"
    },
    {
        "id": "10510059000264",
        "value": "CERQUEIRA TRANSPORTADORA E LOGISTICA"
    },
    {
        "id": "04992256000183",
        "value": "CENTRAL DE CARGAS SAO JOSE"
    },
    {
        "id": "16981568000170",
        "value": "CELIA LOPES BEZERRA TRANSPORTES ME"
    },
    {
        "id": "07573195000108",
        "value": "CDC CARGAS E TRANSPORTES EIRELI"
    },
    {
        "id": "07797011000274",
        "value": "CAZAN TRANSPORTES LTDA ME"
    },
    {
        "id": "07689042000473",
        "value": "CAVALHEIRO LOGISTICS"
    },
    {
        "id": "04544189000568",
        "value": "CATTANI CARGAS LTDA"
    },
    {
        "id": "03390033000264",
        "value": "CATALANA DE NITEROI TRANSPORTES LTDA"
    },
    {
        "id": "02854953000304",
        "value": "CASTOR EXPRESSO LTDA"
    },
    {
        "id": "32668930000295",
        "value": "CASTLOG TRANPORTES (SP)"
    },
    {
        "id": "33070814000313",
        "value": "CARVALIMA TRANSPORTES LTDA"
    },
    {
        "id": "10742363000237",
        "value": "CARRION LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "10924370000179",
        "value": "CARREGUE RAPIDO TRANSPORTES LTDA"
    },
    {
        "id": "23206526000128",
        "value": "CARREGABRASIL TRANSPORTES E LOGISTICA LTDA EPP"
    },
    {
        "id": "13008025000345",
        "value": "CARNEIRO ALMEIDA TRANSPORTES DISTRIBUICAO E LOGISTICA EIRELI"
    },
    {
        "id": "00702501000462",
        "value": "CARGOCENTER"
    },
    {
        "id": "59267138000102",
        "value": "CARGO SHIP GLOBAL LOGISTICS"
    },
    {
        "id": "14038562000210",
        "value": "CARGO MASTER TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "08800030000194",
        "value": "CAPRY CARGO TRANSPS E LOGISTICA LTDA"
    },
    {
        "id": "04353981000101",
        "value": "CANTERO TRANSPORTES RODOVIARIO DE CARGAS LTDA"
    },
    {
        "id": "71790661000106",
        "value": "CANTARO"
    },
    {
        "id": "08706145000890",
        "value": "CAMPINENSE TRANSPORTE"
    },
    {
        "id": "10677967000166",
        "value": "CAMP AUTO CENTER"
    },
    {
        "id": "06974853000100",
        "value": "CAMILA CRISTINA MENDES"
    },
    {
        "id": "09122220000243",
        "value": "CAMCAM TRANSPORTES"
    },
    {
        "id": "08384362000135",
        "value": "CAMARGO TRANSPORTES E ENCOMENDAS LTDA ME"
    },
    {
        "id": "82173071000231",
        "value": "CAJUMAR TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "05543757000226",
        "value": "CAIAPO CARGAS"
    },
    {
        "id": "90394107001207",
        "value": "CADORE TRANSPORTES E COMERCIO LTDA"
    },
    {
        "id": "90394107000316",
        "value": "CADORE TRANSPORTES E COMERCIO LTDA"
    },
    {
        "id": "05821019000384",
        "value": "CABO VERDE TRANSPORTES LTDA"
    },
    {
        "id": "18587293000110",
        "value": "CABEN TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "21642529000189",
        "value": "C5 LOGISTICA"
    },
    {
        "id": "08285466000192",
        "value": "C5 LOGISTICA"
    },
    {
        "id": "42986169000293",
        "value": "C4 TRANSPORTE, LOGISTICA E ARMAZENAMENTO DE CARGAS LTDA"
    },
    {
        "id": "26186284000146",
        "value": "C. F. C. F. TRANSPORTES LTDA"
    },
    {
        "id": "02451161000208",
        "value": "C MALTA TRANSPORTES LTDA ME"
    },
    {
        "id": "00738833000235",
        "value": "C F B MORAIS REPRESENTACAO (TRANSROTA)"
    },
    {
        "id": "74602954000100",
        "value": "BY BUS ENTREGADORA E DISTRIBUIDORA"
    },
    {
        "id": "15183079000100",
        "value": "BWJ EXPRESS"
    },
    {
        "id": "08185296000174",
        "value": "BUSSOLA LOGISTICA LTDA"
    },
    {
        "id": "21815608000144",
        "value": "BUSCO LOG LOGISTICA LTDA."
    },
    {
        "id": "10624126000271",
        "value": "BUCOVIA TRANSPORTES LTDA"
    },
    {
        "id": "48740351000165",
        "value": "BRASPRESS TRANSPORTES URGENTES LTDA"
    },
    {
        "id": "48740351012414",
        "value": "BRASPRESS TRANSPORTES URGENTES"
    },
    {
        "id": "03944455000234",
        "value": "BRASILCARGO"
    },
    {
        "id": "05569742000156",
        "value": "BRASIL TRANSPORTES LTDA"
    },
    {
        "id": "05233521000960",
        "value": "BRASIL SUL LINHAS RODOVIARIAS LTDA."
    },
    {
        "id": "07223558000266",
        "value": "BRASIL POSTAL ENCOMENDAS, CARGAS E LOGISTICA LTDA"
    },
    {
        "id": "09653140000214",
        "value": "BRASIL CARGAS LTDA"
    },
    {
        "id": "00998573000156",
        "value": "BRASIL CAR TRANSPORTE DE VEICULOS & LOGISTICA LTDA"
    },
    {
        "id": "22311171000174",
        "value": "BRANQUINHO TRANSPORTES LTDA"
    },
    {
        "id": "01757541000202",
        "value": "BR LOG"
    },
    {
        "id": "06213530000285",
        "value": "BR EXPRESSO TRANSPORTE E LOGISTICA LTDA"
    },
    {
        "id": "16821901000265",
        "value": "BR BRASIL LOG"
    },
    {
        "id": "04537201000264",
        "value": "BOSS"
    },
    {
        "id": "14898432000257",
        "value": "BOM JESUS TRANSPORTES"
    },
    {
        "id": "05112286000110",
        "value": "BINHO TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "10711246000125",
        "value": "BILL TRANSPORTES"
    },
    {
        "id": "29190813000173",
        "value": "BIGTRANS"
    },
    {
        "id": "04121460000390",
        "value": "BHM TRANSPORTES EIRELI"
    },
    {
        "id": "00783518000149",
        "value": "BGL LOGISTICA E TRANSPORTE LTDA"
    },
    {
        "id": "14018050000100",
        "value": "BEMTRACK LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "08701819000350",
        "value": "BELA VISTA - J P TRANSPORTADORA LTDA"
    },
    {
        "id": "04353469004748",
        "value": "BAUER EXPRESS"
    },
    {
        "id": "04353469000327",
        "value": "BAUER CARGAS"
    },
    {
        "id": "04436683000185",
        "value": "BARONI TRANSPORTES"
    },
    {
        "id": "14169095000186",
        "value": "BANDEIRA EXPRESS"
    },
    {
        "id": "00776574000660",
        "value": "B2W Entrega Companhia Digital"
    },
    {
        "id": "02029345000284",
        "value": "B.V. TRANSPORTES"
    },
    {
        "id": "02992684000180",
        "value": "B.B. SERVICE CARGAS E ENCOMENDAS LTDA"
    },
    {
        "id": "07420856000165",
        "value": "B BRASIL TRANSPORTES, LOGISTICA E ARMAZENAGEM LTDA - EPP"
    },
    {
        "id": "40915038000109",
        "value": "AVILA TRANSPORTES"
    },
    {
        "id": "19862027000111",
        "value": "AUTOMOTIVE IMPORT'S COMERCIAL LTDA"
    },
    {
        "id": "48860540000171",
        "value": "AUTOCASE COMERCIO E DISTRIBUIDORA LTDA"
    },
    {
        "id": "82647884000135",
        "value": "AUTO VIACAO CATARINENSE LTDA"
    },
    {
        "id": "19339415000708",
        "value": "AUTO VIACAO CAMBUI LTDA"
    },
    {
        "id": "08848231000242",
        "value": "ATUAL CARGAS"
    },
    {
        "id": "28395663000252",
        "value": "ATLLOG"
    },
    {
        "id": "60664828000176",
        "value": "ATLAS - EMPRESA DE TRANSPORTES ALTAS LTDA"
    },
    {
        "id": "00736214000392",
        "value": "ATLANTICO CARGAS E ENCOMENDAS LTDA"
    },
    {
        "id": "11242837000244",
        "value": "ATLANTICO AIR CARGO"
    },
    {
        "id": "01334631000290",
        "value": "ATLANTA LOGISTICA E TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "01125797000701",
        "value": "ATIVA DISTRIBUICAO E LOGISTICA LTDA"
    },
    {
        "id": "12360920000108",
        "value": "ATF TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "43596607000199",
        "value": "AT TRANSPORTES & LOGISTICA LTDA"
    },
    {
        "id": "22382136000146",
        "value": "AT SERVICOS DE TRANSPORTES"
    },
    {
        "id": "03410332000132",
        "value": "ASR"
    },
    {
        "id": "03970763000153",
        "value": "ASES EXPRESS TRANSPORTES LTDA"
    },
    {
        "id": "15776984000174",
        "value": "ARSENAL CAR PECAS E ACESSORIOS LTDA"
    },
    {
        "id": "11595658000371",
        "value": "ARMAZEM LOG"
    },
    {
        "id": "04321149000123",
        "value": "ARMANDI TRANSPORTES LTDA (MANDACARU)"
    },
    {
        "id": "72090442000349",
        "value": "ARLETE TRANSPORTES & LOGISTICA LTDA"
    },
    {
        "id": "14938900000199",
        "value": "AQUILA TRANSPORTES"
    },
    {
        "id": "12606105000178",
        "value": "APPLYCAR COMERCIO E DISTRIBUIDORA EIRELI"
    },
    {
        "id": "04425239000326",
        "value": "ANTONIO MANOEL SERAFIM EIRELI"
    },
    {
        "id": "11094910000105",
        "value": "ANGELO ROGERIO BATISTA SANTOS EPP"
    },
    {
        "id": "12352017000197",
        "value": "ANGAR 3 LOGISTICA TRANSPORTE E DISTRIBUICAO LTDA"
    },
    {
        "id": "13770395000234",
        "value": "ANDRE ALVES DA SILVA EPP (RODOALVES-RS)"
    },
    {
        "id": "02391283000175",
        "value": "ANDRADE & REZENDE TRANSPORTES LTDA"
    },
    {
        "id": "46435293000807",
        "value": "ANDORINHA CARGAS"
    },
    {
        "id": "03561487009",
        "value": "ANDERSON"
    },
    {
        "id": "10278143000202",
        "value": "AMPLA CARGAS, TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "72345200000278",
        "value": "AMILTON LUIZ CARDOSO LTDA"
    },
    {
        "id": "02859381000275",
        "value": "AMIGO TRANSPORTES DE GOIAS LTDA"
    },
    {
        "id": "19020756000201",
        "value": "AMERICARGAS LOGISTICA E TRANSPORTES LTDA EPP"
    },
    {
        "id": "04767841000260",
        "value": "AMC TRANSPORTES "
    },
    {
        "id": "04767841000180",
        "value": "AMC TRANSPORTES"
    },
    {
        "id": "47959697000862",
        "value": "AMAZONAS INDUSTRIA E COMERCIO LTDA"
    },
    {
        "id": "08629309000238",
        "value": "AM JUMBO CARGO TRANSPORTES ROD E LOGISTICA LTDA"
    },
    {
        "id": "02945318000251",
        "value": "ALTOEXPRESS CARGAS E ENCOMENDAS LTDA"
    },
    {
        "id": "03370710000100",
        "value": "ALTIZA"
    },
    {
        "id": "29083693000105",
        "value": "ALPYTRANS TRANSPORTES E LOGISTICA EIRELI"
    },
    {
        "id": "10844205000107",
        "value": "ALPNY TRANSPORTES E LOGISTICA LTDA EPP"
    },
    {
        "id": "96373535000268",
        "value": "ALPHEU TRANSPORTES LTDA"
    },
    {
        "id": "33746863000161",
        "value": "ALO CARGAS"
    },
    {
        "id": "03701440000164",
        "value": "ALIANCA TRANSPORTE E LOGISTICA LTDA"
    },
    {
        "id": "22939519000172",
        "value": "ALIANCA EXPRESS TRANSPORTES RODOVIARIO LTDA"
    },
    {
        "id": "02427026001207",
        "value": "ALIANCA"
    },
    {
        "id": "82110818000393",
        "value": "ALFA TRANSPORTES LTDA"
    },
    {
        "id": "82110818000393",
        "value": "ALFA TRANSPORTES"
    },
    {
        "id": "96669007000250",
        "value": "ALEX TRANSPORTES LTDA"
    },
    {
        "id": "05494041000286",
        "value": "ALEMAR LOGISTICA E TRANSPORTE LTDA"
    },
    {
        "id": "06344101000166",
        "value": "ALEIXO & FRANCO TRANSPORTES DE CARGAS LTDA"
    },
    {
        "id": "12455074000282",
        "value": "ALBUQUERQUE SALES TRANSPORTES LTDA ME"
    },
    {
        "id": "06965919000104",
        "value": "ALBERTO ITIYU FUJISAWA ME"
    },
    {
        "id": "06171308000267",
        "value": "ALAGOINHAS TRANSPORTES LTDA"
    },
    {
        "id": "66122029000154",
        "value": "AIR TIGER BRASIL LTDA"
    },
    {
        "id": "27840760000163",
        "value": "AIR FREIGHT TRANSPORT SERVICES"
    },
    {
        "id": "00464890000273",
        "value": "AILTON GOMES BRANDAO DE ITUBERA ME"
    },
    {
        "id": "05507626000374",
        "value": "AHP TRANSPORTES LTDA"
    },
    {
        "id": "07805269000275",
        "value": "AGUIAR TRANSPORTES"
    },
    {
        "id": "02241402000618",
        "value": "AGUIA SUL LOGISTICA E TRANSPORTES LTDA"
    },
    {
        "id": "08860184000686",
        "value": "AGUIA BRANCA ENCOMENDAS LTDA."
    },
    {
        "id": "06338337000432",
        "value": "AGRIJAR"
    },
    {
        "id": "39394790000172",
        "value": "AGILLY TRANSPORTES"
    },
    {
        "id": "20721450000180",
        "value": "AGILE TRANSPORTES"
    },
    {
        "id": "08651231000258",
        "value": "AGIL TRANSPORTE RODOVIARIO DE CARGAS EIRELI"
    },
    {
        "id": "28276660000119",
        "value": "AGEFEX BRASIL"
    },
    {
        "id": "18865260000276",
        "value": "AGA TRANSPORTES"
    },
    {
        "id": "01190930000118",
        "value": "AG TRANSPORTES AGENCIAMENTO DE CARGAS LTDA"
    },
    {
        "id": "34231067000159",
        "value": "AF LOG"
    },
    {
        "id": "34231067000159",
        "value": "AF LOG"
    },
    {
        "id": "05529929000126",
        "value": "AEROFLEX CARGO E LOGISTICA LTDA"
    },
    {
        "id": "67434506000180",
        "value": "AEREO LESTE CARGAS E ENCOMENDAS LTDA"
    },
    {
        "id": "37086644000154",
        "value": "ADW LOGISTICA"
    },
    {
        "id": "06879342000452",
        "value": "ADRIANA BARBOZA BEZERRA DE SA"
    },
    {
        "id": "13142828000126",
        "value": "ADILSON ARRUDA"
    },
    {
        "id": "09619134000160",
        "value": "ADESUL TRANSPORTADORA TURISTICA LTDA"
    },
    {
        "id": "04961504000200",
        "value": "ACTUAL CARGO LTDA"
    },
    {
        "id": "01083933000152",
        "value": "ACROTTI MUDANCAS E TRANSPORTES LTDA"
    },
    {
        "id": "81560047000705",
        "value": "ACEVILLE"
    },
    {
        "id": "05222092000763",
        "value": "ACCERT TRANSPORTES E LOGISTICA LTDA"
    },
    {
        "id": "41442632000273",
        "value": "ACAPU TRANSPORTES "
    },
    {
        "id": "11389422000117",
        "value": "ABT-LOG"
    },
    {
        "id": "17910909000260",
        "value": "ABM LOGISTICA LTDA"
    },
    {
        "id": "26705966000118",
        "value": "A10 LOGISTICA E TRANSPORTES"
    },
    {
        "id": "06814159000126",
        "value": "A.M.-TRANSPORTES"
    },
    {
        "id": "80845043000107",
        "value": "A.J. FUCHS TRANSPORTES LTDA"
    },
    {
        "id": "80845043000450",
        "value": "A.J. FUCHS TRANSPORTES LTDA"
    },
    {
        "id": "04641732000200",
        "value": "A.C.O TRANSPORTES DE CARGAS EIRELI"
    },
    {
        "id": "07856991000158",
        "value": "A. R. TRANSPORTES"
    },
    {
        "id": "40060730000283",
        "value": "A L TRANSPORTES"
    },
    {
        "id": "03030414000151",
        "value": "A L MAFRA TRANSPORTES LTDA"
    },
    {
        "id": "04208610000489",
        "value": "A F TRANSPORTES"
    },
    {
        "id": "10603304000288",
        "value": "A F ARAUJO FONSECA TRANSPORTE E COMERCIO ME"
    },
    {
        "id": "41915753000295",
        "value": "4 IRMAOS"
    },
    {
        "id": "56059058000246",
        "value": "3P TRANSPORTES LTDA"
    },
    {
        "id": "40944635000242",
        "value": "3M TRANSPORTADORA"
    },
    {
        "id": "35822509000102",
        "value": "2AE AEREO E RODOVIARIO TRANSPORTES LTDA"
    }
]