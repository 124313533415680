import styled from "styled-components"
import { useEffect, useState } from "react"
import Table from "../components/Table.jsx"
import { Select } from "../components/Select.jsx"
import IconButton from "../components/IconButton.jsx"
import { Input } from "../components/Input.jsx"
import { useSnackbar } from "notistack"
import { getPartnerIdInativo, insertOrder } from "../http/portalb2b.api.js"
import { getPartnerId } from "../http/portalb2b.api.js"
import { useId } from "react"
import carrinho from "../assets/carrinho-vazio.jpg"
import { device } from "../components/device.config.js"
import ModalProduct from "../components/ModalProduct.jsx"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Header from "../components/Header.jsx"
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SelectData from "../components/Datalist.jsx"
import iconTrash from "../assets/LixeiraIcon.jpg"


import {
    paymentsTimeVale,
    paymentsAi,
    paymentsVale,
    paymentsTimeAi,
    paymentsAutoCase,
    paymentsTimeAutoCase,
    paymentsTimeSKAutoParts,
    paymentsSKAutoParts,
    paymentsTimeZZComercial,
    paymentsZZComercial
} from "../temp/payment.js"
import { DeleteForever } from "@mui/icons-material"
import ModalConfirm from "../components/ModalConfirm.jsx"
import { useLayoutEffect } from "react"
import { Fragment } from "react"
import ClearButton from "../components/ClearButton.jsx"
import { useMediaQuery } from "@mui/material"


const CartContainerFilter = styled.div`
    display: flex;
	gap:5px;
	transition: all 0.3s ease;
	&.fixo {
    position: fixed;
	padding:30px 30px 10px 30px;
	border-radius:10px;
    top: 0;
	background-color: rgb(255, 255, 255);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
	@media ${device.laptopL} {
		margin-top:30px;
	 }
	 @media ${device.mobileL} {
		flex-direction:column;
	 }

`

const CartTable = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
`

const CartItemSearch = styled.div`
width:100%;
display:flex;
gap:5px;
flex-direction:column;
@media ${device.desktop} {

	 }
`


const CartSaleContainer = styled.div`
 	display:flex;
	justify-content: center;
	flex-direction:column;
	width:100%;

	&.test{
		align-items: center;

	}
    border-radius:10px;
	gap:20px;
	@media ${device.laptopL} {
	 }

`
const Paragraph = styled.p`
font-weight:500;
font-size:12px;
color:black;
text-align: center;
@media ${device.laptopL} {
    font-weight:400;
    font-size:11px;
}
`

const FilterButtonItemClear = styled.div`
width: 120px;
display:flex;
`
const NumberInput = styled.input`

  font-size: 14px;
  width: 90px;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 4px;
  text-align:'center';
  /* Remover as setas do campo de entrada */
  appearance: none;
  -moz-appearance: textfield; /* Para o Firefox */

  /* Estilo adicional para navegadores que suportam o seletor '::-webkit-inner-spin-button' */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus{
    outline: none;
    border: 1px solid #ccc;
  }
  :active{
    outline: none;
    border: 1px solid #ccc;
}
:hover{
      color: black;
      cursor: pointer;
    }
`;

const CardContainer = styled.div`
    display:flex;
    width:230px;
    overflow:hidden;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    text-align:'center';
    @media ${device.laptopL} {
      padding:5px;
    }
 `

const WariningMessageContainer = styled.div`
 display:flex;
 flex-direction: column;
 margin-bottom:10px;
 `
const WariningMessageItem = styled.div`

  `
export const CartSale = () => {

    const [cartValues, setCartValues] = useState([])
    const [values, setValues] = useState({})
    const [payments, setPayments] = useState([])
    const [paymentTime, setPaymentTime] = useState([])
    const [company, setCompany] = useState('')
    const [filters, setFilters] = useState({})
    const [filterData, setFilterData] = useState([])
    const [valueSubmit, setValueSubmit] = useState({})
    const [disabled, setDisabled] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [transport, setTransport] = useState({})
    const [isActiveModal, setIsActiveModal] = useState(false)
    const [isActiveFilter, setIsActiveFilter] = useState(true)
    const [disabledButton, setDisabledButton] = useState(false)

    const id = useId()
    const orderEdit = JSON.parse(localStorage.getItem('edtCart'))
    const handleScroll = () => {

        const filtroElement = document.getElementById("filtro");
        const filtroElementContainer = document.getElementById("filtroContainer");


        if (filtroElement) {
            const shouldHideFilter = ((window.scrollY >= 500)); // Esconde o filtro a 200 pixels do topo

            if (window.scrollY <= 500) {
                setIsActiveFilter(true)
                setDisabledButton(false)
            }
            if (shouldHideFilter) {
                setDisabledButton(true)
                filtroElement.classList.add("fixo");
                filtroElementContainer.classList.add("test");


            } else if (shouldHideFilter === false) {
                filtroElement.classList.remove("fixo");
                filtroElementContainer.classList.remove("test");


            }
        }
    };

    useLayoutEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const handleAddCart = (data) => {

        let cartData = JSON.parse(localStorage.getItem('cart'))
        if (!cartData) cartData = []
        let newCardItems = [...cartData]

        let newCardItem = newCardItems.map(newCardItem => {
            return {
                ...newCardItem,
                quantity: newCardItem.codigo == data.codigo ? newCardItem.quantity + 1 : newCardItem.quantity
            }
        })
        // quantity = newCardItems.find(card => card.codigo == data.codigo) ? newCardItems.find(card => card.codigo == data.codigo).quantity + 1 : 1
        // let distinctItemCardData = newCardItems.filter(card => card.codigo != data.codigo)

        localStorage.setItem('cart', JSON.stringify([...newCardItem]))
        // localStorage.setItem('cart', JSON.stringify([...distinctItemCardData, { ...data, quantity }]))

        handleData()

    }

    const quantityCardItem = (data, quantity) => {

        if (quantity) {
            let cartData = JSON.parse(localStorage.getItem('cart'))
            if (!cartData) cartData = []
            let newCardItems = [...cartData]

            let newCardItem = newCardItems.map(newCardItem => {
                return {
                    ...newCardItem,
                    quantity: newCardItem.codigo == data.codigo ? Number(quantity) : newCardItem.quantity,
                    negotiatedPrice: newCardItem.codigo == data.codigo && newCardItem.negotiatedPrice ? newCardItem.negotiatedPrice : 0




                }
            })

            localStorage.setItem('cart', JSON.stringify([...newCardItem]))



        }
        handleData()

    }
    const handleRemoveCart = (data) => {

        let cartItems = JSON.parse(localStorage.getItem('cart'))
        let newCardItems = [...cartItems]
        if (!cartItems) cartItems = []

        let quantity = newCardItems.find(cartItem => cartItem.codigo == data.codigo).quantity
        if (quantity > 1) {

            let newCardItem = newCardItems.map(newCardItem => {
                return {
                    ...newCardItem,
                    quantity: newCardItem.codigo == data.codigo ? newCardItem.quantity - 1 : newCardItem.quantity
                }
            })

            // let distinctItemCardData = cartItems.filter(cartItem => cartItem.codigo != data.codigo)

            if (data.quantity <= 0) {
                //Apagar do Carrinho
                let newCardItemDelete = newCardItems.filter(newCardItem => newCardItem.codigo !== data.codigo)
                localStorage.setItem('cart', JSON.stringify([...newCardItemDelete]))

            } else {
                // Decrementar estoque e manter no carrinho
                localStorage.setItem('cart', JSON.stringify([...newCardItem]))

            }
        } else {

            handleModalDelete(data)
        }
        handleData()
    }

    const { enqueueSnackbar } = useSnackbar()

    const handleData = () => {
        const values = JSON.parse(localStorage.getItem('cart'))

        if (values && values.edit) {
            setCartValues(values.items)
        } else {

            setCartValues(values)
        }

    }
    useEffect(() => { if (!visitor) handleSubmitSearch(); handleData() }, [])


    const handleChange = (e) => {
        e.preventDefault()

        const { name, value } = e.target;
        setCompany(value)

        if (value == 'Vale') {

            setPaymentTime(paymentsTimeVale)
            setPayments(paymentsVale)
        }

        if (value == 'AUTOCASE') {
            // setPaymentTime(paymentsTimeAutoCase)
            // setPayments(paymentsAutoCase)

        }

        if (value == 'SKAutoParts') {
            setPaymentTime(paymentsTimeSKAutoParts)
            setPayments(paymentsSKAutoParts)

        }

        if (value == 'AUTOCASE') {
            // setPaymentTime(paymentsTimeAutoCase)
            // setPayments(paymentsAutoCase)

        }

        if (value == 'ai') {
            setPaymentTime(paymentsTimeAi)
            setPayments(paymentsAi)

        }

        if (value == 'ZZComercial') {
          setPaymentTime(paymentsTimeZZComercial)
          setPayments(paymentsZZComercial)

      }

        if (value == '') {
            setPaymentTime([])
            setPayments([])
            setValueSubmit((current) => {
                return {
                    ...current,
                    transport: '',
                    message: '',
                    payment: '',
                    paymentTime: '',
                }
            })

        }

    }

    const handleInputSubmit = (e) => {
        e.preventDefault()

        let { name, value } = e.target;

        if (name == 'cliente') {
            value = filterData.find(data => data._id == value);
            let client = value._id
            setFilters({ ...filters, client })

        }
        setValueSubmit((current) => {
            return {
                ...current,
                [name]: value
            }
        })



    }

    useEffect(() => {

        let newValueSubmit = valueSubmit

        if (newValueSubmit.payment && newValueSubmit.payment !== "" && newValueSubmit.payment !== null &&
            newValueSubmit.paymentTime && newValueSubmit.paymentTime !== "" && newValueSubmit.paymentTime !== null &&
            newValueSubmit.cliente && newValueSubmit.cliente !== null && newValueSubmit.cliente !== undefined &&
            company !== null && company !== '') {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }

    }, [company, valueSubmit])

    const handlePrice = (e, data) => {
        e.preventDefault()
        const { value } = e.target;
        const newPrice = value !== '' ? parseFloat(value.replace(',', '.')) : '';

        let cartData = JSON.parse(localStorage.getItem('cart')) || [];
        let newCardData = [...cartData];

        let newCardItem = newCardData.map(newCardItem => {
            return {
                ...newCardItem,
                negotiatedPrice: newCardItem.codigo == data.codigo ? newCardItem.negotiatedPrice = newPrice : newCardItem.negotiatedPrice
            }
        })


        localStorage.setItem('cart', JSON.stringify(newCardItem));

        handleData();
    }

    const handleBlur = (e, data) => {
        e.preventDefault()
        const { value } = e.target;
        const newValue = value !== '' ? parseFloat(value.replace(',', '.')).toFixed(2) : '';

        // Defina o valor atual do input usando newValue
        e.target.value = newValue;

        // Atualize o valor atual no objeto data
        data.negotiatedPrice = newValue;

        // Resto do código...
    }

    const handleInput = (e) => {
        e.preventDefault()

        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value })
    }

    const handleDeleteCart = () => {
        localStorage.removeItem('cart')
        localStorage.removeItem('edtCart')
        setCartValues([])
        enqueueSnackbar(orderEdit && orderEdit.edit ? 'Edição Cancelada' : 'Carrinho Apagado', { variant: 'success' })
    }

    const handleSubmitSearch = async () => {
        let partnerFilterInativo = []
        const values = JSON.parse(localStorage.getItem('edtCart'))
        if (values && values.edit && filters.search) {
            const partnerFilter = await getPartnerId(filters.search)
            if (partnerFilter.length > 0) {
                setFilterData(partnerFilter)
                setFilters({ ...filters, client: partnerFilter.length == 1 ? partnerFilter[0]._id : values.cliente._id })
                setValueSubmit((current) => {
                    return {
                        ...current,
                        cliente: partnerFilter[0]
                    }
                })
            } else {

                let partnerFilterInativo = await getPartnerIdInativo(filters.search)
                if (partnerFilterInativo.length > 0) {
                    setFilterData(partnerFilterInativo)
                    setFilters({ ...filters, client: partnerFilterInativo[0]._id })
                    setValueSubmit((current) => {
                        return {
                            ...current,

                            cliente: partnerFilterInativo[0]
                        }
                    })
                }

            }
            if (!values.edit) {
                setFilters((current) => { return { ...current, client: values.cliente._id } })
                setValueSubmit({
                    cliente: values.cliente,
                })

            }



        } else {

            const partnerFilter = await getPartnerId(filters.search)
            if (filters.search) partnerFilterInativo = await getPartnerIdInativo(filters.search)

            if (partnerFilter.length == 0) {


                if (partnerFilterInativo.length > 0 && filters.search) {
                    setFilterData(partnerFilterInativo)
                    setFilters({ ...filters, client: partnerFilterInativo[0]._id })
                    setValueSubmit((current) => {
                        return {
                            ...current,

                            cliente: partnerFilterInativo[0]
                        }
                    })
                } else {
                    handleData()
                    setFilters({})
                    setValueSubmit((current) => {
                        return {
                            ...current,
                            cliente: '',
                            transport: '',
                            message: '',
                            payment: '',
                            paymentTime: '',
                        }
                    })
                }
            }
            else if (partnerFilter.length > 0) {
                setFilterData(partnerFilter)

                if (filters.search == '' || !filters.search) {
                    setFilters({})
                    setValueSubmit((current) => {
                        return {
                            ...current,
                            transport: '',
                            message: '',
                            payment: '',
                            paymentTime: '',
                        }
                    })
                }

            }

            if (filters.search && partnerFilter.length > 0) {
                setFilters({ ...filters, client: partnerFilter[0]._id })
                setValueSubmit((current) => {
                    return {
                        ...current,

                        cliente: partnerFilter[0]
                    }
                })
            } else if (filters.search && partnerFilterInativo.length == 0) {

                enqueueSnackbar('Não foi localizado nenhum cliente', { variant: 'info' })
                setFilters({})
                setValueSubmit((current) => {
                    return {
                        ...current,
                        transport: '',
                        message: '',
                        payment: '',
                        paymentTime: '',
                    }
                })

            }

            if (filters.search == '') {
                setFilters({ ...filters, client: '' })

            }

        }
    }

    const IconStyle = {
        color: '#07689D',
        backgroundColor: 'white',
        fontSize: 10,
    }

    const IconStyleVis = {
        color: '#07689D',

    }

    const IconStyleDelete = {
        color: 'red',

    }

    const onSubmit = async () => {

        try {

            if (orderEdit && orderEdit.edit) {
                const values = JSON.parse(localStorage.getItem('edtCart'))
                if (values) {
                    await insertOrder([{ ...valueSubmit, transport, company, items: cartValues, edit: true, orderId: values.orderid, }])
                    enqueueSnackbar('Pedido editado', { variant: 'success' })
                    localStorage.removeItem('cart')
                    localStorage.removeItem('edtCart')
                    setFilters({})
                    setCartValues([])


                }
            } else if (valueSubmit && cartValues && cartValues.length > 0) {
                const order = await insertOrder([{ ...valueSubmit, transport, company, items: cartValues }])
                if (order) {
                    enqueueSnackbar('Orçamento Salvo', { variant: 'success' })
                    localStorage.removeItem('cart')
                    localStorage.removeItem('edtCart')
                    setFilters({})
                    setCartValues([])
                }
                else {
                    enqueueSnackbar('Não possivel salvar seu pedido, revise seu pedido!', { variant: 'error' })
                }

            }
            else {
                enqueueSnackbar('Adicione itens ao carrinho', { variant: 'info' })
                setFilters({})
            }
        }
        catch (error) {

        }

    }



    const handleChangeItens = (e, data) => {
        e.preventDefault()
        const { value } = e.target;

        if (value >= 1) {
            quantityCardItem(data, value)
        } else {
            handleModalDelete(data)
        }
    }


    const handleDeleteItem = () => {

        if (values.codigo) {

            let cartValues = JSON.parse(localStorage.getItem('cart'))
            const newCartValues = cartValues.filter(item => item.codigo !== values.codigo)
            localStorage.setItem('cart', JSON.stringify(newCartValues))

            setIsActiveModal(!isActiveModal)
            handleData()
        }



    }

    const columns = [
        {
            path: 'descricao',
            name: 'Descrição',
            isActiveFilter: false,
            attribute: (value) => {
                return <p style={{ flexWrap: 'nowrap', inlineSize: 160 }}>{value}</p>
            }

        },
        {
            path: 'categoria',
            name: 'Categoria',
            isActiveFilter: false,
            attribute: (value) => {
                return <p style={{ flexWrap: 'nowrap', inlineSize: 100 }}>{value}</p>
            }
        },
        {
            path: 'avaible',
            name: 'Status',
            isActiveFilter: true,
            style: { width: 10 },
            attribute: (value) => {
                return value == true ?
                    < p style={{ color: '#50de4e', fontWeight: 'bold' }}>Disponível</p> :
                    <p style={{ color: 'red', fontWeight: 'bold' }}>Indisponivel</p>
            }
        },
        {
            path: 'codigo',
            name: 'SKU',
            isActiveFilter: false,

        },
        {
            path: 'quantity',
            name: 'Quantidade',
            isActiveFilter: false,
            attribute: (quantity, value) => {


                return (
                    <CardContainer>
                        <IconButton

                            onClick={() => { handleRemoveCart(value) }}
                            style={{ width: '20px', height: 'px', borderRadius: 0, background: 'none', }} >
                            <RemoveIcon style={IconStyle} />
                        </IconButton>
                        <Input
                            onChange={(e) => { handleChangeItens(e, value) }}
                            value={quantity
                            }
                            style={{ width: 45, height: 15, fontSize: 11, textAlign: 'center', color: 'black', backgroundColor: '#e2e2e2' }}

                        />
                        <IconButton
                            disabled={value.avaible == false ? true : false}
                            onClick={() => { handleAddCart(value) }}
                            style={{ width: '20px', height: '20px', borderRadius: 0, background: 'none' }}
                        >
                            <AddIcon style={IconStyle} />
                        </IconButton>
                    </CardContainer>)

            }

        },
        {
            path: 'price',
            name: 'Preço Real',
            totalValue: 0,
            onChange: handleChange,
            attribute: (value) => {
                return value
            },
            sum: true
        },
        {
            path: '',
            name: '%Negociado',

            attribute: (isEmpty, value) => {
                let percentual = 0
                if (value.negotiatedPrice > 0) {
                    percentual = (((value.negotiatedPrice - value.price) * 100) / value.price).toFixed(2)
                } else {
                    percentual = 0
                }

                return (<p style={{ fontSize: 14, color: percentual >= 0 ? 'green' : 'red' }}>{percentual + ' %'}</p>)
            }
        },
        {
            path: 'negotiatedPrice',
            name: 'Preço Negociado',
            totalValue: 0,
            attribute: (price, value) => {
                value.negotiatedPrice
                return (
                    <CardContainer key={value._id}>


                        <NumberInput
                            type="number"
                            onBlur={(e) => handleBlur(e, value)}
                            key={id}
                            required={true}
                            minLength={1}
                            style={{ margin: 10, fontSize: 14, textAlign: 'center', color: 'black', backgroundColor: '#e2e2e2' }}
                            name='priceNegotiated'
                            onChange={(e) => { handlePrice(e, value) }}
                            placeholder={Number(price).toFixed(2)}

                        />

                    </CardContainer>)

            },
            sum: (value) => {
                let total = 0
                total + value
                return total

            }
        },
        {
            attribute: (isEmpty, value) => {
                return (
                    <div style={{ display: 'flex', gap: '10px', margin: 20 }}>
                        <IconButton tooltipTitle='Consultar item' onClick={() => handleModal(value)} background={'transparent'} style={{ width: 50 }} >
                            <VisibilityOutlinedIcon style={IconStyleVis} />
                        </IconButton>
                        <IconButton tooltipTitle='Deletar item' onClick={() => handleModalDelete(value)} background={'transparent'} style={{ width: 50 }} >
                            <DeleteForever style={IconStyleDelete} />
                        </IconButton>
                    </div>)
            }
        }

    ]
    const columnsDevice = [
        {
            path: '',
            name: 'Orçamento',
            isActiveFilter: false,
            totalValue: 0,
            sum: (value) => {
                let total = 0
                total + value
                return total

            },
            attribute: (_, dataRaw) => {
                return (
                    <Fragment>
                        <p style={{ padding: 10 }}>Codigo:{dataRaw.codigo}</p>
                        <p style={{ padding: 10 }}>Marca:{dataRaw.marca}</p>
                        <p style={{ padding: 10 }}>Categoria:{dataRaw.categoria}</p>
                        <p style={{ padding: 10 }}>Preço De:{dataRaw.price}</p>
                        <p style={{ padding: 10 }}>Preço Por:{dataRaw.fromPrice}</p>
                        <CardContainer>
                            <IconButton

                                onClick={() => { handleRemoveCart(dataRaw) }}
                                style={{ width: '20px', height: 'px', borderRadius: 0, background: 'none', }} >
                                <RemoveIcon style={IconStyle} />
                            </IconButton>
                            <Input
                                onChange={(e) => { handleChangeItens(e, dataRaw) }}
                                value={dataRaw.quantity
                                }
                                style={{ width: 45, height: 15, fontSize: 11, textAlign: 'center', color: 'black', backgroundColor: '#e2e2e2' }}

                            />
                            <IconButton
                                disabled={dataRaw.avaible == false ? true : false}
                                onClick={() => { handleAddCart(dataRaw) }}
                                style={{ width: '20px', height: '20px', borderRadius: 0, background: 'none' }}
                            >
                                <AddIcon style={IconStyle} />
                            </IconButton>
                            <CardContainer key={dataRaw._id}>

                                Preco Negociado:
                                <NumberInput
                                    type="number"
                                    onBlur={(e) => handleBlur(e, dataRaw)}
                                    key={id}
                                    required={true}
                                    minLength={1}
                                    style={{ margin: 10, fontSize: 14, textAlign: 'center', color: 'black', backgroundColor: '#e2e2e2' }}
                                    name='priceNegotiated'
                                    onChange={(e) => { handlePrice(e, dataRaw) }}
                                    placeholder={Number(dataRaw.price).toFixed(2)}

                                />

                            </CardContainer>
                        </CardContainer>
                        <div style={{ display: 'flex', gap: '10px', margin: 20 }}>
                            <IconButton tooltipTitle='Consultar item' onClick={() => handleModal(dataRaw)} background={'transparent'} style={{ width: 50 }} >
                                <VisibilityOutlinedIcon style={IconStyleVis} />
                            </IconButton>
                            <IconButton tooltipTitle='Deletar item' onClick={() => handleModalDelete(dataRaw)} background={'transparent'} style={{ width: 50 }} >
                                <DeleteForever style={IconStyleDelete} />
                            </IconButton>
                        </div>
                    </Fragment>)
            }

        },
    ]

    const toggleModal = () => {
        setIsOpen(!isOpen)

    }

    const handleModal = (value) => {
        setValues(value)
        toggleModal()
    }


    const handleModalDelete = (value) => {
        setValues(value)
        setIsActiveModal(!isActiveModal)
    }


    const handleKeyDown = (event) => {
        if (event.keyCode === 13 ||
            event.keyCode === 18) { // Verifica se a tecla pressionada é "Enter"
            handleSubmitSearch(); // Chama a função de clique no botão
        }
    };
    const { visitor } = JSON.parse(localStorage.getItem('authToken'))
    const iswebdevice = useMediaQuery('(max-width:768px)');

    return (
        <CartSaleContainer id="filtroContainer">

            <ModalProduct
                isOpen={isOpen} value={values} onClose={() => { toggleModal() }} />

            <ModalConfirm
                isOpen={isActiveModal}
                logo={iconTrash}
                img={{ width: 70, heigth: 50 }}
                handleFuncionYes={() => {
                    handleDeleteItem()
                }}
                handleFuncionNot={() => setIsActiveModal(!isActiveModal)}
                label={`Deseja deletar o SKU: ${values.codigo}?`} />
            <Header icon={<LocalMallOutlinedIcon fontSize="20px" />} name='Carrinho' />


            {!isActiveFilter && <FilterButtonItemClear>
                {disabledButton && <ClearButton iconName={isActiveFilter ? 'pin_end' : 'push_pin'} label={!isActiveFilter ? "Mostrar" : "Ocultar"} onClick={() => setIsActiveFilter((state) => !state)}></ClearButton>}
            </FilterButtonItemClear>}

            <Fragment>
                <CartContainerFilter id='filtro' >


                    {!isActiveFilter &&
                        <FilterButtonItemClear>
                            {disabledButton &&
                                <ClearButton iconName={isActiveFilter ? 'pin_end' : 'push_pin'} label={!isActiveFilter ? "Mostrar" : "Ocultar"} onClick={() => setIsActiveFilter((state) => !state)}></ClearButton>}
                        </FilterButtonItemClear>}

                    {isActiveFilter && (
                        <Fragment>
                            <CartItemSearch>
                                <Input
                                    onChange={handleInput}
                                    value={filters.search}
                                    name="search"
                                    onKeyUp={handleKeyDown}
                                    placeholder="Pesquise seu cliente, ultilize Nome ou Código ou Cnpj" />
                                <Select
                                    onChange={handleInputSubmit}
                                    value={filters.client}
                                    name="cliente"
                                    placeholder="Nome  do Cliente" >
                                    <option value="" hidden>Selecione o cliente *</option>
                                    {filterData && filterData.map((filter, index) => <option key={id + index} value={filter._id}> Código: {filter.codigo}  Razão: {filter.nome}  CNPJ{filter.documento} UF: {filter.UF}</option>)}
                                </Select>

                                <Input name="message" onChange={handleInputSubmit} placeholder="Observação (Opcional)" />


                            </CartItemSearch>
                            <CartItemSearch>
                                <IconButton
                                    onClick={handleSubmitSearch}
                                    iconName={'search'}
                                    label={'Pesquisar Cliente'} />
                                <Select name="company" onChange={handleChange} value={company}>
                                    <option value="" hidden>Empresa *</option>
                                    <option value="Vale">Vale</option>
                                    <option value="ai">AI</option>
                                    <option value="SKAutoParts">SKAutoPartes</option>
                                    <option value="ZZComercial">ZZComercial</option>
                                </Select>
                                <SelectData value={transport} setValue={setTransport} />

                            </CartItemSearch>
                            <CartItemSearch>
                                <Select value={valueSubmit.payment} name="payment" onChange={handleInputSubmit} disabled={!payments.length > 0 ? true : false}>
                                    <option value="" hidden>Forma de Pagamento *</option>
                                    {payments && payments.map((payment, index) => <option key={id + index} value={payment}>{payment}</option>)}
                                </Select>

                                <Select value={valueSubmit.paymentTime} name="paymentTime" onChange={handleInputSubmit} disabled={!paymentTime.length > 0 ? true : false}>
                                    <option value="" hidden>Prazos *</option>
                                    {paymentTime && paymentTime.map((payment, index) => <option key={id + index} value={payment.id + ' | ' + payment.descricao}>{payment.descricao}</option>)}
                                </Select>
                                <IconButton
                                    iconName={'save'}
                                    disabled={disabled}
                                    onClick={onSubmit}
                                    outlined={true}
                                    label={'Salvar Orçamento'} />
                                <IconButton
                                    onClick={handleDeleteCart}
                                    iconName={orderEdit && orderEdit.edit ? 'close' : 'delete'}
                                    outlined={true}
                                    label={orderEdit && orderEdit.edit ? 'Cancelar Edição' : 'Apagar Orçamento'} />

                                <FilterButtonItemClear>
                                    {disabledButton && <ClearButton iconName={isActiveFilter ? 'pin_end' : 'push_pin'} label={!isActiveFilter ? "Mostrar" : "Ocultar"} onClick={() => setIsActiveFilter((state) => !state)}></ClearButton>}
                                </FilterButtonItemClear>
                            </CartItemSearch>
                        </Fragment>)}

                </CartContainerFilter>
            </Fragment>

            {orderEdit && orderEdit.orderid && orderEdit.edit && (
                <WariningMessageContainer>
                    <WariningMessageItem>
                        <p style={{ color: '#722424', fontWeight: 700 }}>Você está editando o pedido #{orderEdit.orderid}</p>
                    </WariningMessageItem>
                    <WariningMessageItem>
                        <p style={{ color: '#722424', fontWeight: 700 }}> caso não deseje continuar, clique em cancelar edição !</p>
                    </WariningMessageItem>
                </WariningMessageContainer>
            )}
            {cartValues && cartValues.length > 0 ? (
                <CartTable>

                    <Table
                        options={[{ buttonFilter: false }]}
                        columns={iswebdevice ? columnsDevice : columns}
                        data={!visitor ? cartValues : []} />
                </CartTable>

            ) : <CartTable>
                <img src={carrinho}></img>
            </CartTable>}
        </CartSaleContainer >
    )
}
