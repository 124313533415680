import styled from "styled-components";
import IconButton from "./IconButton.jsx";
import { Fragment, useEffect, useRef, useState } from "react";
import { Input } from "./Input.jsx";
import { useSnackbar } from 'notistack'
import { device } from "./device.config.js";
import ClearButton from "./ClearButton.jsx";
import DateRangePicker from "./DateRangePicker.jsx";
import PaginationOrders from "./PaginationOrders.jsx";



const TableHeader = styled.th`
    align-items: center;
    border: 0px solid black;
    background:  #0771AC;
    color: white;
    margin: 20px auto;
    width: 20%;
    height: 70px;
    padding: 10px;


`
const Tables = styled.table`
overflow: hidden;
border-radius:10px;
font-size: 12px;
padding:10px;
margin:0 auto;
align-items: center;
border-collapse: collapse;

`
const TableData = styled.td`
border: 0px solid black;
text-align: center;
@media ${device.tablet} { 
    display:flex;
    justify-content:center;
    }

`
const Tbody = styled.tbody`
width:100px;
`
const TableRow = styled.tr`
  align-items: center;
  border: 1px solid #ccc;
  min-height:1220px;
  :hover{

}
`
const TableSection = styled.section`
  align-items: center;
  text-align: center;
  justify-content: space-around;    
  flex-direction:column;
`
const FilterContainer = styled.div`
    display: flex; 
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 40px;
    gap: 10px;
`;

const FilterItem = styled.div`
    width: 19%;
 
    @media ${device.desktop} { 
        width: 19%;
    }
 
`;

const FilterButtonContainer = styled.div`
display: flex;
width:100%;
flex-direction:column;
justify-content:flex-end;
margin:0 auto;
gap:5px;
flex-wrap:wrap;
@media ${device.desktop} { 
    justify-content: flex-end;
	 }
@media ${device.laptopL} { 
    justify-content: flex-end;
	 }
@media ${device.laptop} { 
   justify-content: flex-end;
}
@media ${device.tablet} { 
    justify-content: flex-end;
	 }
@media ${device.mobileS} { 
    justify-content: flex-end;
	 }

`
const FilterButtonItem = styled.div`
display: flex;
justify-content:flex-end;
width: 100%;
`
const ContainerTable = styled.div`
display:flex;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* A maioria dos navegadores */;
width: 100%;
flex-direction:column;

margin:0 auto;
::-webkit-scrollbar-thumb {
  background-color: #0771AC; /* Cor do fundo da alça da barra de rolagem */
  border-radius: 6px; /* Raio de borda da alça da barra de rolagem */
}
::-webkit-scrollbar {
  width: 2px; /* Largura da barra de rolagem */
  height:10px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
}

@media ${device.laptop} { 
    overflow-x: scroll;
}
@media ${device.tablet} { 
    overflow-x: scroll;
	 }
@media ${device.mobileS} { 
    overflow-x: scroll;
	 }
`
const PaginationContainer = styled.div`
display:flex;
margin:0 auto ;
margin-top:10px;
margin-bottom:10px;
gap:20px;
min-width: 1120px;
justify-content: flex-end;
align-items: baseline;
`
const TableDataSum = styled.td`
  font-size: 14px;
  font-weight:500;
  font-family:'Poppins';
`
const ContainerSum = styled.div`
margin-top:20px;
display:flex;
align-items: flex-end;
flex-direction:column;
`


const Table = ({ handleFilterPage, setStartDateOrders, setEndDateOrders, columns = [], data = [], setPagination, totalPages = 100, currentPage = 1, options = [], ...others }) => {

    const [filtersValue, setFiltersValue] = useState({})
    const [values, setValues] = useState([])
    const [columnsValue, setColumnsValue] = useState([])
    const [startDate, setStartDate] = useState();
    const [clear, setClear] = useState()
    const [endDate, setEndDate] = useState();
    const { enqueueSnackbar } = useSnackbar()
    const refInput = useRef()

    useEffect(() => {
        setValues(data)
    }, [data])

    useEffect(() => {
        if (setStartDateOrders, setEndDateOrders) {
            setStartDateOrders(startDate)
            setEndDateOrders(endDate)
        }
    }, [startDate, endDate])

    useEffect(() => {
        setColumnsValue(columns)
        if (columns && columns.filter(column => column.isActiveFilter == true).length == 0) {
            refInput.current.style.display = "none";
        } else {
            refInput.current.style.display = "flex";
        }
    }, [columns])


    const handleFilter = (e, index) => {
        e.preventDefault()
        const { name, value } = e.target
        setFiltersValue({ ...filtersValue, [name]: value.trim() })
        setColumnsValue((current) => {
            current[index] = { ...current[index], value }
            return current

        })

    }

    const handleResetFilter = () => {
        let clearValueColumn = columns.map(column => { return { ...column, value: '' } })
        setColumnsValue(clearValueColumn)
        setFiltersValue({})
        setValues(data)
        setClear({ ...{} })
    }

    const handleSubmitFilter = () => {

        let filterData = [...values]
        let filterValues = { ...filtersValue }

        //Filtro por aproximação

        for (let filter of Object.keys(filterValues)) {
            const regex = new RegExp(filterValues[filter].trim(), 'i');
            filterData = filterData.filter(f => regex.test(f[filter.toLocaleLowerCase().trim()]) || regex.test(f[filter.trim()]))
        }
        setValues(filterData)
        if (filterData.length == 0) {
            enqueueSnackbar('Nenhum item localizado!', { variant: 'default' })
            setValues(data)

        }
    }

    const handleKeyDown = (event) => {

        event.preventDefault()
        if (event.keyCode === 13 ||
            event.keyCode === 18) { // Verifica se a tecla pressionada é "Enter"
            handleSubmitFilter(); // Chama a função de clique no botão
        }
    };

    const convertMonetaryValueToNumber = (value) => {
        const valueConvert = Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return valueConvert;
    };

    const calcNegotiated = () => {
        let negotiatedPricePercent = 0;
        const totalNegotiatedPrice = values.reduce((accumulator, item) => accumulator + (item.quantity * Number(item.negotiatedPrice)), 0)
        const totalReal = (values.reduce((accumulator, item) => accumulator + (item.quantity * item.price), 0))
        if (totalNegotiatedPrice) {
            negotiatedPricePercent = (((totalReal - totalNegotiatedPrice) * 100) / totalReal).toFixed(2)
        }

        return negotiatedPricePercent
    }


    return (
        <Fragment>

            <FilterContainer ref={refInput}  >
                {columnsValue && columnsValue.filter(
                    column => column.isActiveFilter == true && column.type == 'input'
                ).map((filter, index) => {
                    return (

                        <FilterItem key={index}>
                            <Input
                                key={index}
                                name={filter.path}
                                onKeyUp={handleKeyDown}
                                placeholder={filter.name}
                                value={filter.value}
                                onChange={(e) => {
                                    e.preventDefault()
                                    if (filter.onChange) filter.onChange(e)
                                    handleFilter(e, index)
                                }}
                            />
                        </FilterItem>

                    )
                })}
                {columnsValue && columnsValue.filter(
                    column => column.isActiveFilter == true && column.type == 'date'
                ).map((filter, index) => {
                    return (
                        <FilterItem key={index}>
                            <DateRangePicker
                                setEndDateTable={setEndDate}
                                setStartDateTable={setStartDate}
                                key={index}
                                name={filter.path}
                                onKeyUp={handleKeyDown}
                                clear={clear}
                                placeholder={filter.name}
                                value={filter.value}
                                onChange={(e) => {
                                    e.preventDefault()
                                    if (filter.onChange) filter.onChange(e)
                                    handleFilter(e, index)
                                }}
                            />
                        </FilterItem>

                    )
                })}


                {options.length > 0 && options.filter(f => f.buttonFilterPage == true).length > 0 && (
                    <FilterItem>
                        <FilterButtonContainer>
                            <FilterButtonItem  >
                                <IconButton
                                    iconName={'search'}
                                    label={'Procurar'}
                                    onClick={() => { handleFilterPage(filtersValue) }}
                                />
                            </FilterButtonItem>
                            <FilterButtonItem>
                                <ClearButton
                                    iconName={'clear'}
                                    label={'Limpar Filtros'}
                                    onClick={handleResetFilter}
                                />
                            </FilterButtonItem>
                        </FilterButtonContainer>
                    </FilterItem>)}


                {options.length > 0 && options.filter(f => f.buttonFilter == true).length > 0 && (
                    <FilterItem>

                        <FilterButtonItem >
                            <IconButton

                                iconName={'search'}
                                label={'Procurar'}
                                onClick={handleSubmitFilter}
                            />
                        </FilterButtonItem>

                        <FilterButtonItem>
                            <ClearButton
                                iconName={'clear'}
                                label={'Limpar Filtros'}
                                onClick={handleResetFilter}
                            />
                        </FilterButtonItem>

                    </FilterItem>)}
            </FilterContainer>
            <ContainerTable>
                <Tables>
                    <Tbody id="relatorio">
                        <TableRow>
                            {columnsValue.length > 0 &&
                                columnsValue.map(({ path, name }) => (
                                    <TableHeader key={path}>{name}</TableHeader>
                                ))}
                        </TableRow>
                        {values && values.length > 0 &&
                            values.map(rowData => (
                                <TableRow key={rowData['id']}>
                                    {columnsValue.length > 0 &&
                                        columnsValue.map(({ path, attribute }, index, values) => (
                                            <Fragment>
                                                {path == 'button' ? (
                                                    <TableSection key={index}>
                                                        <FilterButtonItem>
                                                            <IconButton height={'25px'} onClick={() => { console.log('cheguei') }} width={'25px'} iconName="edit" label="" background=" #5a5553" />
                                                        </FilterButtonItem>
                                                        <FilterButtonItem>
                                                            <IconButton height={'25px'} width={'25px'} iconName="delete" label="" background=" #5a5553" />
                                                        </FilterButtonItem>
                                                    </TableSection>
                                                ) : (
                                                    <>
                                                        <TableData key={path}>{!attribute ? rowData[path] : <div> {attribute(rowData[path], rowData)}</div>} </TableData>
                                                    </>
                                                )}
                                            </Fragment>
                                        ))}
                                </TableRow>
                            ))
                        }


                    </Tbody>

                </Tables>

                {columnsValue.length > 0 && columnsValue.filter(column => column.sum).length > 0 && (
                    <ContainerSum>
                        <TableDataSum><strong>Valor Real:</strong> {values && values.length > 0 && convertMonetaryValueToNumber(values.reduce((accumulator, item) => accumulator + (item.quantity * item.price), 0))}</TableDataSum>
                        <TableDataSum><strong>% Negociado:</strong><span style={{ color: calcNegotiated() >= 0 ? 'green' : 'red' }}> {values && values.length > 0 && calcNegotiated() + '%'}</span></TableDataSum>
                        <TableDataSum><strong>Valor Negociado:</strong> {values && values.length > 0 && convertMonetaryValueToNumber(values.reduce((accumulator, item) => accumulator + (item.quantity * Number((item.negotiatedPrice || item.price))), 0))}</TableDataSum>
                    </ContainerSum>
                )}
                {options.length > 0 && options.filter(f => f.pagination == true).length > 0 && (
                    <PaginationContainer>
                        {/* <IconButton style={{ width: '80px' }} iconName='arrow_back' onClick={handlePrevPage} disabled={currentPage === 1}>
                            Anterior
                        </IconButton>
                        <span>Página {currentPage} de {totalPages}</span>
                        <IconButton style={{ width: '80px' }} iconName='arrow_forward' onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Próxima
                        </IconButton> */}
                        <PaginationOrders page={currentPage} totalPage={totalPages} setPagination={setPagination} />
                    </PaginationContainer>)
                }


            </ContainerTable>
        </Fragment >

    )
}

export default Table