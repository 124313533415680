import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { deleteOrder, getOrder, insertOrderInvento, validateOrder } from "../http/portalb2b.api.js";
import IconButton from "../components/IconButton";
import logoInvento from "../assets/invento.png"
import cart from "../assets/cart.png"
import iconInvento from "../assets/logo-invento-fundo-transparente.png"
import iconLixeira from "../assets/LixeiraIcon.jpg"
import IconPrint from "../assets/PickingIcon.png"
import IconEdit from "../assets/edit.png"
import { enqueueSnackbar } from "notistack";
import { convertMonetaryValueToNumber } from '../lib/convertMonetaryValueToNumber.js'
import ModalConfirm from "../components/ModalConfirm";
import { gerarRelatorioPDF } from "../components/ReportPdf";
import ModalOrder from "../components/ModalOrder";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Header from "../components/Header";
import PaginationOrders from "../components/PaginationOrders";
import { useNavigate } from "react-router-dom";

export const OrdersBudget = () => {

	const [value, setValue] = useState({})
	const [isActiveModal, setIsActiveModal] = useState(false);
	const [isActiveModalTrash, setIsActiveModalTrash] = useState(false);
	const [isActiveModalCopy, setIsActiveModalCopy] = useState(false);
	const [isActiveModalEdit, setIsActiveModalEdit] = useState(false);
	const [sendOrder, setOrder] = useState({})
	const [isOpen, setIsOpen] = useState(false)
	const [isPrint, setIsPrint] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [pagination, setPagination] = useState({
		limit: 1,
		page: 1,
	})

	const navigate = useNavigate()
	const getValues = async () => {

		let startDateFilter = startDate ? new Date(new Date(startDate).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));
		let endDateFilter = endDate ? endDate : new Date();
		const orders = await getOrder({ ...pagination, startDateFilter, endDateFilter, status: 'pending' })
		if (orders)
			setValue(orders)
	}
	useEffect(() => {
		getValues()
	}, [startDate, endDate])

	const handleFilter = async (filters = {}) => {
		let startDateFilter = startDate ? new Date(new Date(startDate).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));
		let endDateFilter = endDate ? endDate : new Date();
		const orders = await getOrder({ ...pagination, ...filters, startDateFilter, endDateFilter, status: 'pending' })
		if (orders) {
			setValue(orders)
		}
	}

	useEffect(() => {

		let startDateFilter = startDate ? new Date(new Date(startDate).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));
		let endDateFilter = endDate ? endDate : new Date();
		getOrder({ ...pagination, startDateFilter, endDateFilter, status: 'pending', page: pagination.currentPage, }).then(response => {

			if (response) {
				setValue(response)
			}

		})
	}, [pagination])


	const handleIsActiveModalSendInvento = (value) => {
		setOrder(value)
		setIsActiveModal(!isActiveModal)
	}

	const handleIsActiveModalPicking = (value) => {
		setOrder(value)
		setIsPrint(!isPrint)
	}


	const handleSubmitInvento = async () => {
		if (sendOrder.status == 'pending') {
			try {
				await insertOrderInvento([sendOrder])
				getValues()
				enqueueSnackbar('Enviado para Invento', { variant: 'success' })
				setIsActiveModal(!isActiveModal)
			} catch (error) {
				enqueueSnackbar(error.response.data, { variant: 'error' })
			}

		} else {
			setIsActiveModal(!isActiveModal)
			enqueueSnackbar('Pedido já foi enviado !', { variant: 'warning' })

		}
	}

	const handleIsActiveModalDelete = (value) => {
		setOrder(value)
		setIsActiveModalTrash(!isActiveModalTrash)
	}

	const handleDelete = async () => {
		if (sendOrder.status == 'pending') {
			await deleteOrder(sendOrder)
			setIsActiveModalTrash(!isActiveModalTrash)
			enqueueSnackbar('Orçamento deletado!', { variant: 'success' })
			getValues()
		} else {
			setIsActiveModalTrash(!isActiveModalTrash)
			enqueueSnackbar('Pedido só pode ser removido com status em orçamento!!', { variant: 'warning' })
		}

	}

	const handleIsActiveModalCopy = (value) => {
		setOrder(value)
		setIsActiveModalCopy(!isActiveModalCopy)
	}

	const handleIsActiveModalEdit = (value) => {
		setOrder(value)
		setIsActiveModalEdit(!isActiveModalEdit)
	}

	const handleCopy = async () => {
		if (sendOrder.status) {
			localStorage.removeItem('cart')
			localStorage.removeItem('edtCart')
			let newOrderVerify = await validateOrder(sendOrder)
			localStorage.setItem('cart', JSON.stringify(newOrderVerify.items))
			setIsActiveModalCopy(!isActiveModalCopy)
			enqueueSnackbar('Pedido copiado ao carrinho!', { variant: 'success' })
			getValues()
			navigate('/carrinho')
		}

	}

	const handleEdit = async () => {
		if (sendOrder.status) {
			localStorage.removeItem('cart')
			let newOrderVerify = await validateOrder(sendOrder)
			localStorage.setItem('cart', JSON.stringify(newOrderVerify.items))
			localStorage.setItem('edtCart', JSON.stringify({ ...newOrderVerify, edit: true }))
			setIsActiveModalEdit(!isActiveModalEdit)
			enqueueSnackbar('Pedido no carrinho para edição', { variant: 'success' })
			getValues()
			navigate('/carrinho')
		}

	}

	const handleIsActiveModalView = (value) => {
		setOrder(value)
		toggleModal()
	}


	const IconStyle = {
		color: '#07689D'
	}

	const columns = [
		{
			path: 'orderid',
			name: 'Nº Pedido de venda',
			type: 'input',
			isActiveFilter: true,
			style: { width: 280 }
		},
		{
			path: 'nome',
			name: 'Nome  do Cliente',
			type: 'input',
			isActiveFilter: true,
			style: { width: 255 }

		},
		{
			path: 'documento',
			name: 'CNPJ',
			type: 'input',
			isActiveFilter: true,
			style: { width: 235 }

		},
		{
			path: 'createdAt',
			name: 'Data Orçamento',
			type: 'date',
			isActiveFilter: true,
			style: { width: 250 },
			attribute: (value) => {
				let date =
					new Date(value).toLocaleDateString();
				return <p style={{ margin: 10 }}>{date}</p>
			}

		},

		{
			path: 'status',
			name: 'Status',
			isActiveFilter: false,
			attribute: (value) => {
				let status = {
					pending: 'Orçamento',
					send: 'Enviado para Invento',
					cancelled: 'Cancelado'
				}
				return status[value] ? status[value] : value
			}
		},
		{
			path: 'gross',
			name: 'Total',
			isActiveFilter: false,
			attribute: (value) => {
				return convertMonetaryValueToNumber(value)
			}
		},
		{
			path: '',
			name: 'Ação',
			attribute: (isEmpty, value) => {
				return (
					<div style={{ display: 'flex', gap: '10px', margin: 20 }}>
						{/*
						Botão enviar para o invento */}
						<IconButton tooltipTitle='Enviar pedido para o Invento' onClick={() => handleIsActiveModalSendInvento(value)} background={'transparent'} style={{ width: 50 }}>
							<img style={{ width: 30, height: 20 }} src={iconInvento} />
						</IconButton>
						{/* Botão imprimir pedido */}
						<IconButton tooltipTitle='Imprimir Pedido' onClick={() => { handleIsActiveModalPicking(value) }} background={'transparent'} style={{ width: 50 }} >
							<LocalPrintshopOutlinedIcon style={IconStyle} />
						</IconButton>
						{/* */}
						<IconButton tooltipTitle='Duplicar pedido para o carrinho' onClick={() => { handleIsActiveModalCopy(value) }} background={'transparent'} style={{ width: 50 }} >
							<FileCopyOutlinedIcon style={IconStyle} />
						</IconButton>
						<IconButton tooltipTitle='Editar Pedido' onClick={() => { handleIsActiveModalEdit(value) }} background={'transparent'} style={{ width: 50 }} >
							<EditNoteIcon style={IconStyle} />
						</IconButton>
						{/* Botão excluir pedido */}
						<IconButton tooltipTitle='Excluir pedido permanentemente' onClick={() => { handleIsActiveModalDelete(value) }} background={'transparent'} style={{ width: 50 }} >
							<DeleteOutlineOutlinedIcon style={IconStyle} />
						</IconButton>
						<IconButton tooltipTitle='Consultar detalhe do pedido' onClick={() => { handleIsActiveModalView(value) }} background={'transparent'} style={{ width: 50 }} >
							<VisibilityOutlinedIcon style={IconStyle} />
						</IconButton>

					</div>
				)
			}
		},
	]



	const options = [
		{
			buttonFilterPage: true,
			pagination: false,
			buttonFilter: false
		}]





	const toggleModal = () => {
		setIsOpen(!isOpen)
	}

	const { visitor } = JSON.parse(localStorage.getItem('authToken'))

	return (
		<>
			<Header icon={<ArticleOutlinedIcon fontSize="20px" />} name='Orçamento' />
			<ModalOrder isOpen={isOpen} value={sendOrder} onClose={toggleModal} />
			<ModalConfirm
				isOpen={isActiveModal}
				logo={logoInvento}
				handleFuncionYes={handleSubmitInvento}
				handleFuncionNot={() => setIsActiveModal(!isActiveModal)}
				label={'Deseja enviar o pedido para o Invento?'} />
			<ModalConfirm
				isOpen={isActiveModalTrash}
				logo={iconLixeira}
				img={{ width: 70, heigth: 50 }}
				handleFuncionYes={handleDelete}
				handleFuncionNot={() => setIsActiveModalTrash(!isActiveModalTrash)}
				label={'Deseja apagar o orçamento?'} />
			<ModalConfirm
				isOpen={isPrint}
				logo={IconPrint}
				img={{ width: 50, heigth: 30 }}
				handleFuncionYes={() => { gerarRelatorioPDF(sendOrder, setIsPrint(!isPrint)) }}
				handleFuncionNot={() => setIsPrint(!isPrint)}
				label={'Deseja Imprimir o orçamento?'} />
			<ModalConfirm
				isOpen={isActiveModalCopy}
				logo={cart}
				img={{ width: 70, heigth: 50 }}
				handleFuncionYes={() => { handleCopy() }}
				handleFuncionNot={() => setIsActiveModalCopy(!isActiveModalCopy)}
				label={'Deseja copiar o pedido?'} />
			<ModalConfirm
				isOpen={isActiveModalEdit}
				logo={IconEdit}
				img={{ width: 70, heigth: 50 }}
				handleFuncionYes={() => { handleEdit() }}
				handleFuncionNot={() => setIsActiveModalEdit(!isActiveModalEdit)}
				label={'Deseja editar o pedido?'} />

			<Table
				handleFilterPage={handleFilter}
				columns={columns}
				data={!visitor ? value.result : []}
				options={options}
				setEndDateOrders={setEndDate}
				setStartDateOrders={setStartDate}
			/>
			<PaginationOrders page={value.currentPage} totalPage={value.totalPages} setPagination={setPagination} />
		</>
	);
};

