import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { deleteOrder, getOrder, insertOrderInvento } from "../http/portalb2b.api.js";
import IconButton from "../components/IconButton";
import logoInvento from "../assets/invento.png"
import cart from "../assets/cart.png"
import iconLixeira from "../assets/LixeiraIcon.jpg"
import IconPrint from "../assets/PickingIcon.png"
import { enqueueSnackbar } from "notistack";
import { convertMonetaryValueToNumber } from '../lib/convertMonetaryValueToNumber.js'
import ModalConfirm from "../components/ModalConfirm";
import { gerarRelatorioPDF } from "../components/ReportPdf";
import ModalOrder from "../components/ModalOrder";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import Header from "../components/Header";
import PaginationOrders from "../components/PaginationOrders";
import { useNavigate } from "react-router-dom";


export const Orders = () => {

	const [value, setValue] = useState({})
	const [isActiveModal, setIsActiveModal] = useState(false);
	const [isActiveModalTrash, setIsActiveModalTrash] = useState(false);
	const [isActiveModalEdit, setIsActiveModalEdit] = useState(false);
	const [sendOrder, setOrder] = useState({})
	const [isOpen, setIsOpen] = useState(false)
	const [isPrint, setIsPrint] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [pagination, setPagination] = useState({
		currentPage: 1,
		totalPages: 1,
	})
	const navigate = useNavigate()
	const getValues = async () => {
		let startDateFilter = startDate ? new Date(new Date(startDate).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));
		let endDateFilter = endDate ? endDate : new Date();
		const orders = await getOrder({ startDateFilter, endDateFilter, status: { $nin: ['pending'] } })
		if (orders)
			setValue(orders)
	}
	useEffect(() => {
		getValues()
	}, [startDate, endDate])


	const handleFilter = async (filters = {}) => {
		let startDateFilter = startDate ? new Date(new Date(startDate).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));
		let endDateFilter = endDate ? endDate : new Date();
		const orders = await getOrder({ ...filters, startDateFilter, endDateFilter, status: { $nin: ['pending'] } })
		if (orders) {
			setValue(orders)
		}
	}

	useEffect(() => {

		let startDateFilter = startDate ? new Date(new Date(startDate).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));
		let endDateFilter = endDate ? endDate : new Date();
		getOrder({ startDateFilter, endDateFilter, status: { $nin: ['pending'] }, page: pagination.currentPage, }).then(response => {

			if (response) {
				setValue(response)
			}

		})

	}, [pagination])

	const handleIsActiveModalPicking = (value) => {
		setOrder(value)
		setIsPrint(!isPrint)
	}


	const handleSubmitInvento = async () => {
		if (sendOrder.status == 'pending') {
			await insertOrderInvento([sendOrder])
			getValues()
			enqueueSnackbar('Enviado para Invento', { variant: 'success' })
			setIsActiveModal(!isActiveModal)
		} else {
			setIsActiveModal(!isActiveModal)
			enqueueSnackbar('Pedido já foi enviado !', { variant: 'warning' })

		}
	}


	const handleDelete = async () => {
		if (sendOrder.status == 'pending') {
			await deleteOrder(sendOrder)
			setIsActiveModalTrash(!isActiveModalTrash)
			enqueueSnackbar('Orçamento deletado!', { variant: 'success' })
			getValues()
		} else {
			setIsActiveModalTrash(!isActiveModalTrash)
			enqueueSnackbar('Pedido só pode ser removido com status em orçamento!!', { variant: 'warning' })
		}

	}

	const handleIsActiveModalEdit = (value) => {
		setOrder(value)
		setIsActiveModalEdit(!isActiveModalEdit)

	}

	const handleEdit = async () => {
		if (sendOrder.status) {
			localStorage.removeItem('cart')
			localStorage.setItem('cart', JSON.stringify(sendOrder.items))
			setIsActiveModalEdit(!isActiveModalEdit)
			enqueueSnackbar('Pedido copiado ao carrinho!', { variant: 'success' })
			getValues()
			navigate('/carrinho')
		}

	}

	const handleIsActiveModalView = (value) => {
		setOrder(value)
		toggleModal()
	}


	const IconStyle = {
		color: '#07689D'
	}

	const columns = [
		{
			path: 'orderid',
			name: 'Nº Pedido de venda',
			type: 'input',
			isActiveFilter: true,
			style: { width: 280 }
		},

		{
			path: 'nome',
			name: 'Nome  do Cliente',
			type: 'input',
			isActiveFilter: true,
			style: { width: 255 }

		},
		{
			path: 'documento',
			name: 'CNPJ',
			type: 'input',
			isActiveFilter: true,
			style: { width: 235 }

		},
		{
			path: 'createdAt',
			name: 'Data Orçamento',
			type: 'date',
			isActiveFilter: true,
			style: { width: 235 },
			attribute: (value) => {
				let date =
					new Date(value).toLocaleDateString();
				return <p style={{ margin: 10 }}>{date}</p>
			}

		},

		{
			path: 'status',
			name: 'Status',
			isActiveFilter: false,
			attribute: (value) => {
				let status = {
					pending: 'Orçamento',
					send: 'Enviado para Invento',
					cancelled: 'Cancelado',
					invoiced: 'Faturado',
					'ENVIADO PARA O CLIENTE': 'Concluido'
				}
				return status[value] ? status[value] : value
			}
		},
		{
			path: 'gross',
			name: 'Total',
			isActiveFilter: false,
			attribute: (value) => {
				return convertMonetaryValueToNumber(value)
			}
		},
		{
			path: '',
			name: 'Ação',
			attribute: (isEmpty, value) => {
				return (
					<div style={{ display: 'flex', gap: '10px', margin: 20 }}>
						{/* 
					
						{/* Botão imprimir pedido */}
						<IconButton tooltipTitle='Imprimir Pedido' onClick={() => { handleIsActiveModalPicking(value) }} background={'transparent'} style={{ width: 50 }} >
							<LocalPrintshopOutlinedIcon style={IconStyle} />
						</IconButton>
						{/* */}
						<IconButton tooltipTitle='Duplicar pedido para o carrinho' onClick={() => { handleIsActiveModalEdit(value) }} background={'transparent'} style={{ width: 50 }} >
							<FileCopyOutlinedIcon style={IconStyle} />
						</IconButton>
						<IconButton tooltipTitle='Consultar detalhe do pedido' onClick={() => { handleIsActiveModalView(value) }} background={'transparent'} style={{ width: 50 }} >
							<VisibilityOutlinedIcon style={IconStyle} />
						</IconButton>

					</div>
				)
			}
		},
	]

	const options = [
		{
			buttonFilterPage: true,
			pagination: false,
			buttonFilter: false
		}]

	const toggleModal = () => {
		setIsOpen(!isOpen)
	}

	const { visitor } = JSON.parse(localStorage.getItem('authToken'))

	return (
		<> <Header icon={<SellOutlinedIcon fontSize="20px" />} name='Pedidos' />
			<ModalOrder isOpen={isOpen} value={sendOrder} onClose={toggleModal} />
			<ModalConfirm
				isOpen={isActiveModal}
				logo={logoInvento}
				handleFuncionYes={handleSubmitInvento}
				handleFuncionNot={() => setIsActiveModal(!isActiveModal)}
				label={'Deseja enviar o pedido para o Invento?'} />
			<ModalConfirm
				isOpen={isActiveModalTrash}
				logo={iconLixeira}
				img={{ width: 70, heigth: 50 }}
				handleFuncionYes={handleDelete}
				handleFuncionNot={() => setIsActiveModalTrash(!isActiveModalTrash)}
				label={'Deseja apagar o orçamento?'} />
			<ModalConfirm
				isOpen={isPrint}
				logo={IconPrint}
				img={{ width: 50, heigth: 30 }}

				handleFuncionYes={() => { gerarRelatorioPDF(sendOrder, setIsPrint(!isPrint)) }}
				handleFuncionNot={() => setIsPrint(!isPrint)}
				label={'Deseja Imprimir o orçamento?'} />
			<ModalConfirm
				isOpen={isActiveModalEdit}
				logo={cart}
				img={{ width: 70, heigth: 50 }}
				handleFuncionYes={() => { handleEdit() }}
				handleFuncionNot={() => setIsActiveModalEdit(!isActiveModalEdit)}
				label={'Deseja copiar o pedido?'} />

			<Table
				handleFilterPage={handleFilter}
				columns={columns}
				data={!visitor ? value.result : []}
				options={options}
				setEndDateOrders={setEndDate}
				setStartDateOrders={setStartDate}
			/>
			<PaginationOrders page={value.currentPage} totalPage={value.totalPages} setPagination={setPagination} />
		</>
	);
};

