import { Pagination } from '@mui/material'
import React from 'react'
import styled from 'styled-components'


export const ContainerPagination = styled.div`
display:flex;
margin:0 auto;
margin-top:20px;
bottom:0;
justify-content:flex-end;
`
const PaginationOrders = ({ page = 1, totalPage, setPagination }) => {
    const handleChange = (e, value) => {
        e.preventDefault()
        setPagination(
            (paginationCurrent) => {
                return { ...paginationCurrent, currentPage: value }
            });
    };

    return (
        <ContainerPagination>
            <Pagination shape="rounded" color="primary" variant="outlined" count={totalPage} page={parseInt(page)} onChange={handleChange} />
        </ContainerPagination>
    )
}

export default PaginationOrders