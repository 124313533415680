import React from 'react';
import ReactImageMagnify from 'react-image-magnify';
import styled from 'styled-components';
import { device } from './device.config';

const ImageRender = ({ src }) => {
    const ImageContainer = styled.div`
  min-width:380px;
    display: 'flex';
    @media ${device.laptop} {
         display: 'flex';
         width:380px;
}
 `

    return (
        <ImageContainer >
            <ReactImageMagnify {...{
                smallImage: {
                    isFluidWidth: true,
                    isActivatedOnTouch: true,
                    hoverDelayInMs: true,
                    isEnlargedImagePortalEnabledForTouch: true,
                    src
                },
                largeImage: {
                    src,
                    width: 1200,
                    height: 1500,

                },
                enlargedImagePosition: "over",

            }} />

        </ImageContainer>
    );
};

export default ImageRender;
