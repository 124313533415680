import styled from "styled-components";
import { device } from "./device.config";

export const Select = styled.select`
    width:100%;
    height:250px;
    color: #787575;
    max-height:60px;  
    background-color:#F5F5F5; 
    max-height:50px;
    border:1px solid #D9D9D9;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;

       option {
        max-width:100%;
        width:100%;
    height:250px;
    color: #787575;
    max-height:60px;  
    background-color:#F5F5F5; 
    max-height:50px;
    border:1px solid #D9D9D9;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
       }
        :focus{
     max-width:100%;
     width:100%;
    height:250px;
    color: #787575;
    max-height:60px;  
    background-color:#F5F5F5; 
    max-height:50px;
    border:1px solid #D9D9D9;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    :focus {
      color: #454040;
      outline: none;

    }
    :active{
      color: #696767;
      outline: none;    
      
    }
    ::placeholder{
      color: #787575;
    }
    :hover{

      cursor: pointer;
    }
 	
    @media ${device.laptopL} { 
	width:100%;
	 }

   @media ${device.laptop} { 
	width:100%;
	 }
    @media ${device.desktop} { 
	width:100%;
	 }

`;


