import React, { useEffect } from 'react';
import styled from 'styled-components';
import IconButton from './IconButton';
import { Tooltip } from '@mui/material';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  z-index:1200;
`;

const ModalContainer = styled.div`
  padding:30px;
  background-color: #fff;
  border-radius:15px;
  max-width:800px;
  max-height:600px;
  display:flex;
  justify-content:flex-start;
  overflow: hidden;
  width:700px;
  z-index:1200;
`;

const ModalAvatar = styled.div`
  padding-left:20px;
  border: 1px solid #e2e2e2;
  background:#ffffff;
  margin-top:10px;
  border-radius:15px;
  flex-wrap:wrap;
  align-items: flex-end;
  display:flex;
  overflow-x: auto;
::-webkit-scrollbar-thumb {
  background-color: #E2E2E2; /* Cor do fundo da alça da barra de rolagem */
  border-radius: 6px; /* Raio de borda da alça da barra de rolagem */
}
::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
  height:5px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
}
`;

const ModalItem = styled.p`

`;
const ModalItemPrice = styled.p`
text-align:end;
`;

const ModalItemAvatar = styled.p`
`;

const ModalItemParagraph = styled.p`
  width:100%;
  display:flex;
  flex-direction:column;
`;

const Paragraph = styled.p`
font-weight:bold;
text-align:start;
font-size:20px;
 margin:10px;
`;

const ModalContainerAvatar = styled.div`
  display:flex;
  align-items:center;
`;

const ModalContainerItemText = styled.div`
  width:450px;
`;

const ModalContainerItem = styled.div`
  width:140px;
`;

const ModalContainerPrice = styled.div`
display:flex;
flex-direction:row;
margin-top:0px;
justify-content:flex-end;`

const ModalContainerButton = styled.div`
display:flex;
flex-direction:row;
margin-top:10px;
justify-content:flex-end;`

const ParagraphRed = styled.a`
color:red;
font-size:18px;`

const ModalOrder = ({ isOpen, onClose, value }) => {

  if (!isOpen) {
    return null;
  }


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 27) {
        onClose()
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleClick = () => {
    onClose()
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalItemParagraph>
          <Paragraph>PEDIDO #{value.orderid}</Paragraph>
          <hr></hr>
          <br></br>
          <ModalItem>
            <p><b>Cliente:</b>{value.nome}</p>
            <p><b>Forma de Pagamento:</b> {value.payments}</p>
            <p><b>Prazo de Pagamento:</b> {value.paymentTime.description}</p>
            <Tooltip title={value.transport && value.transport.name ? value.transport.name.substring(0, 50) : ''}><p><b>Transportadora:</b> {value.transport && value.transport.name ? value.transport.name.substring(0, 50) : ''}</p></Tooltip>
            <p><b>Observação:</b> {value.message}</p>
          </ModalItem>

          <ModalAvatar>
            {value.items.length > 0 && value.items.map(item => {
              return (
                <>
                  <ModalItemAvatar>
                    <ModalContainerAvatar>
                      <ModalContainerItem>
                        <img style={{ width: '130px' }} src={item.imagem} />

                      </ModalContainerItem>
                      <ModalContainerItemText>
                        <br></br>
                        <p> <b>Descrição: </b>{item.descricao}</p>
                        <p> <b>SKU:</b> {item.codigo} </p>
                        <p> <b>Preço Unitário:</b> R$ {item.price}</p>
                        <p> <b>Preço Negociado:</b> R$ {item.negotiatedPrice}</p>
                        <p> <b>Quantidade:</b> {item.quantity}</p>
                         <b>Item Cortado: </b>{item.cutItem ? <ParagraphRed>{item.cutItem}</ParagraphRed> : item.cutItem}
                        <br></br>
                        <hr></hr>
                      </ModalContainerItemText>

                    </ModalContainerAvatar>
                  </ModalItemAvatar >

                </>
              )
            })}

          </ModalAvatar >


          <ModalContainerPrice>
            <ModalItemPrice>
              <p><b>Valor total Negociado:</b> R$   {value.items.length > 0 ? value.items.reduce((acc, item) => ((item.negotiatedPrice || item.price) * item.quantity) + acc, 0).toFixed(2) : 0}</p>
              <p><b>Valor total:</b> R$  {value && value.gross ? value.gross.toFixed(2) : 0}</p>
              <p><b>Valor Faturado:</b> R$  {value && value.invoicedGross ? value.invoicedGross.toFixed(2) : 0} </p>
            </ModalItemPrice>

          </ModalContainerPrice>
          <ModalContainerButton>
            <ModalItem>
              <IconButton
                onClick={handleClick}
                style={{ width: '150px', fontSize: 18 }}
                iconName={'closed'}
                label={'Fechar'} />
            </ModalItem>
          </ModalContainerButton>
        </ModalItemParagraph>
      </ModalContainer>

    </ModalOverlay >
  );
};

export default ModalOrder;
