import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { transport } from '../temp/transport';
import ModalTransport from './ModalTransport';
import iconTransport from '../assets/transport.png'

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SelectInput = styled.input`
  width: 100%;
  height: 50px;
  color: #787575;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;

  :focus {
    color: #454040;
    outline: none;
  }

  ::placeholder {
    color: #787575;
  }
`;

const OptionList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border-top: none;
  background-color: #fff;
  z-index: 1;
`;

const OptionItem = styled.li`
  padding: 8px;
  font-size: 14px;
  color: #787575;
  cursor: pointer;

  :hover {
    background-color: #f2f2f2;
  }
`;

const NoOptionsMessage = styled.p`
  padding: 8px;
  margin: 0;
`;

const SelectData = ({ setValue }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(transport);
  const [values, setValues] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef(null);


  const toggleModal = () => {
    setIsOpen(!isOpen)

  }

  const handleInputChange = (event) => {
    const value = event.target.value;

      if (value == '') {

        setValue({})
      }
    setSearchTerm(value);

    const filtered = value
      ? transport.filter((option) =>
        option.id.toLowerCase().includes(value.toLowerCase())
      )
      : transport;
    setFilteredOptions(filtered);
    setIsActive(true);
    
  };

  const handleOptionClick = (option) => {
    setSearchTerm(option.value + ' ' + option.id);
    setValues(option)
    toggleModal()
    setFilteredOptions(transport);
    setIsActive(false);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleIsDesactiveModal = () => {

    toggleModal()
    setSearchTerm('')

  }

  const handleClick = () => {
    setIsActive(false);
    toggleModal()
    setValue(values)

  }

  return (
    <SelectContainer ref={containerRef}>
      <ModalTransport
        img={{ width: 70, heigth: 50 }}
        label={'Deseja selecionar essa transportadora?'}
        onClose={toggleModal}
        handleFuncionYes={handleClick}
        handleFuncionNot={() => handleIsDesactiveModal()}
        isOpen={isOpen}
        logo={iconTransport}
        transport={values} />
      <SelectInput
        type="number"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Digite o CNPJ para buscar a transportadora..."
      />
      {isActive && searchTerm && (
        <OptionList>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <OptionItem
                key={option.id}
                onClick={() => handleOptionClick(option)}
              >
                {option.id + ' ' + ' ' + option.value}
              </OptionItem>
            ))
          ) : (
            <NoOptionsMessage>Nenhuma opção encontrada</NoOptionsMessage>
          )}
        </OptionList>
      )}
    </SelectContainer>
  );
};

export default SelectData;
