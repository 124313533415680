import styled from "styled-components";
import { device } from "./device.config";


export const Input = styled.input`
	  width:100%;
    height:250px;
    color: #787575;
    max-height:60px;  
    background-color:#F5F5F5; 
    max-height:50px;
    border:1px solid  #D9D9D9;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    :focus {
      color: #454040;
      outline: none;
      border:1px solid #D9D9D9;

    }
    :active{
      color: #696767;
      border:1px solid #D9D9D9;
      outline: none;    
      
    }
    ::placeholder{
      color: #787575;
   
    }
 border-radius: 8px;
	
	@media ${device.laptopL} { 
	width:100%;
	 }

   @media ${device.laptop} { 
	width:100%;
	 }
    @media ${device.desktop} { 
	width:100%;
	 }
    `

